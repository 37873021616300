import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {PlaylistDetails} from "./playlist-details/playlist-details.component";
import {PlaylistsComponent} from "./schedule/playlists.component";
import {APP_BASE_HREF} from '@angular/common';
import {PlaylistCompare} from "./playlist-compare/playlist-compare.component";
import {UserListComponent} from "./user-list/user-list.component";
import {UserAgreementComponent} from "./user-list/user-agreement.component";
import {UserDetailResolve} from "./resolvers/user-detail.resolve.service";
import {UnauthorisedComponent} from "./user-list/unauthorised.component";
import {ProfileListComponent} from "./profile-list/profile-list.component";
import {ChannelListComponent} from "./channel-list/channel-list.component";
import {ChannelGroupListComponent} from "./channel-group-list/channel-group-list.component";
import {AuthGuard} from "./common/auth-guard.service";
import {NotificationsConfigurationComponent} from "./notifications-configuration/notifications-configuration.component";
import {UnauthorisedWarningComponent} from "./user-list/authorisationWarning.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {ControlPanelComponent} from "./control-panel/control-panel.component";

/**
 * Created by sebastian on 03.04.17.
 */

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: PlaylistsComponent,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'home/:ids/:date',
    component: PlaylistsComponent,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'detail/:channelId/:date/:isMissingLink',
    component: PlaylistDetails,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'compare/:channelId/:version1/:date',
    component: PlaylistCompare,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'blocked',
    component: UnauthorisedComponent,
  },
  {
    path: 'unauthorised',
    component: UnauthorisedWarningComponent,
  },
  {
    path: 'user',
    component: UserListComponent,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'user/agreement',
    component: UserAgreementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/change_password',
    canActivate: [AuthGuard]
  },
  {
    path: 'user/sign_out',
    canActivate: [AuthGuard]
  },
  {
    path: 'profiles',
    component: ProfileListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'channels',
    component: ChannelListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'channelGroups',
    component: ChannelGroupListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notificationsConfiguration',
    component: NotificationsConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'transferStatistics',
    component: StatisticsComponent,
    resolve: {
      user: UserDetailResolve
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'transferControlPanel',
    component: ControlPanelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'files/:extension/:file-name'
  },
  {
    path: 'logout/cas',
    canActivate: [AuthGuard]
  },
  {
    path: '/logout/cas'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuard, {provide: APP_BASE_HREF, useValue: '/'}, UserDetailResolve,]
})
export class AppRoutingModule {
}
