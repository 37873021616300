/**
 * Created by tomow on 09.06.17.
 */
import {Component, Input} from "@angular/core";

@Component({
  selector: 'sort-arrow',
  templateUrl: 'sort-arrow.component.html'
})

export class SortArrowComponent {

  @Input() selected: boolean;
  @Input() sortAsc: boolean;
}
