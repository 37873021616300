<div class="control-panel-page">

  <H2>{{dictionaryService.dictionary.controlPanel.title}}</H2>

  <!--KONTENER WYSZUKIWANIA-->
  <div class="cp-search-container">

    <!--WYSZUKIWARKA-->
    <div class="cp-search-row">

      <div class="cp-channel-group-and-channel">
        <div class="field-box cp-inline-input">
          <div><label
            class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.channelsGroupInput.label}}
            *:</label></div>
          <div class="search-groups--autocomplete disable-all select-input-height">
            <p-autoComplete name="channel" [size]="25"
                            [placeholder]="dictionaryService.dictionary.controlPanel.searchBox.channelsGroupInput.placeholder"
                            [(ngModel)]="channelsGroupSelected"
                            [suggestions]="channelsGroupList"
                            field="name"
                            appendTo="body"
                            [disabled]="isGroupSelected == false"
                            (completeMethod)="searchFilterChannelsGroup($event)"
                            (ngModelChange)="isGroupSelected = null"
                            (onSelect)="selectChannelsGroup($event)">
              <ng-template let-channelGroupSelected pTemplate="item">
                <span>{{channelGroupSelected.name}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <div class="field-box cp-inline-input">
          <div><label class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.channelInput.label}}
            *:</label></div>
          <div class="search-groups--autocomplete disable-all select-input-height">
            <p-autoComplete name="channel" [size]="25"
                            [placeholder]="dictionaryService.dictionary.controlPanel.searchBox.channelInput.placeholder"
                            [(ngModel)]="channelSelected"
                            [suggestions]="channelList"
                            field="name"
                            appendTo="body"
                            [disabled]="isGroupSelected == true"
                            (completeMethod)="searchFilterChannels($event)"
                            (ngModelChange)="isGroupSelected = null"
                            (onSelect)="selectChannel($event)">
              <ng-template let-channelSelected pTemplate="item">
                <span>{{channelSelected.name}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </div>

      <div class="cp-date-from-to-and-file-compare-and-status">
        <div class="date-from-to">
          <div class="field-box cp-inline-input">
            <div><label class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.playlistDates.label}}
              *:</label></div>
            <div class="weekpicker-box">
              <p-calendar class="field"
                          [readonlyInput]="true"
                          dateFormat="yy/mm/dd"
                          type="date"
                          selectOtherMonths="true"
                          placeholder="{{dictionaryService.dictionary.controlPanel.searchBox.playlistDates.placeholderFrom}}"
                          [(ngModel)]="playlistDateFrom"
                          (ngModelChange)="onPlaylistDateFromChange()"
                          appendTo="body">
              </p-calendar>
            </div>
          </div>

          <div class="field-box cp-inline-input">
            <div><label class="field-label">&nbsp;</label></div>
            <div class="weekpicker-box">
              <p-calendar class="field"
                          [readonlyInput]="true"
                          dateFormat="yy/mm/dd"
                          selectOtherMonths="true"
                          placeholder="{{dictionaryService.dictionary.controlPanel.searchBox.playlistDates.placeholderTo}}"
                          [(ngModel)]="playlistDateTo"
                          appendTo="body">
              </p-calendar>
            </div>
          </div>
        </div>

        <div class="cp-file-compare-and-status">
          <div class="field-box cp-inline-input">
            <div><label class="field-label">&nbsp;</label></div>
            <button (click)="equalsButtonAction()"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  cp-search-buttons cp-equals-button"
                    [ngClass]="{'cp-search-buttons-active' : filterFileSize == 1}">
              &#61;
            </button>
          </div>

          <div class="field-box cp-inline-input">
            <div><label class="field-label">&nbsp;</label></div>
            <button (click)="notEqualsButtonAction()"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  cp-search-buttons cp-not-equals-button "
                    [ngClass]="{'cp-search-buttons-active' : filterFileSize == -1}">
              &ne;
            </button>
          </div>

          <div class="field-box cp-inline-input">
            <div><label
              class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.processingStatus.label}}
              :</label></div>
            <p-dropdown
              [options]="materialStatusesForInput"
              [(ngModel)]="filterStatus"
              optionLabel="label"
              optionValue="value"
              [style]="{'height':'29px', 'width':'100%'}"
              [styleClass]="'custom-dropdown'"
              [placeholder]="dictionaryService.dictionary.controlPanel.searchBox.processingStatus.placeholder"
            >
            </p-dropdown>
          </div>
        </div>
      </div>

      <div class="cp-material-and-file-name">
        <div class="field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.materialName.label}}
            :</label></div>
          <div><input style="padding-left: 4px;" class="field cp-input-border p-inputtext" name="materialName"
                      type="text"
                      [(ngModel)]="filterMaterialName"></div>
        </div>

        <div class="field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.controlPanel.searchBox.fileName.label}}
            :</label>
          </div>
          <div><input style="padding-left: 4px;" class="field cp-input-border p-inputtext" name="fileName" type="text"
                      [(ngModel)]="filterFileName"></div>
        </div>
      </div>

      <div class="cp-filter-actions-and-validation-message">
        <div class="filter-actions">
          <div class="field-box cp-inline-input">
            <div><label class="field-label">&nbsp;</label></div>
            <button (click)="filterButtonAction()"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent filter-button cp-search-buttons ">
              {{dictionaryService.dictionary.controlPanel.searchBox.filter.label}}
              <i class="material-icons">tune</i>
            </button>
          </div>
          <div class="field-box cp-inline-input">
            <div><label class="field-label">&nbsp;</label></div>
            <button (click)="clearButtonAction()"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  cp-search-buttons">
              {{dictionaryService.dictionary.controlPanel.searchBox.clearFilter.label}}
            </button>
          </div>
        </div>
        <div class="validation-message field-box" *ngIf="validationMessage != null">
          <div><label class="field-label">&nbsp;</label></div>
          <div class="cp-search-error-msg">{{validationMessage}}</div>
        </div>
      </div>

      <div class="cp-file-action">
        <div style="padding-bottom: 5px">
          <button type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-elements-action-btn"
                  (click)="switchCheckAll()">
              <span *ngIf="!isAllChecked">
                {{dictionaryService.dictionary.controlPanel.buttons.checkAll}}</span>
            <span *ngIf="isAllChecked">{{dictionaryService.dictionary.controlPanel.buttons.unCheckAll}}</span>
          </button>
        </div>

        <div>
          <button type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-elements-action-btn"
                  (click)="retrySelected(false)">
            {{dictionaryService.dictionary.controlPanel.buttons.retry}}
            <i class="material-icons changed-msg">replay</i>
          </button>
        </div>
      </div>

    </div>

  </div>


  <!--KONTENER TABELI-->
  <div class="cp-table-container">

    <table class="cp-table">
      <!--NAGŁÓWEK TABELI-->
      <thead>
      <tr>
        <td colspan="6"
            class="cp-table-header-db-part"> {{dictionaryService.dictionary.controlPanel.fileTable.headers.database}}</td>
        <td colspan="7"
            class="cp-table-header-ftp-part">{{dictionaryService.dictionary.controlPanel.fileTable.headers.ftp}}</td>
      </tr>
      <tr class="cp-table-header-row">
        <td class="cp-table-header-cell cp-column-material-name">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.materialName}}
        </td>
        <td class="cp-table-header-cell cp-column-file-name-db">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileNameDb}}
        </td>
        <td class="cp-table-header-cell cp-column-file-size">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileSize}}
        </td>
        <td class="cp-table-header-cell cp-column-processing-status">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.processingStatus}}
        </td>
        <td class="cp-table-header-cell cp-column-retry-counter">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.retryCounter}}
        </td>
        <td class="cp-table-header-cell cp-column-action">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.action}}
        </td>
        <!-- -------------------- ------------------------- ------------WIZUALNE ROZGRANICZENIE BAZA/FTP-->
        <td class="cp-table-header-cell cp-column-file-name-ftp">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileNameFtp}}
        </td>
        <td class="cp-table-header-cell cp-column-date">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.sendDateFtp}}
        </td>
        <td class="cp-table-header-cell cp-column-file-size">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileSize}}
        </td>
        <!-- informacje o połączeniu ftp -->
        <td class="cp-table-header-cell cp-column-file-ftp-host">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileHost}}
        </td>
        <td class="cp-table-header-cell cp-column-file-ftp-port">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.filePort}}
        </td>
        <td class="cp-table-header-cell cp-column-file-ftp-user">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileUser}}
        </td>
        <td class="cp-table-header-cell cp-column-file-ftp-dir">
          {{dictionaryService.dictionary.controlPanel.fileTable.headers.fileDir}}
        </td>
      </tr>

      </thead>

      <!--TREŚĆ TABELI-->
      <tbody>
      <ng-container *ngIf="searchStarted && filesHistory?.length > 0">
        <tr class="cp-table-data-row" *ngFor="let row of filesHistory"
            [ngClass]="{'cp-table-row-status-both': row.rowStatus === FtpConstants.DB_AND_FTP,
            'cp-table-row-status-only-ftp': row.rowStatus === FtpConstants.ONLY_FTP,
            'cp-table-row-status-only-db': ((row.rowStatus === FtpConstants.ONLY_DB || row.rowStatus === FtpConstants.NO_FILE_INFO) && row.processingStatus !== FileStatusConstants.VIRTUAL)}">

          <td class="cp-table-data-cell cp-column-material-name">
            {{row.materialName}}
          </td>

          <ng-container
            *ngIf="row.rowStatus !== FtpConstants.NO_FILE_INFO && row.processingStatus !== FileStatusConstants.VIRTUAL">
            <td class="cp-table-data-cell cp-column-file-name-db">
              {{row.historyFileName}}
            </td>
            <td class="cp-table-data-cell cp-column-file-size"
                [ngClass]="{'cp-table-diff-size':row.rowStatus == FtpConstants.SIZE_DIFF}">
              {{(row.historyFileSize != null ? (row.historyFileSize + ' B') : null)}}
            </td>
          </ng-container>

          <ng-container
            *ngIf="row.rowStatus == FtpConstants.NO_FILE_INFO && row.processingStatus !== FileStatusConstants.VIRTUAL">
            <td colspan="2"
                class="cp-column-no-file-info">{{dictionaryService.dictionary.controlPanel.fileTable.info.noFileInfo}}</td>
          </ng-container>
          <ng-container *ngIf="row.processingStatus === FileStatusConstants.VIRTUAL">
            <td colspan="2"
                class="cp-column-virtual-material">{{dictionaryService.dictionary.controlPanel.fileTable.info.virtual}}</td>
          </ng-container>


          <td class="cp-table-data-cell cp-column-processing-status">
            {{dictionaryService.dictionary.controlPanel.processingStatus.materialStatus[row.processingStatus]}}
          </td>
          <td class="cp-table-data-cell cp-column-retry-counter">
            {{row.counter}}
          </td>
          <td class="cp-table-data-cell cp-column-action">
            <div class="field-box-checkbox margin-center" *ngIf="row.processingStatus !== FileStatusConstants.VIRTUAL">
              <div><label
                class="field-label"></label></div>
              <div style="text-align: center">
                <input class="playlist-element-checkbox" type="checkbox" [(ngModel)]="row.isChecked">
              </div>
            </div>
          </td>

          <!-- -------------------- ------------------------- ------------WIZUALNE ROZGRANICZENIE-->
          <!--Są dane FTP-->
          <ng-container
            *ngIf="(row.rowStatus == FtpConstants.DB_AND_FTP || row.rowStatus === FtpConstants.SIZE_DIFF) && row.processingStatus !== FileStatusConstants.VIRTUAL">
            <td class="cp-table-data-cell cp-column-file-name-ftp">
              {{row.ftpFileName}}
            </td>
            <td class="cp-table-data-cell cp-column-date">
              <ng-container *ngIf="isIE">
                {{row.ftpFileDate | date:'yyyy-MM-dd'}} {{zeroPrefixHours(row.ftpFileDate)}}{{zeroPrefixMinutes(row.ftpFileDate)}}
              </ng-container>
              <ng-container *ngIf="!isIE">
                {{row.ftpFileDate | date:'yyyy-MM-dd HH:mm'}}
              </ng-container>
            </td>
            <td class="cp-table-data-cell cp-column-file-size"
                [ngClass]="{'cp-table-diff-size':row.rowStatus === FtpConstants.SIZE_DIFF}">
              {{(row.ftpFileSize != null ? (row.ftpFileSize + ' B') : null)}}
            </td>
          </ng-container>
          <!--Brak danych FTP-->
          <ng-container
            *ngIf="(row.rowStatus == FtpConstants.ONLY_DB || row.rowStatus == FtpConstants.NO_FILE_INFO) && row.processingStatus !== FileStatusConstants.VIRTUAL">
            <td colspan="3" class="cp-column-no-file-info">
              {{dictionaryService.dictionary.controlPanel.fileTable.info.fileNotFound}}
            </td>
          </ng-container>
          <ng-container *ngIf="row.processingStatus === FileStatusConstants.VIRTUAL">
            <td colspan="3" class="cp-column-virtual-material">
              {{dictionaryService.dictionary.controlPanel.fileTable.info.notApplicable}}
            </td>
          </ng-container>
          <!-- informacje o połączeniu ftp -->
          <td class="cp-column-file-ftp-host">
            {{row.ftpHost}}
          </td>
          <td class="cp-column-file-ftp-port">
            {{row.ftpPort}}
          </td>
          <td class="cp-column-file-ftp-user">
            {{row.ftpUser}}
          </td>
          <td class="cp-column-file-ftp-dir">
            {{row.ftpDir}}
          </td>

        </tr>
      </ng-container>
      <tr *ngIf="searchStarted && filesHistory?.length <= 0">
        <td colspan="13" class="no-results-found" style="height: 60px;">
          {{dictionaryService.dictionary.controlPanel.fileTable.info.noResults}} <i class="material-icons">do_not_disturb</i>
        </td>
      </tr>
      <tr *ngIf="!searchStarted">
        <td colspan="13" class="cp-choose-filters" style="height: 60px;">
          {{dictionaryService.dictionary.controlPanel.fileTable.info.chooseFilter}}
        </td>
      </tr>
      </tbody>
    </table>


  </div>


</div>
