/**
 * Created by sebastian on 03.04.17.
 */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {PlaylistDetailsService} from "../playlist-details-table/playlist-details-table.service";
import {LoaderService} from "../../common/loader.service";
import {NotifyService} from "../../common/notify.service";
import {DictionaryService} from "../../common/dictionary-service";
import {ChannelBean, PlaylistDetailsBean, PlaylistScheduleDetails, UserBean} from "../../model/generated/dtos";
import {MaterialStatusConstants} from '../../common/constant/material-status-constants';

@Component({
  selector: 'playlist-details-search',
  templateUrl: './playlist-details-search.component.html'
})
export class PlaylistDetailSearch implements OnInit {

  @Output() detailsData: EventEmitter<Observable<PlaylistDetailsBean[]>> = new EventEmitter();
  @Input() selectedChannel: ChannelBean;
  @Input() playlistId: number;
  @Input() statusTranslation: any;
  @Input() user: UserBean;
  @Input() playlistBean: PlaylistScheduleDetails;

  materialStsOpen: boolean;
  blockTypeOpen: boolean;
  materialStatuses: string[];

  chosenMaterialStatus: any;
  chosenBlockType: any;
  startTime: string;
  endTime: string;
  partnerId;
  adminStatuses: string[] = MaterialStatusConstants.ADMIN_STATUSES;
  userStatuses: string[] = MaterialStatusConstants.USER_STATUSES;
  blockTypes: string[] = ['%', 'OS', 'R', 'B'];

  private sortSubscription: Subscription;

  public isMissingMaterial = false;


  constructor(private playlistDetailsService: PlaylistDetailsService,
              private loaderService: LoaderService,
              private notifyService: NotifyService,
              protected dictionaryService: DictionaryService) {
  };

  ngOnInit(): void {
    this.blockTypeOpen = false;
    this.chosenBlockType = this.blockTypes[0];

    this.materialStatuses = this.user && this.user.role && (this.user.role == 'ADMIN' || this.user.role == 'OPERATOR') ? this.adminStatuses : this.userStatuses;
    this.chosenMaterialStatus = this.materialStatuses[0];

    this.followSortSubscription();
    this.isMissingMaterial = this.playlistDetailsService.isMissingMaterialsLink;
  }

  clearFiltr(): void {
    this.chosenMaterialStatus = this.materialStatuses[0];
    this.isMissingMaterial = false;
    this.startTime = '';
    this.endTime = '';
    this.chosenBlockType = this.blockTypes[0];
    this.partnerId = '';
    this.filter();
  }

  filter(sortBy?: string, sortDirection?: string): void {
    this.loaderService.showLoader();
    let oldSelectedStatus = this.chosenMaterialStatus;
    this.checkMaterialStatus();
    if (sortBy != null && sortBy != "") {
      this.detailsData.emit(this.playlistDetailsService.getDetailsByFilters(this.playlistId, this.chosenMaterialStatus, this.getFullTime(this.startTime, true), this.getFullTime(this.endTime, false), this.chosenBlockType, this.partnerId, sortBy, sortDirection));
    } else {
      sortBy = "blockStartTimecode";
      sortDirection = "asc";
      this.detailsData.emit(this.playlistDetailsService.getDetailsByFilters(this.playlistId, this.chosenMaterialStatus, this.getFullTime(this.startTime, true), this.getFullTime(this.endTime, false), this.chosenBlockType, this.partnerId, sortBy, sortDirection));
    }
    this.chosenMaterialStatus = oldSelectedStatus;
  }

  getFullTime(time: string, isStart: boolean): string {
    if (time == null)
      return null;
    else if (time != null && time.trim().length == 0)
      return null;
    else {
      if (isStart)
        return time + ":00";
      else
        return time + ":59";
    }
  }

  checkMaterialStatus() {
    if (this.isMissingMaterial)
      this.chosenMaterialStatus = MaterialStatusConstants.ALL_MISSING;
  }

  choseStatusFilter(status: any) {
    this.chosenMaterialStatus = status;
  }

  followSortSubscription() {
    this.sortSubscription = this.notifyService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'playlistDetailsSearchSortParameters') {
        if (res.value instanceof Object) {
          this.filter(res.value.sortBy, res.value.sortDirection);
        }
      }
    });
  }

  choseBlockTypeFilter(blockType: any) {
    this.chosenBlockType = blockType;
  }

  changeIsMissingMaterial() {
    this.isMissingMaterial = !this.isMissingMaterial;
    this.filter();
  }

  openListIfCheckboxNotChecked() {
    if (!this.isMissingMaterial) {
      this.materialStsOpen = !this.materialStsOpen;
    }
  }

}
