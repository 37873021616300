import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FtpBean} from "../model/generated/dtos";
import {CommonResultBean} from "../model/generated/dtos";
import {ProfileBean} from "../model/generated/dtos";
import {newFtpBean} from "../common/constructors/ftp-bean";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class FtpProfileService {

  checkConnectionProfileFtpUrl: string = environment.BASE_API_URL + 'profiles/checkProfileFtpConnection';
  connected: boolean;

  constructor(private http: HttpClient) {}

  isConnected(profileBean: ProfileBean): Observable<CommonResultBean> {

    let ftpBean: FtpBean = newFtpBean(profileBean.id, profileBean.ftpType, profileBean.ftpHost, profileBean.ftpPort, profileBean.ftpUsername, profileBean.ftpPassword, profileBean.ftpUrl, profileBean.ftpUrlIn)
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<CommonResultBean>(this.checkConnectionProfileFtpUrl, ftpBean, { headers });
  }
}
