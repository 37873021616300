import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DictionaryService} from '../../common/dictionary-service';
import {PlaylistElementBean, PlaylistElementFilterBean, UserBean} from '../../model/generated/dtos';
import {StatisticsService} from '../statistics.service';
import {PlaylistSearchService} from '../../schedule/search-playlist/search-playlist.service';
import {LoaderService} from '../../common/loader.service';
import {HeaderElement} from './headerElement';
import {CommonMessageService} from '../../common/common-message-service';
import {PlaylistElementBeanExtend} from '../../model/generated/common';
import {MaterialStatusConstants} from '../../common/constant/material-status-constants';

@Component({
  selector: 'playlists-elements',
  templateUrl: './playlists-elements.component.html',
  styleUrls: ['./playlists-elements.component.css']
})
export class PlaylistsElementsComponent implements OnInit {

  @Output() public retryCompleted = new EventEmitter();
  @Input() user: UserBean;

  searchFilter: PlaylistElementFilterBean = {} as PlaylistElementFilterBean;
  playlistElements: PlaylistElementBeanExtend[] = [];
  expanded: boolean[] = [];
  statusesOpen = false;
  pageSizeOpen = false;
  dateOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false
  };


  // kanał & grupa kanałowa
  searchCurrentChannelGroup: string;
  foundFilterChannelGroupNames: string[];
  searchCurrentChannel: string;
  foundFilterChannels: string[];

  showMore = true;
  materialStatusDetailsColumnIndex: number;
  materialStatusDetailsTextLength = 60;

  materialStatuses: string[] = MaterialStatusConstants.STATISTICS_MATERIAL_STATUSES;
  materialStatusesWithError: string[] = MaterialStatusConstants.ERROR_STATUSES;
  materialStatusesForInput: { label: string, value: string }[];

  pageSizes: number[] = [5, 10, 25, 50, 100, 250, 500, 1000];

  headersBase: HeaderElement[] = [
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.code,
      propertyName: 'code',
      selected: false,
      asc: false,
      clickable: true,
      center: false,
      hidden: false,
      width: 5
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.materialName,
      propertyName: 'materialName',
      selected: false,
      asc: true,
      clickable: true,
      center: false,
      hidden: false,
      width: 0
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.channelGroup,
      propertyName: 'channelGroup',
      selected: false,
      asc: true,
      clickable: true,
      center: false,
      hidden: false,
      width: 10
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.channel,
      propertyName: 'channel',
      selected: false,
      asc: true,
      clickable: true,
      center: false,
      hidden: false,
      width: 12
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.playlistDate,
      propertyName: 'playlistDate',
      selected: false,
      asc: true,
      clickable: true,
      center: false,
      hidden: false,
      width: 5
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.materialStatus,
      propertyName: 'materialStatus',
      selected: false,
      asc: true,
      clickable: false,
      center: false,
      hidden: false,
      width: 8
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.materialStatusDetails,
      propertyName: 'materialStatusDetails',
      selected: false,
      asc: true,
      clickable: false,
      center: false,
      hidden: false,
      width: 22
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.statusDate,
      propertyName: 'statusDate',
      selected: true,
      asc: false,
      clickable: true,
      center: false,
      hidden: false,
      width: 9
    },
    {
      name: this.dictionaryService.dictionary.statistics.playlistElements.actionTableHeader,
      propertyName: 'chosenMaterial',
      selected: false,
      asc: true,
      clickable: false,
      center: false,
      hidden: false,
      width: 5
    }
  ];

  headersView: HeaderElement[] = this.headersBase;

  isAllChecked = false;

  checkboxEnabled = true;
  retryCopyEnabled = false;
  statuses: any;

  constructor(public dictionaryService: DictionaryService,
              private statisticsService: StatisticsService,
              private playlistSearchService: PlaylistSearchService,
              private messageService: CommonMessageService,
              protected loaderService: LoaderService) {
  }

  ngOnInit() {
    this.materialStatusDetailsColumnIndex = this.headersBase.findIndex((header) => {
      return header.propertyName === 'materialStatusDetails';
    });
    this.initSearchFilter();
    this.clearFiltersAndGetElements();
    this.materialStatusesForInput = this.materialStatuses.map(status => {
      return {label: this.dictionaryService.dictionary.statistics.statuses[status], value: status};
    });
  }

  initSearchFilter() {
    this.searchFilter.sortBy = 'statusDate';
    this.searchFilter.sortDirection = 'DESC';
    this.searchFilter.pageSize = 50;
  }


  clearFiltersAndGetElements() {
    this.searchFilter.statusFilter = MaterialStatusConstants.COPYING_ERROR;
    this.checkEnabledCheckboxForStatuses();
    this.searchFilter.pageNumber = 0;
    this.searchFilter.playListDateFromFilter = new Date(new Date().setHours(0, 0, 0, 0));
    this.searchFilter.playListDateToFilter = null;
    this.searchFilter.materialNameFilter = null;
    this.searchFilter.codeFilter = null;
    this.searchCurrentChannelGroup = null;
    this.searchCurrentChannel = null;
    this.refreshPlaylistsElements();
  }


  toggleArrow(index: number, header: any): void {
    if (this.headersView[index].clickable) {
      if (this.headersView[index].selected) {
        this.headersView[index].asc = !this.headersView[index].asc;
      } else {
        this.headersView.forEach(headerElement => headerElement.selected = false);
        this.headersView[index].selected = true;
      }
      this.searchFilter.sortBy = header.propertyName;
      this.searchFilter.sortDirection = (header.asc ? 'ASC' : 'DESC');
      this.refreshPlaylistsElements();
    }
  }

  nextPage() {
    this.searchFilter.pageNumber++;
    this.getPlaylistsElements();
  }

  switchCheckAll() {
    this.checkAll(!this.isAllChecked);
  }

  checkAll(value: boolean) {
    this.isAllChecked = value;
    this.playlistElements.forEach(el => {
      el.isChecked = this.isAllChecked;
    })
  }

  refreshPlaylistsElements() {
    this.searchFilter.pageNumber = 0;
    this.playlistElements = [];
    this.expanded = [];
    this.getPlaylistsElements();
  }

  changePageSize(rows: number) {
    this.searchFilter.pageSize = rows;
    this.refreshPlaylistsElements();
  }

  checkEnabledCheckboxForStatuses() {
    if (this.searchFilter.statusFilter === MaterialStatusConstants.VIRTUAL
      || this.searchFilter.statusFilter === MaterialStatusConstants.MATERIAL_NULL) {
      this.checkboxEnabled = false;
      this.retryCopyEnabled = false;
    } else {
      this.checkboxEnabled = true;
      this.retryCopyEnabled = this.searchFilter.statusFilter === MaterialStatusConstants.COPYING_ERROR;
    }

    this.updateHeader();
  }

  updateHeader() {
    if (this.checkboxEnabled) {
      this.headersView = this.headersBase;
    } else {
      this.headersView = this.headersBase.slice(0, this.headersBase.length - 1);
    }
  }


  getPlaylistsElements() {
    this.setChannelGroupAndChannelFilter();
    this.headersBase[this.materialStatusDetailsColumnIndex].hidden = this.materialStatusesWithError.findIndex((status: string) => {
      return this.searchFilter.statusFilter === status;
    }) === -1;
    this.checkEnabledCheckboxForStatuses();

    if (this.searchFilter != null) {
      if (this.searchFilter.playListDateToFilter != null) {
        this.searchFilter.playListDateToFilter.setHours(23, 59, 59, 999);
      }

      if (this.searchFilter.codeFilter != null) {
        this.searchFilter.codeFilter = this.searchFilter.codeFilter.trim();
      }

      if (this.searchFilter.materialNameFilter != null) {
        this.searchFilter.materialNameFilter = this.searchFilter.materialNameFilter.trim();
      }

    }

    this.loaderService.showLoader();
    this.statisticsService.getPlaylistsElementsForUser(this.searchFilter, this.user.id).subscribe(
      resp => {
        resp.elements.forEach(el => {
          this.playlistElements.push({...el, isChecked: false});
          this.expanded.push(false);
        });
        this.searchFilter.resultCount = resp.resultCount;
        this.checkAll(false);
        this.showMore = (this.playlistElements.length < resp.resultCount);
        this.loaderService.hideLoader();
      },
      () => {
        this.loaderService.hideLoader();
        this.messageService.error(this.dictionaryService.dictionary.statistics['getElementsError']);
      }
    );
  }

  searchFilterChannels(event) {
    if (this.searchCurrentChannelGroup == null || this.searchCurrentChannelGroup.trim().length <= 0) {
      if (this.user.role === 'MANAGER' || this.user.role === 'USER') {
        this.playlistSearchService.getUserChannelsByName(event.query, '').subscribe(data => {
          this.foundFilterChannels = data.map(d => d.name);
        });
      } else {
        this.playlistSearchService.getChannelsByName(event.query, '').subscribe(data => {
          this.foundFilterChannels = data.map(d => d.name);
        });
      }
    } else {
      this.playlistSearchService.getChannelsByNameAndChannelGroupName(event.query, this.searchCurrentChannelGroup).subscribe(data => {
        this.foundFilterChannels = data.map(d => d.name);
      });
    }
  }

  searchFilterChannelGroups(event): void {
    this.playlistSearchService.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.foundFilterChannelGroupNames = data.map(d => d.name);
    });
  }

  setChannelGroupAndChannelFilter() {
    if (this.searchCurrentChannel != null && this.searchCurrentChannel.trim().length > 0) {
      this.searchFilter.channelNameFilter = this.searchCurrentChannel.trim();
    } else {
      this.searchFilter.channelIdFilter = null;
      this.searchFilter.channelNameFilter = null;
    }

    if (this.searchCurrentChannelGroup != null && this.searchCurrentChannelGroup.trim().length > 0) {
      this.searchFilter.channelGroupNameFilter = this.searchCurrentChannelGroup.trim();
    } else {
      this.searchFilter.channelGroupIdFilter = null;
      this.searchFilter.channelGroupNameFilter = null;
    }
  }

  selectChannel(event) {
    this.searchCurrentChannel = event;
    this.searchCurrentChannel = event.name;
  }

  // Ponawianie materiałów
  retrySelected(retryCopying: boolean) {
    this.loaderService.showLoader();
    const elementsToProcessing: PlaylistElementBean[] = [];
    this.playlistElements.forEach(el => {
      if (el.isChecked) {
        elementsToProcessing.push({...el});
      }
    });

    if (elementsToProcessing.length > 0) {
      this.statisticsService.retryMaterialsProcessing(retryCopying, elementsToProcessing).subscribe(
        resp => {
          this.loaderService.hideLoader();
          this.retryCompleted.emit();
          this.messageService.success(this.getSuccesMsg(retryCopying, resp));
          this.refreshPlaylistsElements();
        },
        () => {
          this.loaderService.hideLoader();
          this.messageService.error(this.dictionaryService.dictionary.statistics['retryProcessingError']);
        });
    } else {
      this.loaderService.hideLoader();
      this.messageService.error(this.dictionaryService.dictionary.statistics['retryProcessingZeroError']);
    }
  }

  getSuccesMsg(retryCopying: boolean, processedElements: number): string {
    if (!(typeof processedElements === 'number')) {
      processedElements = 0;
    }
    let message: string;
    if (processedElements > 0) {
      message = (retryCopying ?
        this.dictionaryService.dictionary.statistics['retryCopyingResponse'] : this.dictionaryService.dictionary.statistics['retryProcessingResponse']);
      message = message.replace('$', processedElements.toString(10));
    } else {
      message = (retryCopying ?
        this.dictionaryService.dictionary.statistics['retryCopyingZeroErrorResp'] : this.dictionaryService.dictionary.statistics['retryProcessingZeroErrorResp']);
    }
    return message;
  }

  expandShrink(index: number) {
    this.expanded[index] = !this.expanded[index];
  }
}
