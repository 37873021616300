import {Component, OnInit} from '@angular/core';
import {ControlPanelDataService} from './control-panel-data.service';
import {ChannelBean, ChannelsGroupBean, CompareHistoryAndFtpFilesBean} from '../model/generated/dtos';
import {DictionaryService} from '../common/dictionary-service';
import {PlaylistSearchService} from '../schedule/search-playlist/search-playlist.service';
import {LoaderService} from '../common/loader.service';
import {GlobalUtilService} from '../common/global-util.service';
import {DatePipe} from '@angular/common';
import {CommonMessageService} from '../common/common-message-service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {MaterialStatusConstants} from '../common/constant/material-status-constants';
import {CompareHistoryAndFtpFilesBeanExtend} from '../model/generated/common';
import {ControlPanelMaterialStatusConstants} from '../common/constant/control-panel-material-status-constants';
import {FtpConstants} from '../common/constant/ftp-constants';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  providers: [ControlPanelDataService]
})
export class ControlPanelComponent implements OnInit {

  public filesHistory: CompareHistoryAndFtpFilesBeanExtend[] = [];

  // inputs
  public channelSelected: ChannelBean;
  public channelsGroupSelected: ChannelsGroupBean;
  public channelList: ChannelBean[];
  public channelsGroupList: ChannelsGroupBean[];
  public playlistDateFrom: Date;
  public playlistDateTo: Date;
  public filterMaterialName: string;
  public filterFileName: string;
  public filterStatus: string;
  public filterFileSize = 0; // -1=rozmiary różne, 1=rozmiary równe, 0=nie dotyczy
  public isGroupSelected = null; // null=nic nie wybrano, true=grupa, false=kanał

  public searchStarted = false;
  isAllChecked = false;

  FileStatusConstants = MaterialStatusConstants;
  FtpConstants = FtpConstants;

  public isIE = false;

  // walidacje
  public minPlaylistDate: Date = new Date();
  public maxPlaylistDate: Date = new Date();

  public materialSelectStatuses: string[] = ControlPanelMaterialStatusConstants.ALL_STATUSES;

  materialStatusesForInput: { label: string, value: string }[];

  public validationMessage: string = null;

  public statusesOpen = false;

  constructor(private controlPanelDataService: ControlPanelDataService,
              public dictionaryService: DictionaryService,
              private playlistSearchService: PlaylistSearchService,
              private messageService: CommonMessageService,
              protected loaderService: LoaderService,
              private globalUtilService: GlobalUtilService,
              public datePipe: DatePipe) {
  }


  chooseStatus(status: string) {
    this.filterStatus = status;
  }

  ngOnInit() {
    this.isIE = this.globalUtilService.isIE();
    this.minPlaylistDate = new Date();

    this.controlPanelDataService.getParameters().subscribe({
      next: data => {
        this.minPlaylistDate.setDate(this.minPlaylistDate.getDate() - data.daysBackward);
        this.maxPlaylistDate.setDate(this.maxPlaylistDate.getDate() + data.daysForward);
      },
      error: () => {
        this.maxPlaylistDate.setDate(this.maxPlaylistDate.getDate() + 14);
      },
      complete: () => {
        this.minPlaylistDate.setHours(0, 0, 0, 0);
        this.maxPlaylistDate.setHours(23, 59, 59, 999);
      },
    });
    this.materialStatusesForInput = [{
      label: this.dictionaryService.dictionary.controlPanel.processingStatus.any,
      value: null
    }
    ].concat(
      this.materialSelectStatuses.map(status => ({
        label: this.dictionaryService.dictionary.controlPanel.processingStatus.materialSelect[status],
        value: status
      }))
    );
  }

  getFilteredData() {
    this.isAllChecked = false;

    this.loaderService.showLoader();
    this.controlPanelDataService.getFileHistory(
      this.channelsGroupSelected?.name,
      this.channelSelected?.name,
      this.playlistDateFrom,
      this.playlistDateTo,
      this.filterMaterialName,
      this.filterFileName,
      this.filterStatus,
      this.filterFileSize
    ).subscribe({
      next: data => {
        this.searchStarted = true;
        this.filesHistory = data.map(fileHistory => ({...fileHistory, isChecked: false}));
        this.loaderService.hideLoader();
      },
      error: () => {
        this.loaderService.hideLoader();
      }
    });
  }

  searchFilterChannels(event) {
    this.playlistSearchService.getChannelsByNameAndOmitDiffGenFalse(event.query).subscribe(data => {
        this.channelList = data;
      },
      () => {
        this.messageService.error(this.dictionaryService.dictionary.controlPanel.errors.getChannels);
      });
  }

  validateAndFilter() {
    if (this.isGroupSelected == null) {
      this.validationMessage = this.dictionaryService.dictionary.controlPanel.errors.channelsGroupOrChannelNull;
      return;
    }

    // puste
    if (this.playlistDateFrom == null || this.playlistDateTo == null) {
      this.validationMessage = this.dictionaryService.dictionary.controlPanel.errors.datesNull;
      return;
    }

    // kolejność
    if (this.playlistDateFrom > this.playlistDateTo) {
      this.validationMessage = this.dictionaryService.dictionary.controlPanel.errors.datesOrder;
      return;
    }

    let validationStart = new Date(this.playlistDateFrom).setHours(this.minPlaylistDate.getHours() + 1);
    if (validationStart < this.minPlaylistDate.getTime() || this.playlistDateTo > this.maxPlaylistDate) {
      this.validationMessage = this.dictionaryService.dictionary.controlPanel.errors.datesRange +
        this.getFormattedDate(this.minPlaylistDate) + " - " + this.getFormattedDate(this.maxPlaylistDate);
      return;
    }

    this.validationMessage = null;
    this.getFilteredData();
  }

  clearFilters() {
    // this.channelSelected = null;
    this.filterMaterialName = null;
    this.filterFileName = null;
    this.filterStatus = null;
    this.isGroupSelected = null;
    this.channelSelected = null;
    this.channelsGroupSelected = null;
    this.validationMessage = null;
    this.filesHistory = [];
  }

  fileAction(file: CompareHistoryAndFtpFilesBean) {
    this.loaderService.showLoader();
    this.controlPanelDataService.doFileAction(file).subscribe(resp => {
      if (resp === true) {
        this.loaderService.hideLoader();
        this.messageService.success(this.dictionaryService.dictionary.controlPanel.actions.retry_ok);
        this.validateAndFilter();
      } else {
        this.loaderService.hideLoader();
        this.messageService.error(this.dictionaryService.dictionary.controlPanel.actions.retry_error);
      }

    }, () => {
      this.loaderService.hideLoader();
    });
  }


  // PRZYCISKI AKCJI GUI
  filterButtonAction() {
    this.filterFileSize = 0;
    this.validateAndFilter();
  }

  clearButtonAction() {
    this.filterFileSize = 0;
    this.clearFilters();
  }

  equalsButtonAction() {
    this.filterFileSize = 1;
    this.validateAndFilter();
  }

  notEqualsButtonAction() {
    this.filterFileSize = -1;
    this.validateAndFilter();
  }

  zeroPrefixMinutes(val: Date): string {
    if (isNotNullOrUndefined(val) && val.getMinutes() != null) {
      return this.zeroPrefix(val.getMinutes());
    } else {
      return null;
    }
  }

  zeroPrefixHours(val: Date): string {
    if (isNotNullOrUndefined(val) && val.getHours()) {
      return this.zeroPrefix(val.getHours()) + ':';
    } else {
      return null;
    }
  }

  zeroPrefix(val: number) {
    if (val > 9) {
      return val.toString(10);
    } else {
      return '0' + val.toString(10);
    }
  }

  getFormattedDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy/MM/dd');
  }

  onPlaylistDateFromChange() {
    if (this.playlistDateTo == null) {
      this.playlistDateTo = new Date(this.playlistDateFrom);
    }
  }

  searchFilterChannelsGroup(event) {
    this.playlistSearchService.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.channelsGroupList = data;
    });
  }

  selectChannelsGroup(event: ChannelsGroupBean) {
    this.channelSelected = null;
    this.channelsGroupSelected = event;
    this.isGroupSelected = true;
  }

  selectChannel(event: ChannelBean) {
    this.channelsGroupSelected = null;
    this.channelSelected = event;
    this.isGroupSelected = false;
  }

  switchCheckAll() {
    this.isAllChecked = !this.isAllChecked;
    this.filesHistory.forEach(el => {
      el.isChecked = this.isAllChecked && el.processingStatus !== MaterialStatusConstants.VIRTUAL;
    })
  }

  retrySelected(retryCopying: boolean) {
    this.loaderService.showLoader();

    const elementsToProcessing: CompareHistoryAndFtpFilesBean[] = [];
    this.filesHistory.forEach(el => {
      if (el.isChecked) {
        elementsToProcessing.push({...el});
      }
    });

    if (elementsToProcessing.length > 0) {
      this.controlPanelDataService.retryMaterialsProcessing(retryCopying, elementsToProcessing).subscribe(
        resp => {
          this.loaderService.hideLoader();
          this.messageService.success(this.getSuccessMsg(retryCopying, resp));
          this.validateAndFilter();
        },
        () => {
          this.loaderService.hideLoader();
          this.messageService.error(this.dictionaryService.dictionary.controlPanel.errors.retryProcessing);
        });
    } else {
      this.loaderService.hideLoader();
      this.messageService.error(this.dictionaryService.dictionary.controlPanel.errors.retryProcessingZero);
    }
  }

  getSuccessMsg(retryCopying: boolean, processedElements: number): string {
    if (!(typeof processedElements === 'number')) {
      processedElements = 0;
    }
    let message: string;
    if (processedElements > 0) {
      message = (retryCopying ? this.dictionaryService.dictionary.controlPanel.errors.retryCopyingResponse
        : this.dictionaryService.dictionary.controlPanel.errors.retryProcessingResponse);
      message = message.replace('$', processedElements.toString(10));
    } else {
      message = (retryCopying ? this.dictionaryService.dictionary.controlPanel.errors.retryCopyingZeroResponse
        : this.dictionaryService.dictionary.controlPanel.errors.retryProcessingZeroResponse);
    }
    return message;
  }
}
