import {Component} from "@angular/core";
import {DictionaryService} from "../../common/dictionary-service";
import {StatisticsService} from "../statistics.service";
import {MaterialMissingBean, MaterialMissingFilterBean} from "../../model/generated/dtos";
import {LoaderService} from "../../common/loader.service";

@Component({
  selector: 'missing-materials',
  templateUrl: './missing-materials.component.html'
})
export class MissingMaterialsComponent {

  constructor(public dictionaryService: DictionaryService,
              private statisticsService: StatisticsService,
              protected loaderService: LoaderService) {

  }

  searchFilter: MaterialMissingFilterBean = {} as MaterialMissingFilterBean;

  missingMaterials: MaterialMissingBean[] = [];

  showMore: boolean = true;


  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.statistics.missingMaterials.materialNameHeader,
      propertyName: 'title',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.statistics.missingMaterials.createDate,
      propertyName: 'createDate',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.statistics.missingMaterials.retryCount,
      propertyName: 'retryCount',
      selected: false,
      asc: true,
      clickable: true
    }
  ];


  ngOnInit() {
    this.initSearchFilter();
    this.clearFiltersAndGetMaterials();
  }

  initSearchFilter() {
    this.searchFilter.sortBy = 'title';
    this.searchFilter.sortDirection = "ASC";
  }


  clearFiltersAndGetMaterials() {
    this.searchFilter.pageNumber = 0;
    this.searchFilter.materialTitleFilter = null;
    this.searchFilter.createDateFromFilter = null;
    this.searchFilter.createDateToFilter = null;

    this.refreshMaterials();
  }

  refreshMaterials() {
    this.searchFilter.pageNumber = 0;
    this.missingMaterials = [];
    this.getMissingMaterials();
  }

  toggleArrow(index: number, header: any): void {
    if (this.headers[index].clickable) {
      if (this.headers[index].selected) {
        this.headers[index].asc = !this.headers[index].asc;
      } else {
        this.headers.forEach(header => header.selected = false);
        this.headers[index].selected = true;
      }
      this.searchFilter.sortBy = header.propertyName;
      this.searchFilter.sortDirection = (header.asc ? "ASC" : "DESC");
      this.refreshMaterials();
    }
  }

  nextPage() {
    this.searchFilter.pageNumber++;
    this.getMissingMaterials();
  }


  getMissingMaterials() {

    if (this.searchFilter != null && this.searchFilter.createDateToFilter != null)
      this.searchFilter.createDateToFilter.setHours(23, 59, 59, 999);

    this.loaderService.showLoader();
    this.statisticsService.getMissingMaterials(this.searchFilter).subscribe(resp => {
        resp.elements.forEach(el => {
          this.missingMaterials.push(el);
        });
        this.showMore = (resp.elements.length == resp.pageSize);
        this.loaderService.hideLoader();
      },
      err => {
        this.loaderService.hideLoader();
        console.error(err)
      });
  }

}
