<div class="table-row">
  <div class="table-row-toggleable-head toggle-row-head flexbox" [ngClass]="{'active': isExpanded}">
    <div class="table-item item-main item-pozycja" (click)="isExpanded=!isExpanded">
      <a class="toggle-trigger">
        <span class="toggle-label">{{dictionaryService.dictionary.playlistDetails.row.expandLabel}}</span>
        <span class="toggle-label-active">{{dictionaryService.dictionary.playlistDetails.row.retractLabel}}</span>
      </a>
    </div>
    <div class="table-item item-poczatek sort-item">
      {{rowDetails.blockStart}}
    </div>
    <div class="table-item item-nazwa">
      {{rowDetails.blockName}}
    </div>
    <div class="table-item item-idtvn">

    </div>

    <div class="table-item item-dl">
      {{rowDetails.blockLength}}
    </div>
    <div class="table-item item-format">

    </div>

    <div class="table-item item-typ">
      {{dictionaryService.dictionary.playlistDetails.search.blockTypes[rowDetails.blockType]}}
    </div>

    <div class="table-item item-piwo">

    </div>
    <div class="table-item item-hfss">

    </div>
    <div class="table-item item-statusmat">
      {{rowDetails.status}}
    </div>
    <div class="table-item item-akcje">

    </div>
  </div>
  <div [ngClass]="{'table-row-toggleable-body toggle-row-body': !isExpanded}">
    <ng-container *ngFor="let detail of rowDetails.childList">
      <div class="table-row flexbox">
        <div class="table-item item-main item-pozycja">
          {{detail.position}}
        </div>
        <div class="table-item item-poczatek sort-item">
          {{detail.blockStart}}
        </div>
        <div class="table-item item-nazwa ">
          {{detail.materialName}}
        </div>
        <div class="table-item item-idtvn">
          {{detail.partnerId}}
        </div>
        <div class="table-item item-dl">
          {{detail.blockLength}}
        </div>
        <div class="table-item item-format">
          {{detail.format}}
        </div>
        <div class="table-item item-typ">
          {{dictionaryService.dictionary.playlistDetails.search.orderTypes[detail.blockType]}}
        </div>
        <div class="table-item item-piwo">
          {{detail.piwo}}
        </div>
        <div class="table-item item-hfss">
          {{detail.hfss}}
        </div>
        <ng-container *ngIf="detail.virtual">
          <div class="table-item item-statusmat item-status virtual-msg">
              <span
                class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
                <i class="material-icons virtual-msg">play_arrow</i>
              </span>
            <span class="item-icon-label">{{statusTranslation[MaterialStatusConstants.VIRTUAL]}}</span>
          </div>
          <div class="table-item item-akcje" title="Odśwież"></div>
        </ng-container>
        <ng-container *ngIf="!detail.virtual">
          <div *ngIf="detail.status === MaterialStatusConstants.MATERIAL_NULL" class="table-item item-statusmat item-status error-msg">
								<span
                  class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
									<i class="material-icons error-msg">warning</i>
								</span>
            <span class="item-icon-label">{{statusTranslation[detail.status]}}</span>
          </div>
          <div *ngIf="detail.status===MaterialStatusConstants.SENT_TO_BROADCASTER"
               class="table-item item-statusmat item-status success-msg" (click)="openPopup(detail.id)">
            <div>
								<span class="dialog-show" data-target="dialog-1">
									<span
                    class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
										<i class="material-icons success-msg">done</i>
									</span>
									<span class="item-icon-label">{{statusTranslation[detail.status]}}</span>
								</span>
            </div>
          </div>
          <div *ngIf="detail.status===MaterialStatusConstants.VIRTUAL" class="table-item item-statusmat item-status virtual-msg">
              <span
                class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
                <i class="material-icons virtual-msg">play_arrow</i>
              </span>
            <span class="item-icon-label">{{statusTranslation[detail.status]}}</span>
          </div>
          <div *ngIf="detail.status===MaterialStatusConstants.FILE_NOT_EXIST" class="table-item item-statusmat item-status error-msg">
								<span
                  class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
									<i class="material-icons error-msg">warning</i>
								</span>
            <span class="item-icon-label">{{statusTranslation[detail.status]}}</span>
          </div>
          <div
            *ngIf="isProcessingStatus(detail.status)"
            class="table-item item-statusmat item-status warning-msg">
              <span
                class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
                <i class="material-icons warning-msg">edit</i>
              </span>
            <span class="item-icon-label">{{statusTranslation[detail.status]}}</span>
          </div>
          <div class="table-item item-akcje"  style="    display: flex;    padding-left: 5px;">
            <span
              *ngIf="([MaterialStatusConstants.FILE_NOT_EXIST,MaterialStatusConstants.DONE,MaterialStatusConstants.PROCESSING_ERROR,MaterialStatusConstants.TECHNICAL_ERROR,MaterialStatusConstants.COPYING_ERROR, MaterialStatusConstants.PROCESSING_FAILED].indexOf(detail.status)> -1
                    && user && (user.role == 'ADMIN' || user.role == 'OPERATOR')) || detail.status == MaterialStatusConstants.SENT_TO_BROADCASTER "
              class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static ml-5"
              (click)="refreshPlayListElement(detail,false)" title="Odśwież">
              <i class="material-icons changed-msg">autorenew</i>
            </span>
            <span
              *ngIf="([MaterialStatusConstants.COPYING_ERROR].indexOf(detail.status)> -1 && user && (user.role == 'ADMIN' || user.role == 'OPERATOR')) "
              class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static ml-5"
              (click)="refreshPlayListElement(detail,true)" title="Ponów kopiowanie">
              <i class="material-icons changed-msg" style="color: #ff7151">replay</i>
            </span>
            <span
              *ngIf="user && (user.role == 'ADMIN' || user.role == 'OPERATOR')"
              class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static ml-5"
                (click)="getPlaylistElementVantageStatus(detail)" title="{{dictionaryService.dictionary.playlistDetails.errorDetails}}">
              <i class="material-icons changed-msg">info</i>
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
