import {Component} from '@angular/core';
import {DictionaryService} from "../common/dictionary-service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../common/loader.service";
import {BaseComponent} from "../base.component";

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends BaseComponent {

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected loaderService: LoaderService,
              public dictionaryService: DictionaryService) {
    super(router, route, loaderService);
  }

  ngOnInit(): void {
  }

}
