import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {UserListService} from "../user-list/user-list.service";
import {UserBean} from "../model/generated/dtos";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UserDetailResolve implements Resolve<UserBean> {
  constructor(private userService: UserListService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<UserBean> | UserBean {
    return this.userService.getCurrentUser();
  }
}
