<div>
  <h2>{{dictionaryService.dictionary.notificationsConfiguration.header}}</h2>
</div>
<dialog class="mdl-dialog" style="max-width: 500px;" id="delete-user-notify-hour-dialog">
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
    <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.notificationsConfiguration.confirmDialog.header}}</h4>
    <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
      <p>
        {{dictionaryService.dictionary.notificationsConfiguration.confirmDialog.questionFirstPart}}{{deleteNotifyUserHour}}:00 {{dictionaryService.dictionary.notificationsConfiguration.confirmDialog.questionSecondPart}}
      </p>
    </div>
    <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button"
              (click)="closeConfirmPopup('delete-user-notify-hour-dialog')">
        {{dictionaryService.dictionary.notificationsConfiguration.confirmDialog.cancelButton}}
      </button>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              (click)="deleteUserHourConfiguration(deleteNotifyUserHour)">
        {{dictionaryService.dictionary.notificationsConfiguration.confirmDialog.confirmButton}}
      </button>
    </div>
  </div>
</dialog>

<dialog class="mdl-dialog" id="user-mail-channel-form-dialog" style="width: 512px;">
  <div class="popup-background-blocker"></div>
  <div class="mdl-dialog__content">
    <div class="profile-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text">{{dictionaryService.dictionary.notificationsConfiguration.dialog.newHourHeader}}</h2>
      </div>
      <form  #userMailChannelForm="ngForm" action="" method="post" class="filters user-mail-channel-form">
        <div class="mdl-card__supporting-text "
             style="    margin-left: auto;    margin-right: auto;    margin-top: 20px;">
          <div class=" flex-end mb-20" style="min-height: 160px">

           <div class="field-box">
              <div><label class="field-label">{{dictionaryService.dictionary.notificationsConfiguration.dialog.newHourLabel}}</label></div>
              <p-dropdown [options]="dailyHoursItems" name="userNotifyHour" [(ngModel)]="selectedHour"
                          placeholder="{{dictionaryService.dictionary.notificationsConfiguration.dialog.newHourPlaceholder}}"
                          [scrollHeight]="'150px'"
                          appendTo="body"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="mdl-card__actions mdl-card--border" style="text-align:right;">
          <button
            class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="submit" (click)="addUserHourConfiguration(selectedHour)">
            {{dictionaryService.dictionary.notificationsConfiguration.dialog.saveButton}}
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="mdl-card__menu">
          <button (click)="closePopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                  type="button">
            <i class="material-icons" style="color: white;">close</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</dialog>


<div class="notify-configuration-info mb-20" *ngIf="user && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
  <p-accordion>
    <p-accordionTab [selected]="true">
      <p-header>
        {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.header}}
      </p-header>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.subHeader}}
      <ul>
        <li>{{dictionaryService.dictionary.notificationsConfiguration.standardSettings.secondOption}}</li>
      </ul>
      <span class="blue-text">{{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.fifthPart}}</span>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.seventhPart}}
    </p-accordionTab>
  </p-accordion>
</div>

<div class="notify-configuration-info mb-20" *ngIf="user && (user.role == 'MANAGER' || user.role == 'USER')">
  <p-accordion>
    <p-accordionTab [selected]="true">
      <p-header>
        {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.header}}
      </p-header>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.subHeader}}
      <ul>
        <li>{{dictionaryService.dictionary.notificationsConfiguration.standardSettings.secondOption}}</li>
      </ul>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.firstPart}}
      <span>{{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.secondPart}}</span>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.thirdPart}}
      <br/>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.fourthPart}}
      <span>{{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.fifthPart}}</span>
      {{dictionaryService.dictionary.notificationsConfiguration.standardSettings.description.sixthPart}}
    </p-accordionTab>
  </p-accordion>
</div>

<div class="notify-configuration" >
<div style="width: 40%" *ngIf="user && (user.role == 'MANAGER' || user.role == 'USER')">
<!--<div style="width: 40%">-->
    <div class="notify-configuration-info mb-25">
      {{dictionaryService.dictionary.notificationsConfiguration.list.header}}
    </div>
    <div class="table-responsive-vertical shadow-z-1">
      <table id="table" class="table table-hover table-mc-light-blue">
        <thead>
        <tr>
          <th *ngFor="let header of headers; let i = index"
              [ngClass]="{'column-header-hover': header.clickable, 'text-max': header.propertyName=='workflowContext'}"
              class="">
            <div (click)="toggleArrow(i, header, false)" class="column-header-content">
              {{header.name}}
              <sort-arrow [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mailUserChannel of mailUserChannelsList">
          <td>{{mailUserChannel.channel.name}}</td>
          <td style="text-align: center">
            <div class="checkbox-single-box">
              <p-checkbox name="sent" label="" [(ngModel)]="mailUserChannel.sent"
                          (onChange)="setMailUserChannelConfiguration(mailUserChannel)"
                          binary="true"></p-checkbox>
            </div>
          </td>
        </tr>
        <tr *ngIf="mailUserChannelsList.length==0">
          <td colspan="2" class="no-results-found smaller">{{dictionaryService.dictionary.notificationsConfiguration.list.noResults}}<i class="material-icons">do_not_disturb</i>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="bottom-button-wrapper">

      </div>
    </div>
  </div>
  <div style="width: 55%">
    <div class="notify-configuration-info flex">
      <div class="field-box" style="width: 100%; margin-right: 0px">
        <span style="float: left; margin-bottom: 0px" class="notify-configuration-info mb-25">
          {{dictionaryService.dictionary.notificationsConfiguration.list.frequencyConfigurationHeader}}
        </span>
        <button style="float:right;" class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                (click)="openAddHourPopup()">
          {{dictionaryService.dictionary.notificationsConfiguration.list.addButton}}
          <i class="material-icons">alarm_add</i>
        </button>
        <div style="clear: both"></div>
      </div>
    </div>
    <div class="table-responsive-vertical shadow-z-1">
      <table id="aggregatedTable" class="table table-hover table-mc-light-blue">
        <thead>
        <tr>
          <th *ngFor="let headerA of aggregatedHeaders; let i = index"
              [ngClass]="{'column-header-hover': headerA.clickable, 'text-max': headerA.propertyName=='workflowContext'}"
              class="">
            <div (click)="toggleArrow(i, headerA, true)" class="column-header-content">
              {{headerA.name}}
              <sort-arrow [selected]="headerA.selected" [sortAsc]="headerA.asc"></sort-arrow>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let notifHour of userNotifHoursList">
          <td>{{notifHour}}:00</td>
          <td>
            <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                    type="submit" (click)="openConfirmHourDeletePopup(notifHour)">
              {{dictionaryService.dictionary.notificationsConfiguration.list.deleteButton}}
              <i class="material-icons">delete</i>
            </button>

          </td>
        </tr>
        <tr *ngIf="userNotifHoursList.length==0">
          <td colspan="2" class="no-results-found smaller">{{dictionaryService.dictionary.notificationsConfiguration.list.noResults}}<i class="material-icons">do_not_disturb</i>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="bottom-button-wrapper">
      </div>
    </div>
    <ng-container *ngIf="isAdmin">
      <div class="notify-configuration-info flex">
        <div class="field-box" style="width: 100%; margin-right: 0px">
        <span style="float: left; margin-bottom: 0px" class="notify-configuration-info mb-25">
          {{dictionaryService.dictionary.notificationsConfiguration.list.diffReportHeader}}
        </span>
        <span style="float: left; margin-left: 20px">
          <button (click)="diffReportGenerationCheckAll()"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  type="submit">
            {{dictionaryService.dictionary.notificationsConfiguration.list.checkAll}}
          </button>
          <button (click)="diffReportGenerationUncheckAll()" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mb-5">
            {{dictionaryService.dictionary.notificationsConfiguration.list.uncheckAll}}
          </button>
        </span>

          <div style="clear: both"></div>
        </div>
      </div>
      <div class="table-responsive-vertical shadow-z-1">
        <table id="diffReportTable" class="table table-hover table-mc-light-blue">
          <thead>
          <tr>
            <th *ngFor="let headerA of diffReportsConfigHeaders; let i = index"
                [ngClass]="{'column-header-hover': headerA.clickable, 'text-max': headerA.propertyName=='workflowContext'}"
                class="">
              <div (click)="toggleArrow(i, headerA, true)" class="column-header-content">
                {{headerA.name}}
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let diffGeneration of adminProfileDiffGeneration">
            <td>{{diffGeneration.profileName}}</td>
            <td>
              <p-checkbox [(ngModel)]="diffGeneration.generateDiffReport" binary="true"
                          (onChange)="diffReportGenerationChange(diffGeneration)"></p-checkbox>
            </td>
          </tr>
          <tr *ngIf="adminProfileDiffGeneration.length==0">
            <td colspan="2" class="no-results-found smaller">{{dictionaryService.dictionary.notificationsConfiguration.list.noResults}}<i class="material-icons">do_not_disturb</i>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="bottom-button-wrapper">
        </div>
      </div>
    </ng-container>
  </div>
</div>

