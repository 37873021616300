import {Component, OnInit} from "@angular/core";
import {UserListService} from "app/user-list/user-list.service";
import {Router} from "@angular/router";
import {DictionaryService} from "../common/dictionary-service";
import {LoaderService} from "../common/loader.service";
import {CommonMessageService} from "../common/common-message-service";

@Component({
  selector: 'user-agreement',
  templateUrl: './user-agreement.component.html'
})
export class UserAgreementComponent implements OnInit {

  agreement1: boolean;
  agreement2: boolean;
  agreement3: boolean;
  agreement4: boolean;

  termsLocation: string;

  constructor(private userListService: UserListService,
              private messageService: CommonMessageService,
              protected router: Router,
              protected dictionaryService: DictionaryService,
              protected loaderService: LoaderService) {

  }

  ngOnInit(): void {
  }

  saveUserAgreements() {
    if (this.agreement1 && this.agreement2 && this.agreement3 && this.agreement4) {
      var that = this;
      this.userListService.saveUserAgreements().subscribe(result => {
        this.messageService.success(this.dictionaryService.dictionary.userAgreement.messages.agreementsAccepted);
        this.userListService.setCurrentUser(result);
        this.userListService.notifyUserChange();
        setTimeout(function () { that.router.navigate(['/home']);}, 50);
      });
    } else {
      this.messageService.error(this.dictionaryService.dictionary.userAgreement.messages.agreementAcceptanceRequired);
    }
  }
}
