import {Component, OnInit} from "@angular/core";
import {DictionaryService} from "../../common/dictionary-service";
import {StatisticsService} from "../statistics.service";
import {PlaylistsElementsStatisticsBean} from "../../model/generated/dtos";
import {CommonMessageService} from "../../common/common-message-service";
import {MaterialStatusConstants} from '../../common/constant/material-status-constants';

@Component({
  selector: 'statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html'
})
export class StatisticsDashboardComponent implements OnInit {

  public statistics: PlaylistsElementsStatisticsBean = {} as PlaylistsElementsStatisticsBean;

  MaterialStatusConstants = MaterialStatusConstants;

  constructor(public dictionaryService: DictionaryService,
              private statisticsService: StatisticsService,
              private messageService: CommonMessageService) {
  }

  isLoading: boolean = false;

  ngOnInit(): void {
    this.getStatistics();
  }

  getStatistics() {
    this.isLoading = true;
    this.statisticsService.getStatistics().subscribe(
      resp => {
        this.statistics = resp;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.messageService.error(this.dictionaryService.dictionary.statistics['getElementsError']);
      }
    );
  }
}
