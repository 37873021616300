/**
 * Created by sebastian on 10.05.17.
 */
import {Component, Input, OnInit} from "@angular/core";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistBean, PlaylistCompareBean} from "../../model/generated/dtos";

/**
 * Created by sebastian on 08.05.17.
 */
@Component({
  selector: 'playlist-compare-table',
  templateUrl: './playlist-compare-table.component.html'
})
export class PlaylistCompareTable implements OnInit {

  @Input() comparedPlaylists: PlaylistCompareBean[];
  @Input() selectedV1: PlaylistBean;
  @Input() selectedV2: PlaylistBean;

  constructor(protected dictionaryService: DictionaryService) {
  }

  ngOnInit(): void {
  }
}
