<div class="custom-panel flex-center flex-column">

  <dialog class="mdl-dialog" id="import-playlist-form-dialog" style="width: 512px">
    <div class="popup-background-blocker"></div>
    <div class="mdl-dialog__content">
      <div class="import-card-wide mdl-card mdl-shadow--2dp">
        <!--TYTUŁ-->
        <div class="mdl-card__title">
          <h2
            class="mdl-card__title-text">{{dictionaryService.dictionary.importPlaylist.importDraftPlaylistHeader}}</h2>
        </div>

        <form #importForm="ngForm" action="" method="post" class="filters" (ngSubmit)="validateImportForm()" novalidate>

          <!--CIAŁO-->
          <div class="mdl-card__supporting-text"
               style="margin-left: auto; margin-right: auto; margin-top: 20px; min-height: 500px; overflow: visible;">

            <!--FORMULARZ IMPORTU-->
            <div class="playlist-import-form-container">

              <div class="playlist-import-date-info">
                {{dictionaryService.dictionary.importPlaylist.importPossibleDates}}
                <span style="font-weight: bold; font-size: 15px">{{importMinDate |date:'yyyy/MM/dd'}}
                  - {{importMaxDate |date:'yyyy/MM/dd'}}</span>
              </div>

              <!--LEWA KOLUMNA-->
              <div class="playlist-import-form-sources">

                <!--KANAŁ-->
                <div class="field-box">
                  <div><label
                    class="field-label">{{dictionaryService.dictionary.importPlaylist.importDraftPlaylistChannelHeader}}
                    :</label></div>
                  <div class="search-groups--autocomplete disable-all filter-channel playlist-import-select">
                    <p-autoComplete name="channel"
                                    [size]="35"
                                    [placeholder]="dictionaryService.dictionary.importPlaylist.channelsInputPlaceholder"
                                    [(ngModel)]="importRequestBean.channelName"
                                    (ngModelChange)="setImportButtonStateTrue(); checkImportsSelectsDisability()"
                                    [suggestions]="popupFoundChannelNames"
                                    [disabled]="importChannelDisabled"
                                    appendTo="body"
                                    (completeMethod)="searchPopupChannels($event)">
                      <ng-template let-searchCurrentChannel pTemplate="item">
                        <span>{{searchCurrentChannel}}</span>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </div>

                <!--GRUPA KANAŁOWA-->
                <div class="field-box" style="margin-bottom: 10px"
                     *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
                  <div><label
                    class="field-label">{{dictionaryService.dictionary.importPlaylist.importDraftPlaylistChannelGroupHeader}}
                    :</label></div>
                  <div class="search-groups--autocomplete disable-all filter-channel playlist-import-select">
                    <p-autoComplete name="channelGroup"
                                    [size]="35"
                                    [placeholder]="dictionaryService.dictionary.importPlaylist.channelGroupsInputPlaceholder"
                                    [(ngModel)]="importRequestBean.channelGroupName"
                                    (ngModelChange)="setImportButtonStateTrue(); checkImportsSelectsDisability()"
                                    [suggestions]="popupFoundChannelGroupNames"
                                    [disabled]="importChannelGroupDisabled"
                                    appendTo="body"
                                    (completeMethod)="searchImportChannelGroups($event)">
                      <ng-template let-searchCurrentChannelGroup pTemplate="item">
                        <span>{{searchCurrentChannelGroup}}</span>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </div>

                <!--CHECKBOX KANAŁÓW PROFILOWYCH-->
                <div class="field-box" *ngIf="user && user.role && (user.role == 'USER' || user.role == 'MANAGER')">
                  <div><label class="field-label"></label></div>
                  <div class="checkbox-single-box" style="padding-left: 0;padding-top: 21px;">
                    <p-checkbox name="profileChannels"
                                label="{{dictionaryService.dictionary.importPlaylist.profileChannelsImport}}"
                                [(ngModel)]="importRequestBean.fromUser"
                                [disabled]="importFromUserDisabled"
                                (ngModelChange)="checkImportsSelectsDisability()"
                                binary="true">
                    </p-checkbox>
                  </div>
                </div>

              </div>

              <!--PRAWA KOLUMNA-->
              <div class="playlist-import-form-dates">

                <ng-container
                  *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR' || user.role == 'USER' || user.role == 'MANAGER')">
                  <!--DATA OD-->
                  <div class="mb-10">
                    <div class="field-box  user-search-input-margin-bottom">
                      <div><label
                        class="field-label">{{dictionaryService.dictionary.importPlaylist.draftPlaylistDateFromLabel}}
                        :</label></div>
                      <p-calendar class="field"
                                  [readonlyInput]="true"
                                  dateFormat="yy/mm/dd"
                                  selectOtherMonths="true"
                                  placeholder="{{dictionaryService.dictionary.importPlaylist.draftPlaylistDatePlaceholder}}"
                                  [(ngModel)]="importRequestBean.dateFrom"
                                  (ngModelChange)="onImportStartDateChange()"
                                  appendTo="body">
                      </p-calendar>
                    </div>
                  </div>

                  <!--DATA DO-->
                  <div class="mb-10">
                    <div class="field-box  user-search-input-margin-bottom">
                      <div><label
                        class="field-label">{{dictionaryService.dictionary.importPlaylist.draftPlaylistDateToLabel}}
                        :</label></div>
                      <p-calendar class="field"
                                  [readonlyInput]="true"
                                  dateFormat="yy/mm/dd"
                                  selectOtherMonths="true"
                                  placeholder="{{dictionaryService.dictionary.importPlaylist.draftPlaylistDatePlaceholder}}"
                                  [(ngModel)]="importRequestBean.dateTo"
                                  (ngModelChange)="setImportButtonState(true)"
                                  appendTo="body">
                      </p-calendar>
                    </div>
                  </div>
                </ng-container>

                <!--                <ng-container *ngIf="user && user.role && (user.role == 'USER' || user.role == 'MANAGER')">-->
                <!--                  &lt;!&ndash;DZIEN IMPORTU&ndash;&gt;-->
                <!--                  <div class="mb-10">-->
                <!--                    <div class="field-box  user-search-input-margin-bottom">-->
                <!--                      <div><label-->
                <!--                        class="field-label">{{dictionaryService.dictionary.importPlaylist.draftPlaylistImportDateLabel}}-->
                <!--                        :</label></div>-->
                <!--                      <material-datepicker class="font-roboto datepicker-popup playlist-import-datepicker"-->
                <!--                                           name="startSearch"-->
                <!--                                           [(date)]="importRequestBean.dateFrom"-->
                <!--                                           placeholder="{{dictionaryService.dictionary.importPlaylist.draftPlaylistDatePlaceholder}}"-->
                <!--                                           (onSelect)="setImportButtonState(true)"></material-datepicker>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </ng-container>-->


              </div>
            </div>

            <!--POD FORMULARZEM-->
            <div class="" style="margin-bottom: 12px;" *ngIf="importStatus!=IMPORT_STATUS_BEFORE">

              <!--INFO W TRAKCIE-->
              <div class="playlist-import-end-info" *ngIf="importStatus==IMPORT_STATUS_PROCESSING">
                {{dictionaryService.dictionary.importPlaylist.importPlaylistDone}}:
                <span style="font-weight: bold; font-size: 15px"> {{importEndCount}}/{{importTotalCount}} </span>
              </div>
              <div class="playlist-import-current-info"
                   *ngIf="importCurrentPlaylist != null && importStatus==IMPORT_STATUS_PROCESSING">
                {{dictionaryService.dictionary.importPlaylist.importInProgress}}:
                <span style="font-weight: bold; font-size: 15px"> {{importCurrentPlaylist.channelName}}
                  [{{importCurrentPlaylist.playlistDate |date:'yyyy/MM/dd'}}]</span>
              </div>

              <!--PO IMPORCIE-->
              <div class="playlist-import-done-info" *ngIf="importStatus!=IMPORT_STATUS_PROCESSING">
                {{dictionaryService.dictionary.importPlaylist.importSuccessfully}}:
                {{importDoneCount}}/{{importTotalCount}}
              </div>


              <!--LISTA BŁĘDÓW-->
              <ng-container *ngIf="importResponseBean?.errors?.length > 0">
                <div class="playlist-import-list-title">
                  {{dictionaryService.dictionary.importPlaylist.importErrors}}:
                  <span style="font-weight: bold; font-size: 15px">
                  <ng-container>({{importEndCount - importDoneCount}})</ng-container>
                </span>
                </div>
                <div class="playlist-import-list-container">
                  <div class="playlist-import-list-header">
                    <div class="playlist-import-list-row">
                      <div class="playlist-import-list-cell playlist-import-list-cell-channel">
                        {{dictionaryService.dictionary.importPlaylist.importErrorList.channel}}
                      </div>
                      <div class="playlist-import-list-cell playlist-import-list-cell-date">
                        {{dictionaryService.dictionary.importPlaylist.importErrorList.playlistDate}}
                      </div>
                      <div class="playlist-import-list-cell playlist-import-list-cell-description ">
                        {{dictionaryService.dictionary.importPlaylist.importErrorList.description}}
                      </div>
                    </div>
                  </div>
                  <div class="playlist-import-list">
                    <div class="playlist-import-list-row" *ngFor="let error of importResponseBean.errors">
                      <div class="playlist-import-list-cell playlist-import-list-cell-channel">
                        {{error.channelName}}
                      </div>
                      <div class="playlist-import-list-cell playlist-import-list-cell-date">
                        {{error.playlistDate | date: 'yyyy/MM/dd'}}
                      </div>
                      <div class="playlist-import-list-cell playlist-import-list-cell-description ">
                        {{dictionaryService.dictionary.importPlaylist.errors[error.statusCode]}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>

          <!--STOPKA POPUPU-->
          <div class="mdl-card__actions mdl-card--border channel-group-popup-footer-height">
            <div class="channel-group-popup-footer-warning">
            </div>
            <div class="channel-group-popup-footer-button-container">
              <button type="submit" [disabled]="!importButtonActive"
                      class="channel-group-popup-footer-button mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5">
                {{dictionaryService.dictionary.importPlaylist.importButton}}
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
          <div class="mdl-card__menu">
            <button (click)="closeImportPopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                    type="button">
              <i class="material-icons" style="color: white;">close</i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </dialog>

  <dialog class="mdl-dialog" id="change-material-form-dialog" style="width: 512px">
    <div class="popup-background-blocker"></div>
    <div class="mdl-dialog__content">
      <div class="import-card-wide mdl-card mdl-shadow--2dp">
        <!--TYTUŁ-->
        <div class="mdl-card__title">
          <h2 class="mdl-card__title-text">{{dictionaryService.dictionary.changeMaterialPopup.header}}</h2>
        </div>

        <form action="" method="post" class="filters" (ngSubmit)="validateChangeMaterialForm()" novalidate>

          <!--CIAŁO-->
          <div class="mdl-card__supporting-text"
               style="margin-left: auto; margin-right: auto; margin-top: 20px; min-height: 380px; overflow: visible;">

            <!-- FORMULARZ ZMIANY MATERIAŁU -->
            <div class="playlist-import-form-container">

              <div class="playlist-import-date-info">
                {{dictionaryService.dictionary.changeMaterialPopup.possibleDates}}
                <span style="font-weight: bold; font-size: 15px">{{importMinDate |date:'yyyy/MM/dd'}}
                  - {{importMaxDate |date:'yyyy/MM/dd'}}</span>
              </div>

              <!--LEWA KOLUMNA-->
              <div class="playlist-import-form-sources">

                <!--KANAŁ-->
                <div class="mb-8">
                  <div class="field-box">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.importPlaylist.importDraftPlaylistChannelHeader}}
                      :</label></div>
                    <div class="search-groups--autocomplete disable-all filter-channel playlist-import-select">
                      <p-autoComplete name="channel" tabindex="0"
                                      [size]="35"
                                      [placeholder]="dictionaryService.dictionary.importPlaylist.channelsInputPlaceholder"
                                      [(ngModel)]="changeMaterialRequest.channelName"
                                      (ngModelChange)="checkChangeMaterialSelectsDisability()"
                                      [suggestions]="popupFoundChannelNames"
                                      [disabled]="changeMaterialChannelDisabled"
                                      appendTo="body"
                                      (completeMethod)="searchPopupChannels($event)">
                        <ng-template let-searchCurrentChannel pTemplate="item">
                          <span>{{searchCurrentChannel}}</span>
                        </ng-template>
                      </p-autoComplete>
                    </div>
                  </div>
                </div>

                <!--GRUPA KANAŁOWA-->
                <div class="mb-8">
                  <div class="field-box">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.importPlaylist.importDraftPlaylistChannelGroupHeader}}
                      :</label></div>
                    <div class="search-groups--autocomplete disable-all filter-channel playlist-import-select">
                      <p-autoComplete name="channelGroup" tabindex="0"
                                      [size]="35"
                                      [placeholder]="dictionaryService.dictionary.importPlaylist.channelGroupsInputPlaceholder"
                                      [(ngModel)]="changeMaterialRequest.channelGroupName"
                                      (ngModelChange)="checkChangeMaterialSelectsDisability()"
                                      [suggestions]="popupFoundChannelGroupNames"
                                      [disabled]="changeMaterialGroupDisabled"
                                      appendTo="body"
                                      (completeMethod)="searchImportChannelGroups($event)">
                        <ng-template let-searchCurrentChannelGroup pTemplate="item">
                          <span>{{searchCurrentChannelGroup}}</span>
                        </ng-template>
                      </p-autoComplete>
                    </div>
                  </div>
                </div>

                <!--STARY MATERIAŁ-->
                <div class="mb-8">
                  <div class="field-box">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.changeMaterialPopup.oldMaterialHeader}}</label>
                    </div>
                    <div>
                      <input-debounce #oldMaterialInput (value)="getOldMaterialDetails($event)"
                                      [delay]="3000"></input-debounce>
                    </div>
                  </div>
                </div>

              </div>

              <!--PRAWA KOLUMNA-->
              <div class="playlist-import-form-dates">

                <!--DATA OD-->
                <div class="mb-8">
                  <div class="field-box  user-search-input-margin-bottom">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.importPlaylist.draftPlaylistDateFromLabel}}
                      :</label></div>
                    <p-calendar class="field"
                                [readonlyInput]="true"
                                dateFormat="yy/mm/dd"
                                selectOtherMonths="true"
                                placeholder="{{dictionaryService.dictionary.importPlaylist.draftPlaylistDatePlaceholder}}"
                                [(ngModel)]="changeMaterialRequest.dateFrom"
                                (ngModelChange)="onChangeMaterialStartDateChange()"
                                appendTo="body">
                    </p-calendar>
                  </div>
                </div>

                <!--DATA DO-->
                <div class="mb-8">
                  <div class="field-box  user-search-input-margin-bottom">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.importPlaylist.draftPlaylistDateToLabel}}
                      :</label></div>
                    <p-calendar class="field"
                                [readonlyInput]="true"
                                dateFormat="yy/mm/dd"
                                selectOtherMonths="true"
                                placeholder="{{dictionaryService.dictionary.importPlaylist.draftPlaylistDatePlaceholder}}"
                                [(ngModel)]="changeMaterialRequest.dateTo"
                                (ngModelChange)="onChangeMaterialStartDateChange()"
                                appendTo="body">
                    </p-calendar>
                  </div>
                </div>

                <!--NOWY MATERIAŁ-->
                <div class="mb-8">
                  <div class="field-box" style="margin-right: 0;">
                    <div><label
                      class="field-label">{{dictionaryService.dictionary.changeMaterialPopup.newMaterialHeader}}</label>
                    </div>
                    <div>
                      <input-debounce #newMaterialInput (value)="getNewMaterialDetails($event)" [delay]="3000"
                                      tabindex="3"></input-debounce>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <!--POD FORMULARZEM-->
            <div style="margin-bottom: 12px;" *ngIf="oldMaterialDetails || newMaterialDetails">

              <!--TYTYUŁ STAREGO MATERIAŁU-->
              <div *ngIf="oldMaterialDetails" style="font-size: 15px; margin-bottom: 6px;">
                {{dictionaryService.dictionary.changeMaterialPopup.wasTitle}}: {{oldMaterialDetails.title}}
              </div>

              <!--TYTYUŁ NOWEGO MATERIAŁU-->
              <div *ngIf="newMaterialDetails" style="font-size: 15px;">
                {{dictionaryService.dictionary.changeMaterialPopup.nowTitle}}: {{newMaterialDetails.title}}
              </div>
            </div>
          </div>

          <!--STOPKA POPUPU-->
          <div class="mdl-card__actions mdl-card--border channel-group-popup-footer-height">
            <div class="channel-group-popup-footer-warning">
            </div>
            <div class="channel-group-popup-footer-button-container">
              <button type="submit"
                      class="channel-group-popup-footer-button mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5">
                {{dictionaryService.dictionary.changeMaterialPopup.applyChangesButton}}
              </button>
            </div>
          </div>
          <div class="mdl-card__menu">
            <button (click)="closeChangeMaterialPopup()"
                    class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                    type="button">
              <i class="material-icons" style="color: white;">close</i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </dialog>

  <div class="custom-panel--header">
    <div class="search-groups--autocomplete"
         *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
      <p-autoComplete (onSelect)="getSelectedGroup($event)"
                      [size]="35"
                      [placeholder]="dictionaryService.dictionary.playlists.search.choseChannelPlaceholder"
                      [(ngModel)]="selectedChannelGroup"
                      [suggestions]="foundChannelGroups"
                      (completeMethod)="searchChannelsGroup($event)"
                      appendTo="body"
                      field="name">
        <ng-template let-selectedChannelGroup pTemplate="item">
          <span [ngClass]="{'select-all': selectedChannelGroup.id==-1}">{{selectedChannelGroup.name}}</span>
        </ng-template>
      </p-autoComplete>
    </div>

    <div class="weekpicker-box ml-5 playlist-weekpicker-box-height">

      <p-calendar class="field"
                  [readonlyInput]="true"
                  dateFormat="yy/mm/dd"
                  selectOtherMonths="true"
                  [(ngModel)]="date"
                  (ngModelChange)="dateChange()"
                  appendTo="body">
      </p-calendar>
      <div class="nav-prev-next-box">
        <button title="{{dictionaryService.dictionary.playlists.search.previousDayTitle}}"
                (click)="onDecreaseDate(date)" class="mdl-button mdl-button--raised mdl-button-border">
          <i class="material-icons">keyboard_arrow_left</i>
        </button>
        <button title="{{dictionaryService.dictionary.playlists.search.nextDayTitle}}" (click)="onIncreaseDate(date)"
                class="mdl-button mdl-button--raised mdl-button-border">
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
    </div>
    <div class="search-group--buttons ml-5">
      <button [disabled]="selectedGroups.length==0" (click)="onParamChange()"
              class="mdl-button mdl-button--raised mdl-button--accent mr-5"
              *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
        {{dictionaryService.dictionary.playlists.search.searchButtonLabel}}
        <i class="material-icons small">search</i>
      </button>
      <button (click)="onClearClick()" class="mdl-button mdl-button--white mdl-button--grayborder">
        {{user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR') ?
        dictionaryService.dictionary.playlists.search.clearButtonLabel :
        dictionaryService.dictionary.playlists.search.restoreButtonLabel}}
      </button>
    </div>
    <div style=" margin-left: auto;">
      <button *ngIf="user && user.role && (user.role == 'MANAGER' || user.role == 'USER') && diffGenerationAvl"
              (click)="generateDivergencesReport()"
              class="mdl-button mdl-button--white mdl-button--grayborder"
              title="{{dictionaryService.dictionary.playlists.search.materialsTitle}}">
        {{dictionaryService.dictionary.playlists.search.materialsLabel}}
        <i class="material-icons small">content_copy</i>
      </button>
      <button class="mdl-button mdl-button--white mdl-button--grayborder" (click)="openChangeMaterial()"
              *ngIf="user && user.role && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
        {{dictionaryService.dictionary.playlists.search.changeButton}}
      </button>
      <button class="mdl-button mdl-button--white mdl-button--grayborder" (click)="openImportPopup()"
              *ngIf="user && user.role && (user.role == 'MANAGER' || user.role == 'USER' || user.role == 'ADMIN' || user.role == 'OPERATOR')">
        {{dictionaryService.dictionary.playlists.search.importButton}}
        <i class="material-icons small">file_upload</i>
      </button>
    </div>
  </div>
  <div class="custom-chips">

    <ng-container *ngFor="let group of selectedGroups">
      <span class="mdl-chip mdl-chip--deletable">
        <span class="mdl-chip__text">{{group.name}}<span
          class="fw-300">({{dictionaryService.dictionary.playlists.search.group}})</span></span>
        <button (click)="deleteGroup(group)" type="button" class="mdl-chip__action"><i class="material-icons">close</i></button>
      </span>
    </ng-container>

    <ng-container *ngFor="let userChannel of selectedUsersChannels">
      <span class="mdl-chip mdl-chip--deletable">
        <span class="mdl-chip__text">{{userChannel.name}}</span>
        <button (click)="deleteChannel(userChannel)" type="button" class="mdl-chip__action"><i class="material-icons">close</i></button>
      </span>
    </ng-container>

  </div>
</div>
