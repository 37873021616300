export interface LazyLoadEvent {
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  multiSortMeta?: SortMeta[];
  filters?: {
    [s: string]: FilterMetadata;
  };
}

export interface SortMeta {
  field: string;
  order: number;
}

export interface FilterMetadata {
  value?: any;
  matchMode?: string;
}

export class PageResponse<E> {
  constructor(public totalPages: number,
              public totalElements: number,
              public content: E[]) {
  }

  // remove the passed element from the content array.
  remove(element: E) {
    let indexToRemove: number = this.content.indexOf(element);
    this.content.splice(indexToRemove, 1);
    this.totalElements = this.totalElements - 1;
  }
}


export class SliceResponse<E> {
  constructor(public number: number,
              public size: number,
              public numberOfElements: number,
              public hasContent: boolean,
              public hasNext: boolean,
              public hasPrevious: boolean,
              public isFirst: boolean,
              public isLast: boolean,
              public content: E[]) {
  }
}

export class PageRequestByExample<E> {
  constructor(public example: E,
              public lazyLoadEvent: LazyLoadEvent) {
  }
}

export class SliceRequestByExample<E> {
  constructor(public example: E,
              public number: number,
              public size: number,
              public sortField?: string,
              public sortOrder?: number) {
  }
}
