/**
 * Created by sebastian on 11.04.17.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CommonService} from "../../common/common-service";
import {GlobalUtilService} from "../../common/global-util.service";
import {environment} from "../../../environments/environment";
import {PlaylistBean} from "../../model/generated/dtos";
import {PlaylistCompareBean} from "../../model/generated/dtos";

@Injectable({
  providedIn: 'root',
})
export class PlaylistCompareService extends CommonService {

  constructor(private http: HttpClient, private globalUtilService: GlobalUtilService) {
    super();
  }

  private getCompare = environment.BASE_API_URL + 'playlists/getPlaylistCompare';
  private getVersions = environment.BASE_API_URL + 'playlists/getPlaylistVersions';

  getComparedPlaylist(
    date: string, v1: string, v2: string, channelId: string
  ): Observable<PlaylistCompareBean[]> {
    let params = new HttpParams()
      .set('channelId', channelId)
      .set('version1', v1)
      .set('version2', v2)
      .set('from', date);
    return this.http.get(this.getCompare, {params: params})
      .pipe(map(this.extractData));
  }

  getPlaylistVersions(channelId: string, date: string): Observable<PlaylistBean[]> {
    let params = new HttpParams()
      .set('channelId', channelId)
      .set('from', date)
    return this.http.get(this.getVersions, {
      params: params
    })
      .pipe(map(this.extractData));
  }
}
