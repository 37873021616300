export class FtpConstants {
  public static readonly CHANNEL_FTP = 'CHANNEL_FTP';
  public static readonly PROFILE_FTP = 'PROFILE_FTP';

  public static readonly DB_AND_FTP = 'DB_AND_FTP';
  public static readonly ONLY_DB = 'ONLY_DB';
  public static readonly ONLY_FTP = 'ONLY_FTP';
  public static readonly SIZE_DIFF = 'SIZE_DIFF';
  public static readonly NO_FILE_INFO = 'NO_FILE_INFO';

  public static readonly TECHNICAL_FILE = 'EMISSION_FILE';
  public static readonly MATERIAL_FILE = 'MATERIAL';
  public static readonly PLAYLIST_FILE = 'PLAYLIST';
}
