import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedService {

  constructor(
    private http: HttpClient
  ) {
  }

  public ready(): Observable<void> {
    return this.http.get<void>('/api/unauthorized/ready');
  }
}
