<dialog class="mdl-dialog" id="popup">
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
    <div class="content">
      <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.statusPopup.header}}</h4>
      <div class="status">
        {{statusDetails}}
      </div>
    </div>
    <a (click)="closePopup()" class="popup-close"><span class="material-icons">close</span></a>
  </div>
</dialog>

