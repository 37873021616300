/**
 * Created by sebastian on 09.04.17.
 */
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {CommonService} from "../../common/common-service";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MaterialDetailsBean} from "../material-details-bean";
import {GetPlaylistDetailsByFilterRequestBean, MaterialBean} from "../../model/generated/dtos";
import {PlaylistScheduleDetails} from "../../model/generated/dtos";
import {ChannelBean} from "../../model/generated/dtos";
import {PlaylistDetailsBean} from "../../model/generated/dtos";

@Injectable({
  providedIn: 'root',
})
export class PlaylistDetailsService extends CommonService {

  constructor(private http: HttpClient) {
    super();
  }

  private getPlaylistContentUrl = environment.BASE_API_URL + 'playlists/getPlaylistContent';
  private getPlaylistSchedUrl = environment.BASE_API_URL + 'playlists/getPlaylistDetails';
  private getMaterialDetailsUrl = environment.BASE_API_URL + 'playlists/getMaterialDetails';
  private getChannelByPlaylist = environment.BASE_API_URL + 'playlists/getChannelByPlaylistId';
  private playListElementRefresh = environment.BASE_API_URL + 'playlists/refreshPlayListElement';
  private refreshPlayListTransformationStatusUrl = environment.BASE_API_URL + 'playlists/refreshPlayListTransformationStatus';
  private getPlaylistElementVantageStatusUrl = environment.BASE_API_URL + 'playlists/getPlaylistElementVantageStatus';
  private getPlayListTransformationStatusUrl = environment.BASE_API_URL + 'playlists/getPlayListTransformationStatus';


  public isMissingMaterialsLink: boolean;


  getDetailsByFilters(id: number, status: string, startTime: string, endTime: string, blockType: string, partnerId: string, sortBy: string, sortDirection: string): Observable<PlaylistDetailsBean[]> {
    let filter = {
      id: id,
      partnerId: partnerId,
      blockType: blockType,
      status: status
    } as GetPlaylistDetailsByFilterRequestBean;
    if (!startTime || startTime === '') {
      filter.blockStart = '00:00:00';
    } else {
      filter.blockStart = startTime;
    }

    if (!endTime || endTime === '') {
      filter.blockEnd = '99:99:99';
    } else {
      filter.blockEnd = endTime;
    }

    let params = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortDirection', sortDirection);

    return this.http.post(this.getPlaylistContentUrl, filter, {
      params: params
    }).pipe(map(this.extractData));
  }


  getPlaylistSchedDetail(playListDate: string, channelId: string): Observable<PlaylistScheduleDetails> {
    let params = new HttpParams()
      .set('channelId', channelId)
      .set('playlistDate', playListDate);
    return this.http.get(this.getPlaylistSchedUrl, {
      params: params
    }).pipe(map(this.extractData));
  }


  getMaterialDetails(id: number): Observable<MaterialBean> {
    let params = new HttpParams()
      .set('elementId', id.toString());
    return this.http.get(this.getMaterialDetailsUrl, {
      params: params
    }).pipe(map(this.extractData));
  }


  getMaterialFullDetails(id: number, spotLength: string, spotType: string): Observable<MaterialDetailsBean> {
    return this.getMaterialDetails(id).pipe(map(materialBean => {
      return this.getMaterialDetailsBean(materialBean, spotLength, spotType);
    }));
  }


  getMaterialDetailsBean(details: MaterialBean, spotLength: string, spotType: string): MaterialDetailsBean {
    let fullDetails: MaterialDetailsBean = new MaterialDetailsBean;
    fullDetails.materialBean = details;
    fullDetails.spotLength = spotLength;
    fullDetails.spotType = spotType;
    return fullDetails;
  }

  getChannelByPlaylistId(id: number): Observable<ChannelBean> {
    let params = new HttpParams()
      .set('playlistId', id.toString());
    return this.http.get(this.getChannelByPlaylist, {
      params: params
    }).pipe(map(this.extractData));
  }

  refreshElement(playListElementId: number, playListId: number, status: string, retryCopying: boolean) {
    const request = {
      id: playListElementId,
      playlistId: playListId,
      status: status
    } as PlaylistDetailsBean
    return this.http.post(this.playListElementRefresh + '/' + retryCopying, request);
  }

  refreshTransformationStatus(playListId: number) {
    return this.http.post(this.refreshPlayListTransformationStatusUrl, playListId);
  }

  getPlaylistElementVantageStatus(playListElementId: number) {
    const params = new HttpParams()
      .set('elementId', playListElementId.toString());
    return this.http.get(this.getPlaylistElementVantageStatusUrl, {
      params: params,
      responseType: 'text'
    });
  }

  getPlaylistStatus(playListId: number): Observable<any> {
    return this.http.get(this.getPlayListTransformationStatusUrl + "/" + playListId)
      .pipe(map(this.extractData));
  }

}
