/**
 * Created by sebastian on 29.03.17.
 */
import {Component, Input} from "@angular/core";
import {environment} from "../../../environments/environment";
import {PlaylistBean} from "../../model/generated/dtos";

@Component({
  selector: 'playlist-row',
  templateUrl: './playlist-row.component.html'
})
export class PlaylistRow {
  title = 'PlaylistRow';
  @Input() rowData: PlaylistBean;
  baseApiUrl: string = environment.BASE_API_URL;


}
