<div class="schedule-item item-main flexbox">
 <!-- <div class="channel-logo-box channel-logo logo-{{rowData.logo}} contain" [attr.title]="rowData.channel">
  </div>-->
  <div class="channel-logo-box channel-logo" [attr.title]="rowData.channelName">
    <img *ngIf="rowData.logo" style="width: 100%" [src]="baseApiUrl+'channels/logo/' + rowData.channelId"/>
    <div *ngIf="!rowData.logo" class="empty-logo empty-logo-size"><span>{{rowData.channelName}}</span></div>
  </div>
</div>
<ng-container *ngFor="let detail of rowData.details">
  <playlist-cell class="schedule-item-container" [details]="detail"></playlist-cell>
</ng-container>
