<div class="pre-teble-header flexbox flex-center" style="margin-bottom: 0">
  <button (click)="onExpandAllChange(false)" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border hide-all-trigger mb-5" data-for=".table-row-toggleable-body toggle-row-body">
    {{dictionaryService.dictionary.playlistDetails.table.retractAllLabel}}
  </button>
  <button (click)="onExpandAllChange(true)" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border ml-5 mb-5 show-all-trigger" data-for=".table-row-toggleable-body toggle-row-body">
    {{dictionaryService.dictionary.playlistDetails.table.expandAllLabel}}
  </button>
  <div *ngIf="playlistBean" class="table-desc mb-5">
    <button (click)="comparePlaylists()"
            class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            data-for=".table-row-toggleable-body toggle-row-body">
      {{dictionaryService.dictionary.playlistDetails.table.compareVersionsLabel}}
    </button>
    <span class="va-m">{{dictionaryService.dictionary.playlistDetails.table.versionType[playlistBean.status]}}: <span
      class="fw-700">V{{playlistBean.version}}</span></span>
    <span class="va-m ml-10">{{dictionaryService.dictionary.playlistDetails.table.generatedDateLabel}}: <span
      class="fw-700">{{playlistBean.actualization}}</span></span>
  </div>
  <button (click)="downloadPlaylist()" *ngIf="playlistBean && playlistBean.hasAnyPlaylistFiles" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" >
    <span><a title="Pobierz playlisty">{{dictionaryService.dictionary.playlistDetails.table.downloadPlaylistButton}}</a></span>
  </button>
</div>
<div *ngIf="playlistBean && user && (user.role == 'ADMIN' || user.role == 'OPERATOR')">
  <span class="va-m mr-10" style="float: right; margin-bottom: 15px"> {{dictionaryService.dictionary.playlistDetails.transformationStatusLabel}}:
    <span *ngIf="playlistBean && playlistBean.hasAnyPlaylistFiles" class="fw-700 ml-10 mr-10" [ngClass]="{
      'warning-msg': [MaterialStatusConstants.NEW, MaterialStatusConstants.UPDATE,
      MaterialStatusConstants.TECHNICAL_ERROR, MaterialStatusConstants.COPYING_ERROR, MaterialStatusConstants.LOCK].indexOf(playlistBean.transformationStatus)> -1,
      'success-msg': MaterialStatusConstants.DONE == playlistBean.transformationStatus }">
      {{dictionaryService.dictionary.playlistDetails.transformationStatus[playlistBean.transformationStatus]}}
    </span>
    <span *ngIf="playlistBean && !playlistBean.hasAnyPlaylistFiles" class="fw-700 ml-10 mr-10 warning-msg">
      {{dictionaryService.dictionary.playlistDetails.transformationStatus['no_file']}}
    </span>
    <button (click)="refreshTransformationStatus() " [disabled]="refreshCounter!=0"
            class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="buttton">
      {{dictionaryService.dictionary.playlistDetails.search.resetTransformationButtonLabel}}
      <i class="material-icons">refresh</i>
    </button>
    </span>
</div>
<div class="table-box table-fullfilled">
  <div class="table-row table-header flexbox">
    <div class="table-item item-main item-pozycja">
      {{dictionaryService.dictionary.playlistDetails.table.positionHeader}}
    </div>
    <div class="table-item item-poczatek sort-item" title="Sortuj po początku bloku"
         (click)="sortDetails('blockStartTimecode')">
      {{dictionaryService.dictionary.playlistDetails.table.blockBeginningHeader}}
      <button *ngIf="sortBy == 'blockStartTimecode'"
              class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect" type="button">
        <i *ngIf="sortAscending" class="material-icons" style="color: white;">keyboard_arrow_up</i>
        <i *ngIf="!sortAscending" class="material-icons" style="color: white;">keyboard_arrow_down</i>
      </button>
    </div>
    <div class="table-item item-nazwa sort-item" title="Sortuj po nazwie bloku" (click)="sortDetails('blockName')">
      {{dictionaryService.dictionary.playlistDetails.table.blockSpotNameHeader}}
      <button *ngIf="sortBy == 'blockName'" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
              type="button">
        <i *ngIf="sortAscending" class="material-icons" style="color: white;">keyboard_arrow_up</i>
        <i *ngIf="!sortAscending" class="material-icons" style="color: white;">keyboard_arrow_down</i>
      </button>
    </div>
    <div class="table-item item-idtvn">
      {{dictionaryService.dictionary.playlistDetails.table.partnerIdHeader}}
    </div>

    <div class="table-item item-dl">
      {{dictionaryService.dictionary.playlistDetails.table.dlHeader}}
    </div>
    <div class="table-item item-format">
      {{dictionaryService.dictionary.playlistDetails.table.fileTypeHeader}}
    </div>
    <div class="table-item item-typ">
      {{dictionaryService.dictionary.playlistDetails.table.blockOrderTypeHeader}}
    </div>

    <div class="table-item item-piwo">
      {{dictionaryService.dictionary.playlistDetails.table.beerHeader}}
    </div>
    <div class="table-item item-hfss">
      HFSS
    </div>
    <div class="table-item item-statusmat">
      {{dictionaryService.dictionary.playlistDetails.table.materialStatusHeader}}
    </div>
    <div class="table-item item-akcje">
      {{dictionaryService.dictionary.playlistDetails.table.actionsHeader}}
    </div>
  </div>
  <ng-container *ngFor="let details of playlistDetails">
    <playlist-details-row [rowDetails]="details"
                          [expandAll]="expandAll"
                          (materialDetails)="onMaterialDetEmit($event)"
                          (statusDetails)="onStatusDetEmit($event)"
                          (refreshList)="onRefreshEmit($event)"
                          [statusTranslation]="statusTranslation"
                          [user]="user"
    ></playlist-details-row>
  </ng-container>
  <ng-container *ngIf="playlistDetails == null || playlistDetails.length <= 0">
    <div class="table-row">
      <div class="no-results-found" style="padding-top: 25px; padding-bottom: 25px; background-color: white;">{{dictionaryService.dictionary.playlistDetails.table.noResults}} <i class="material-icons">do_not_disturb</i></div>
    </div>
  </ng-container>
</div>
