import {Component, OnInit} from '@angular/core';
import {PlaylistGridService} from "./schedule/playlist-grid/playlist-grid.service";
import {AppContext} from "./model/app-context";
import {GlobalUtilService} from "./common/global-util.service";
import {Observable} from "rxjs";
import {LoaderService} from "./common/loader.service";
import {UserListService} from "./user-list/user-list.service";
import {UserBean} from "./model/generated/dtos";
import {DictionaryService} from "./common/dictionary-service";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {UnauthorizedService} from "./service/api/unauthorized.service";
import {NavigationService} from "./service/navigation/navigation.service";

@Component({
  selector: 'app-root',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  appContext: AppContext;

  title = 'Playlister';
  public loaderPipe: Observable<boolean>;
  public user: UserBean;
  currentLocation: Location;

  constructor(private playlistService: PlaylistGridService,
              private globalUtilService: GlobalUtilService,
              private loaderService: LoaderService,
              private userService: UserListService,
              private location: Location,
              public dictionaryService: DictionaryService,
              private unauthorizedService: UnauthorizedService,
              private navigationService: NavigationService,
  ) {
    this.unauthorizedService.ready().subscribe({
      error: () => {
        // works as expected on kubernetes
        this.navigationService.toCasLoginPage();
      },
    });
    userService.userDataChange$.subscribe(_ => {
      this.loadUser();
    });
    this.loaderPipe = loaderService.loaderCounterSource$;
    this.currentLocation = location;
  };

  ngOnInit(): void {
    this.appContext = this.globalUtilService.getAppContext();
    this.loadUser();
  }

  private loadUser() {
    this.userService.getCurrentUser().subscribe(res => {
      this.user = res;
    });
  }

  changeLang(isPolish: boolean) {
    this.dictionaryService.isPolish = isPolish;
    sessionStorage.setItem('isPolish', String(this.dictionaryService.isPolish));
    window.location.reload();
  }
}
