/**
 * Created by sebastian on 09.04.17.
 */

import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from "rxjs";
import {PlaylistDetailsService} from "../playlist-details-table/playlist-details-table.service";
import {LoaderService} from "../../common/loader.service";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistDetailsBean, UserBean} from "../../model/generated/dtos";
import {MaterialDetailsBean} from "../material-details-bean";
import {CommonMessageService} from "../../common/common-message-service";
import {MaterialStatusConstants} from '../../common/constant/material-status-constants';

@Component({
  selector: 'playlist-details-row',
  templateUrl: './playlist-details-row.component.html',
  styleUrls: ['./playlist-details-row.component.css']
})
export class PlaylistDetailsRow implements OnInit {

  constructor(private playlistDetailsService: PlaylistDetailsService,
              private messageService: CommonMessageService,
              private loaderService: LoaderService,
              protected dictionaryService: DictionaryService) {
  };

  @Input() rowDetails: PlaylistDetailsBean;
  @Input('expandAll') expandAll: boolean;
  @Output() materialDetails: EventEmitter<Observable<MaterialDetailsBean>> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  @Output() statusDetails: EventEmitter<String> = new EventEmitter();
  @Input() statusTranslation: any;
  @Input() user: UserBean;

  dialog: HTMLElement = null;
  MaterialStatusConstants = MaterialStatusConstants;
  processingStatusList = MaterialStatusConstants.ALL_STATUSES;
  isExpanded: boolean = false;

  ngOnInit(): void {
  }

  openPopup(id: number): void {
    let blockChildren = this.rowDetails.childList.filter(el => el.id != null && el.id === id);
    let spotLength: string = '';

    if (blockChildren != null && blockChildren[0] != null) {
      spotLength = (blockChildren[0].blockLength != null ? blockChildren[0].blockLength : '');
    }

    this.materialDetails.emit(
      this.playlistDetailsService.getMaterialFullDetails(id, spotLength, this.rowDetails.blockType)
    );
    //document.getElementById('dialog-1').showModal();
    let dialogHtmlElement = document.getElementById('dialog-1');
    this.fixedOpenPopup(dialogHtmlElement);

  }

  refreshPlayListElement(detailBean: PlaylistDetailsBean, retryCopying: boolean): void {
    this.loaderService.showLoader();
    this.playlistDetailsService.refreshElement(detailBean.id, detailBean.playlistId, detailBean.status, retryCopying).subscribe(details => {
      if (retryCopying) {
        this.messageService.success(this.dictionaryService.dictionary.playlistDetails.messages.retryCopyingData);
      } else {
        this.messageService.success(this.dictionaryService.dictionary.playlistDetails.messages.refreshData);
      }
      this.refreshList.emit('refresh');
      this.loaderService.hideLoader();
    }, error => {
      this.loaderService.hideLoader();
    });
  }

  getPlaylistElementVantageStatus(detailBean: PlaylistDetailsBean): void {
    this.loaderService.showLoader();
    this.playlistDetailsService.getPlaylistElementVantageStatus(detailBean.id).subscribe({
      next: details => {
      if (details == null || details.trim().length == 0)
        this.showStatusDetailsPopup(this.dictionaryService.dictionary.playlistDetails.errorDetailsNotAvailable);
      else
        this.showStatusDetailsPopup(details);
    },
    error: error => {}}).add(() => {
      this.loaderService.hideLoader();
    })
  }

  showStatusDetailsPopup(statusDetails: string): void {
    this.statusDetails.emit(statusDetails)
    let dialogHtmlElement = document.getElementById('popup');
    this.fixedOpenPopup(dialogHtmlElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expandAll']) {
      this.isExpanded = this.expandAll;
    }
  }

  fixedOpenPopup(dialogHtmlElement: any) {
    let topOffset = window.pageYOffset + 100 + 'px';
    dialogHtmlElement.style.top = topOffset;
    dialogHtmlElement.setAttribute("open", "open");
  }

  isProcessingStatus(status: string) {
    return this.processingStatusList.indexOf(status) > -1;
  }

}

