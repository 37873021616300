import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PlaylistCompareService} from "./playlist-compare-search.service";
import {Observable} from "rxjs";
import {LoaderService} from "../../common/loader.service";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistBean} from "../../model/generated/dtos";
import {ChannelBean} from "../../model/generated/dtos";
import {PlaylistCompareBean} from "../../model/generated/dtos";
import {environment} from "../../../environments/environment";

/**
 * Created by sebastian on 08.05.17.
 */
@Component({
  selector: 'playlist-compare-search',
  templateUrl: './playlist-compare-search.component.html'
})
export class PlaylistCompareSearch implements OnInit {


  @Input() channelId;
  @Input() emissionDate;
  @Input() version1;
  @Input() selectedChannel: ChannelBean;

  date: string;
  channels: ChannelBean[];
  selVersion1: PlaylistBean;
  selVersion2: PlaylistBean;
  playlistsVersion: PlaylistBean[] = [];
  @Output() rowsData: EventEmitter<Observable<PlaylistCompareBean[]>> = new EventEmitter();
  @Output() emitSelV1: EventEmitter<PlaylistBean> = new EventEmitter();
  @Output() emitSelV2: EventEmitter<PlaylistBean> = new EventEmitter();
  version2Open: boolean = false;
  version1Open: boolean = false;

  baseApiUrl: string = environment.BASE_API_URL;

  constructor(private compareService: PlaylistCompareService,
              private loaderService: LoaderService,
              protected dictionaryService: DictionaryService) {
  };

  ngOnInit(): void {
    if (this.emissionDate)
      this.date = this.emissionDate[4] + this.emissionDate[5] + this.emissionDate[6] + this.emissionDate[7] + "/" + this.emissionDate[0] + this.emissionDate[1] + "/" + this.emissionDate[2] + this.emissionDate[3]; //yyyy/MM/dd

    this.compareService.getPlaylistVersions(this.channelId, this.emissionDate).subscribe(versions => {
      this.playlistsVersion = versions;
    });
  }

  version1Select(selected: PlaylistBean): void {
    this.selVersion1 = selected;
  }

  version2Select(selected: PlaylistBean): void {
    this.selVersion2 = selected;
  }

  compare(): void {
    this.loaderService.showLoader();
    this.rowsData.emit(
      this.compareService.getComparedPlaylist(
        this.emissionDate,
        this.selVersion1.version,
        this.selVersion2.version,
        this.channelId)
    );
    this.emitSelV1.emit(this.selVersion1);
    this.emitSelV2.emit(this.selVersion2);
  }

  downloadComparedPlaylists() {
    let browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf('firefox') > -1) {
      window.open(
        this.baseApiUrl + 'playlists/downloadComparedPlaylist/' + this.channelId + '/' + this.emissionDate + '/' + this.selVersion1.version + '/' + this.selVersion2.version,
        '_blank'
      );
      window.close();
      return false;
    } else {
      window.location.href = this.baseApiUrl + 'playlists/downloadComparedPlaylist/' + this.channelId + '/' + this.emissionDate + '/' + this.selVersion1.version + '/' + this.selVersion2.version;
    }
  }

}
