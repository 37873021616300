<div class="table-box">
  <div class="table-row table-header flexbox" style="display: flex; flex-direction: column;">
    <div style="display: flex; flex-direction: row">
      <div style="display: flex; flex-direction: column; border-right: 2px solid #bcbcbc; width: 50%">
        <div class=" table-comparing-header flexbox" style="padding-bottom:  15px;">
          <div class="table-item item-header-wersja">
            <span class="table-item-header-main">{{dictionaryService.dictionary.playlistComparison.table.version}} {{selectedV1.version}}</span>
          </div>
          <div class="table-item item-header-liczba">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.blockCount}}:</span>
            <span class="table-item-header-value">{{selectedV1.blockNumber}}</span>
          </div>
          <div class="table-item item-header-liczba">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.spotCount}}:</span>
            <span class="table-item-header-value">{{selectedV1.spotNumber}}</span>
          </div>
          <div class="table-item item-header-datagen">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.generated}}:</span>
            <span class="table-item-header-value">{{selectedV1.actualization}}</span>
          </div>
        </div>

        <div class="table-row table-header flexbox" style="padding-bottom: 15px;">
          <!--<div class="table-comparing-row flexbox">-->
            <div class="table-item item-pozycja-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.position}}
            </div>
            <div class="table-item item-poczatek-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.blockBeginning}}
            </div>
            <div class="table-item item-nazwa-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.blockSpotName}}
            </div>
            <div class="table-item item-idtvn-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.partnerIdHeader}}
            </div>
            <div class="table-item item-dl-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.dl}}
            </div>
            <div class="table-item item-format-compare text-center">
              Format
            </div>
            <div class="table-item item-typ-compare playlist-compare-block-type-table-header text-center">
              {{dictionaryService.dictionary.playlistComparison.table.blockOrderType}}
            </div>
            <div class="table-item item-piwo-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.beer}}
            </div>
            <div class="table-item item-hfss-compare text-center">
              HFSS
            </div>
          <!--</div>-->
        </div>
      </div>

      <!--<div class="version-spacer"></div>-->

      <div style="display: flex; flex-direction: column; border-left: 2px solid #bcbcbc; width: 50%">
        <div class="table-row table-header flexbox" style="padding-bottom: 15px;">
          <div class="table-item item-header-wersja">
            <span class="table-item-header-main">{{dictionaryService.dictionary.playlistComparison.table.version}} {{selectedV2.version}}</span>
          </div>
          <div class="table-item item-header-liczba">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.blockCount}}:</span>
            <span class="table-item-header-value">{{selectedV2.blockNumber}}</span>
          </div>
          <div class="table-item item-header-liczba">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.spotCount}}:</span>
            <span class="table-item-header-value">{{selectedV2.spotNumber}}</span>
          </div>
          <div class="table-item item-header-datagen">
            <span class="table-item-header-label">{{dictionaryService.dictionary.playlistComparison.table.generated}}:</span>
            <span class="table-item-header-value">{{selectedV2.actualization}}</span>
          </div>
        </div>

        <div class="table-row table-header flexbox"  style="padding-bottom: 15px;">
          <!--<div class="table-comparing-row flexbox">-->
            <div class="table-item item-pozycja-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.position}}
            </div>
            <div class="table-item item-poczatek-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.blockBeginning}}
            </div>
            <div class="table-item item-nazwa-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.blockSpotName}}
            </div>
            <div class="table-item item-idtvn-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.partnerIdHeader}}
            </div>
            <div class="table-item item-dl-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.dl}}
            </div>
            <div class="table-item item-format-compare text-center">
              Format
            </div>
            <div class="table-item item-typ-compare playlist-compare-block-type-table-header text-center" >
              {{dictionaryService.dictionary.playlistComparison.table.blockOrderType}}
            </div>
            <div class="table-item item-piwo-compare text-center">
              {{dictionaryService.dictionary.playlistComparison.table.beer}}
            </div>
            <div class="table-item item-hfss-compare text-center">
              HFSS
            </div>

          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let compareRow of comparedPlaylists">
    <playlist-compare-row [rowCompare]="compareRow"></playlist-compare-row>
  </ng-container>
</div>
