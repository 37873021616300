<p-toast position="top-right"></p-toast>
<div class="center-box">
  <div class="flexbox justify-end">
    <div class="menu" *ngIf = "user && user.active && user.agreementSigned">
      <ul class="list flexbox justify-end" style="margin-top: 10px;">
        <li>
          <user-panel [user] ="user"></user-panel>
        </li>
        <li>
          <lang-panel></lang-panel>
        </li>
      </ul>
    </div>
  </div>
  <div class="topbar flexbox">
    <h1 class="logo-box">
      <a class="logo" href="#/home">
        <span class="logo-label">Playlister</span>
      </a>
    </h1>
    <div class="menu" *ngIf = "user && user.active && user.agreementSigned" >
      <ul class="list flexbox" style="margin-top: 30px;">
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('home')!=-1}" href="#/home">{{dictionaryService.dictionary.app.playlistsLink}}</a>
        </li>
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('user')!=-1}" *ngIf = "user && (user.role == 'ADMIN' || user.role == 'MANAGER')" href="#/user">{{dictionaryService.dictionary.app.usersLink}}</a>
        </li>
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('profiles')!=-1}" *ngIf = "user && user.role == 'ADMIN'" href="#/profiles">{{dictionaryService.dictionary.app.profilesLink}}</a>
        </li>
        <li>
          <a  [ngClass]="{'active':currentLocation.path(true).indexOf('channelGroups')!=-1}" *ngIf = "user && user.role == 'ADMIN'" href="#/channelGroups">{{dictionaryService.dictionary.app.channelsGroupsLink}}</a>
        </li>
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('channels')!=-1}" *ngIf = "user && user.role == 'ADMIN'" href="#/channels">{{dictionaryService.dictionary.app.channelsLink}}</a>
        </li>
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('transferStatistics')!=-1}" href="#/transferStatistics">{{dictionaryService.dictionary.app.statisticsLink}}</a>
        </li>
        <li>
        <a [ngClass]="{'active':currentLocation.path(true).indexOf('transferControlPanel')!=-1}" *ngIf = "user && (user.role == 'ADMIN' || user.role == 'OPERATOR')" href="#/transferControlPanel">{{dictionaryService.dictionary.app.controlPanelLink}}</a>
        </li>
        <li>
          <a [ngClass]="{'active':currentLocation.path(true).indexOf('notificationsConfiguration')!=-1}" href="#/notificationsConfiguration">{{dictionaryService.dictionary.app.notificationsLink}}</a>
        </li>
      </ul>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<footer></footer>
<p-blockUI *ngIf="(loaderPipe | async)" [blocked]="true" ></p-blockUI>
<p-progressSpinner  *ngIf="(loaderPipe | async)" class="loader-spinner"></p-progressSpinner>


