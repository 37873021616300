export class UniqueIdComponent {
  public _id: any;

  constructor() {
    this.makeNewId();
  }

  protected makeNewId(): void {
    this._id = (new Date().getTime());
  }
}
