/**
 * Created by sebastian on 09.04.17.
 */
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DictionaryService} from "../common/dictionary-service";
import {MaterialDetailsBean} from "../playlist-details/material-details-bean";
import {environment} from "../../environments/environment";

@Component({
  selector: 'details-popup',
  templateUrl: './details-popup.component.html'
})
export class PopupDetails implements OnInit, OnChanges {

  @Input() materialDetails: MaterialDetailsBean;
  date: Date = new Date;
  activeTab: number = 1;
  baseApiUrl = environment.BASE_API_URL;

  constructor(public dictionaryService: DictionaryService) {}

  ngOnInit(): void {
  }

  closePopup(): void {
    //document.getElementById('dialog-1').close();
    var dialogHtmlElement = document.getElementById('dialog-1');
    this.fixedClosePopup(dialogHtmlElement);
    this.materialDetails = null;
  }

  getData(): void {
  }

  tabChange(tabNr: number): void {
    this.activeTab = tabNr;
  }


  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.activeTab = 1;
  }
}
