<div>
  <h2>{{dictionaryService.dictionary.channelGroupList.header}}</h2>
</div>
<dialog class="mdl-dialog" id="channel-group-form-dialog" style="width: 512px;">
  <div class="popup-background-blocker"></div>
  <div class="mdl-dialog__content">
    <div class="channel_group-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text" *ngIf="!currentChannelGroupName">{{dictionaryService.dictionary.channelGroupList.dialog.header}}</h2>
        <h2 class="mdl-card__title-text" *ngIf="currentChannelGroupName">{{currentChannelGroupName}}</h2>
      </div>
      <form #channelGroupForm="ngForm" action="" method="post" class="filters" (ngSubmit)="saveChannelGroup(channelGroupForm)" novalidate>
        <div class="mdl-card__supporting-text "
             style="    margin-left: auto;    margin-right: auto;    margin-top: 20px;">
          <div class=" flex-end mb-20">
            <div class="field-box" style="margin-bottom: 12px;">
              <div><label class="field-label">{{dictionaryService.dictionary.channelGroupList.dialog.nameInputLabel}}</label></div>
              <div><input class="field " type="text" name="name" style="  width: 100%;" [(ngModel)]="formBean.name"
                          required></div>
            </div>
        <div class="field-box" style="margin-bottom: 12px;">
              <p-accordion>
                <p-accordionTab [selected]="true">
                  <p-header>
                    {{dictionaryService.dictionary.channelGroupList.dialog.sufixListLabel}}
                  </p-header>
                    <div class="mb-12 field&#45;&#45;info">
                     <span>
                       <span>&#10067;</span>
                       {{dictionaryService.dictionary.channelGroupList.dialog.sufixListDesc}}
                       <br/>
                       {{dictionaryService.dictionary.channelGroupList.dialog.sufixListExample}}
                     </span>
                    </div>
                  <div class="mb-12">
                   <input class="field " type="text" name="suffix" style="  width: 94%;" [(ngModel)]="newSuffix">
                      <i class="material-icons add-extension" (click)="addSuffix(newSuffix)">add_circle</i>
                  </div>
                  <div class="custom-chips" style="margin-bottom: 12px; display: flex; flex-wrap: wrap">
                    <ng-container *ngFor="let sufx of suffixes">
                  <span class="mdl-chip mdl-chip&#45;&#45;deletable">
                    <span class="mdl-chip__text">{{sufx}}</span>
                    <button (click)="deleteSuffix(sufx)" type="button" class="mdl-chip__action"><i
                      class="material-icons">close</i></button>
                  </span>
                    </ng-container>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>


            <div class="field-box" style="margin-bottom: 12px;">
              <p-accordion>
                <p-accordionTab [selected]="false">
                  <p-header>
                    {{dictionaryService.dictionary.channelGroupList.dialog.channelsHeader}}
                  </p-header>
                  <div class="search-groups--autocomplete disable-all">
                    <p-autoComplete name="channel" (onSelect)="getSelectedChannel($event)" [size]="35"
                                    [placeholder]="dictionaryService.dictionary.channelGroupList.dialog.channelInputPlaceholder"
                                    [(ngModel)]="currentChannel"
                                    [suggestions]="foundChannels"
                                    (completeMethod)="searchChannels($event)"
                                    appendTo="body"
                                    field="name">
                      <ng-template let-currentChannel pTemplate="item">
                        <span>{{currentChannel.name}}</span>
                      </ng-template>
                    </p-autoComplete>
                  </div>

                  <div class="custom-chips" style="margin-bottom: 12px; display: flex; flex-wrap: wrap">
                    <ng-container *ngFor="let channel of formBean.channels">
                  <span class="mdl-chip mdl-chip--deletable">
                    <span class="mdl-chip__text">{{channel.name}}</span>
                    <button (click)="deleteInput(channel)" type="button" class="mdl-chip__action"><i
                      class="material-icons">close</i></button>
                  </span>
                    </ng-container>
                  </div>
                </p-accordionTab>
              </p-accordion>

            </div>
            <div class="field-box">
              <p-accordion>
                <p-accordionTab [selected]="true">
                  <p-header>
                    {{dictionaryService.dictionary.channelGroupList.dialog.vantageHeader}}
                  </p-header>
                  <div class="field-box">
                    <div><label class="field-label">Workflow</label></div>
                    <div><input class="field" type="text" name="host" style="  width: 100%;"
                                [(ngModel)]="formBean.workflow" required></div>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </div>
        <div class="mdl-card__actions mdl-card--border channel-group-popup-footer-height" >
          <div class="channel-group-popup-footer-warning">
          </div>
          <div class="channel-group-popup-footer-button-container">
            <button (click)="isFormSaved=true"
                    class="channel-group-popup-footer-button mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                    type="submit">
              {{dictionaryService.dictionary.channelGroupList.dialog.save}}
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
        <div class="mdl-card__menu">
          <button (click)="closePopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                  type="button">
            <i class="material-icons" style="color: white;">close</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</dialog>


<dialog class="mdl-dialog" style="max-width: 500px;"  id="delete-channel-group-dialog" >
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
    <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.channelGroupList.confirmDialog.header}}</h4>
    <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
      <p>
        {{dictionaryService.dictionary.channelGroupList.confirmDialog.question}} {{deleteChannelGroupBean.name}}?
      </p>
    </div>
    <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button" (click)="closeConfirmPopup('delete-channel-group-dialog')">
        {{dictionaryService.dictionary.channelGroupList.confirmDialog.cancelButton}}
      </button>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" type="submit" (click)="deleteChannelGroup()" >
        {{dictionaryService.dictionary.channelGroupList.confirmDialog.confirmButton}}
      </button>
    </div>
  </div>
</dialog>
<form action="" method="post" class="filters" (ngSubmit)="searchChannelGroups()">
  <div class="custom-panel flex-end mb-20">
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.channelGroupList.form.nameInputLabel}}:</label></div>
      <div class="search-groups--autocomplete disable-all filter-channel">
        <p-autoComplete name="name" [size]="35"
                        [placeholder]="dictionaryService.dictionary.channelGroupList.form.nameInputPlaceholder"
                        [(ngModel)]="searchCurrentChannelGroup"
                        (ngModelChange)="autocompleteModelChange()"
                        [suggestions]="foundFilterChannelGroups"
                        [disabled]="channelGroupDisabled" field="name"
                        appendTo="body"
                        (completeMethod)="searchFilterChannelGroups($event)">
          <ng-template let-searchCurrentChannelGroup pTemplate="item">
            <span>{{searchCurrentChannelGroup.name}}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.channelGroupList.form.channelInputLabel}}:</label></div>
      <div class="search-groups--autocomplete disable-all filter-channel">
        <p-autoComplete name="channel" [size]="35"
                        [placeholder]="dictionaryService.dictionary.channelGroupList.form.channelInputPlaceholder"
                        [(ngModel)]="searchCurrentChannel"
                        (ngModelChange)="autocompleteModelChange()"
                        [suggestions]="foundFilterChannels"
                        [disabled]="channelDisabled" field="name"
                        appendTo="body"
                        (completeMethod)="searchFilterChannels($event)">
          <ng-template let-searchCurrentChannel pTemplate="item">
            <span>{{searchCurrentChannel.name}}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="submit" >
      {{dictionaryService.dictionary.channelGroupList.form.filterButton}}
      <i class="material-icons">tune</i>
    </button>
    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button" (click)="clearSearchFilter()">
      {{dictionaryService.dictionary.channelGroupList.form.clearFiltersButton}}
    </button>
    <div class="element-to-right-margin">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              type="button" (click)="openPopup()">
        {{dictionaryService.dictionary.channelGroupList.form.newChannelGroupButton}}
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>
</form>

<div>
  <div class="table-responsive-vertical shadow-z-1">
    <table id="table" class="table table-hover table-mc-light-blue">
      <thead>
      <tr >
        <th *ngFor="let header of headers; let i = index" [ngClass]="{'column-header-hover': header.clickable, 'channel-group-action-header':header.propertyName=='actions'}" class="">
          <div (click)="toggleArrow(i, header)" class="column-header-content">
            {{header.name}}
            <sort-arrow [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr  *ngFor="let channelGroup of channelGroupsList">
        <td>{{channelGroup.name}}</td>
        <td>
          {{channelGroup.workflow}}
        </td>
        <td>
          {{channelGroup.fileExtension}}
        </td>
        <td style="text-align: center">
          <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" type="submit" (click)="openPopup(channelGroup)">
            {{dictionaryService.dictionary.channelGroupList.results.editButton}}
            <i class="material-icons">edit</i>
          </button>
          <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" (click)="openConfirmDeletePopup(channelGroup)">
            {{dictionaryService.dictionary.channelGroupList.results.deleteButton}}
            <i class="material-icons">delete</i>
          </button>
        </td>
      </tr>
      <tr *ngIf="channelGroupsList.length==0">
        <td colspan="9" class="no-results-found">{{dictionaryService.dictionary.channelGroupList.results.noResults}} <i class="material-icons">do_not_disturb</i></td>
      </tr>
      </tbody>
    </table>
    <div class="bottom-button-wrapper">
      <button [disabled]="!hasNextPage" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" (click)="nextSlice()" type="button">
        {{dictionaryService.dictionary.channelGroupList.results.showMore}}
      </button>
    </div>
  </div>
</div>
