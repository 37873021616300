/**
 * Created by sebastian on 04.04.17.
 */
import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../common/loader.service";
import {BaseComponent} from "../base.component";
import {DictionaryService} from "../common/dictionary-service";
import {PlaylistBean} from "../model/generated/dtos";
import {SearchPlaylist} from "./search-playlist/search-playlist.component";
import {PlaylistGridComponent} from "./playlist-grid/playlist-grid.component";

@Component({
  selector: 'playlists',
  templateUrl: './playlists.component.html'
})
export class PlaylistsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  playlists: PlaylistBean[] = [];
  date: Date;

  slotsCount: number = 7;
  slotsCountForGrid: number = 7;

  @ViewChild('searchPlaylist') searchPlaylist: SearchPlaylist;
  @ViewChild('timer') timerRef: ElementRef;
  @ViewChild('playlistGrid') playlistGrid: PlaylistGridComponent;
  private initTimerValue: number = 120;
  private timerValue: number = this.initTimerValue;
  public timerDisplay: string = "0:00";
  private timerActive: boolean = true;

  timerStyle = {
    'background-image': 'linear-gradient(91deg, transparent 50%, #ccc 50%), linear-gradient(90deg, #ccc 50%, transparent 50%)'
  };


  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected dictionaryService: DictionaryService,
              protected loaderService: LoaderService) {
    super(router, route, loaderService);
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.calculateSlots();
  }

  ngAfterViewInit() {
    this.timerSecondCount();
  }

  ngOnDestroy() {
    this.timerActive = false; //przerwanie petli liczenia sekund dla timera
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateSlots();
  }

  onDateNotify(date: Date): void {
    this.date = date;
  }

  onNotify(playlists: PlaylistBean[]): void {
    this.playlists = playlists;
    this.slotsCountForGrid = this.slotsCount;
    setTimeout(() => this.playlistGrid.checkBottomBarPosition());
  }

  timerSecondCount() {
    this.animateTimer();
    if (this.timerActive)
      setTimeout(() => {
        if (this.timerValue > 0) {
          this.timerValue--;
        } else {
          this.restartTimer();
          this.forceDataRefresh();
        }
        this.timerDisplay = this.getTimeString(this.timerValue);
        this.timerSecondCount();
      }, 1000);
  }

  getTimeString(seconds: number): string {
    return this.getMinutesString(seconds) + ":" + this.getSecondsString(seconds);
  }

  getMinutesString(seconds: number): string {
    let minutes: number = Math.floor(seconds / 60);
    return (minutes > 9 ? minutes.toString(10) : "0" + minutes.toString(10));
  }

  getSecondsString(seconds: number): string {
    let secondsLeft = seconds % 60;
    return (secondsLeft > 9 ? secondsLeft.toString(10) : "0" + secondsLeft.toString(10));
  }

  restartTimer() {
    this.timerValue = this.initTimerValue;
  }

  forceDataRefresh() {
    this.searchPlaylist.forceRefreshData();
  }


  animateTimer() {
    let i = this.timerValue / this.initTimerValue * 360;
    // i = 360 - i; //animacja timera w przeciwna strone
    if (i <= 180)
      this.timerStyle = {
        'background-image': 'linear-gradient(' + (i + 90) + 'deg, transparent 50%, #ccc 50%), linear-gradient(90deg, #ccc 50%, transparent 50%)'
      };
    else
      this.timerStyle = {
        'background-image': 'linear-gradient(' + (i - 90) + 'deg, transparent 50%, #275eab 50%),linear-gradient(90deg, #ccc 50%, transparent 50%)'
      };
  }

  manualDataRefresh() {
    this.restartTimer();
    this.forceDataRefresh();
  }

  private calculateSlots() {
    this.slotsCount = Math.min(Math.max(Math.trunc((window.innerWidth - 230) / 185), 7), 21);
  }
}
