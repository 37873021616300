/**
 * Created by sebastian on 03.04.17.
 */
import {Component, ViewChild} from '@angular/core';
import {PlaylistDetailsService} from "./playlist-details-table/playlist-details-table.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {LoaderService} from "../common/loader.service";
import {BaseComponent} from "../base.component";
import {PlaylistDetailSearch} from "./playlist-details-search/playlist-details-search.component";
import {DictionaryService} from "../common/dictionary-service";
import {ChannelBean, MaterialBean, PlaylistDetailsBean, PlaylistScheduleDetails} from "../model/generated/dtos";
import {PlaylistStatusPopupComponent} from "./playlist-status-popup/playlist-status-popup.component";
import {MaterialStatusConstants} from '../common/constant/material-status-constants';

@Component({
  selector: 'playlist-details',
  templateUrl: './playlist-details.component.html'
})
export class PlaylistDetails extends BaseComponent {

  @ViewChild('searchBox') searchBox: PlaylistDetailSearch;
  @ViewChild('popup') popup: PlaylistStatusPopupComponent;
  public statusTranslation: any;
  private channelIdParam: string;
  private playlistDateParam: string;

  playlistDetails: PlaylistDetailsBean[];
  playlistScheDet: PlaylistScheduleDetails;
  materialDetails: MaterialBean;
  id: number;
  isMissingMaterials: string;
  initExpandAllList: boolean = false;
  materialStatus: string;
  channel: ChannelBean;
  playlistDate: Date;

  constructor(private playlistDetailsService: PlaylistDetailsService,
              protected loaderService: LoaderService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected dictionaryService: DictionaryService) {
    super(router, route, loaderService);
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      this.channelIdParam = params['channelId'];
      this.playlistDateParam = params['date'];
      this.isMissingMaterials = params['isMissingLink'];
    });

    this.setMissingMaterialsFlag();

    this.initTranslationDictionary();

    this.loaderService.showLoader();

    if (this.playlistDetailsService.isMissingMaterialsLink)
      this.materialStatus = MaterialStatusConstants.ALL_MISSING;


    this.playlistDetailsService.getPlaylistSchedDetail(this.playlistDateParam, this.channelIdParam).subscribe(details => {
      this.playlistScheDet = details;
      this.channel = details.channel;
      this.playlistDate = details.playlistDate;
      this.id = details.id;
      this.playlistDetailsService.getDetailsByFilters(this.id, this.materialStatus, null, null, '%', '%', 'blockStartTimecode', 'asc').subscribe(details => {
        this.playlistDetails = details;
        this.loaderService.hideLoader();
      }, error => {
        this.loaderService.hideLoader();
      });
    });
  }

  private initTranslationDictionary() {
    let statusTranslateDict = this.dictionaryService.dictionary.playlistDetails.search.statuses;
    this.statusTranslation = (this.user && this.user.role && (this.user.role == 'ADMIN' || this.user.role == 'OPERATOR')) ? statusTranslateDict['ADMIN'] : statusTranslateDict['USER'];
  }

  onNotify(playlists: Observable<PlaylistDetailsBean[]>): void {
    playlists.subscribe(playlists => {
      this.playlistDetails = playlists;
      this.loaderService.hideLoader();
    }, error => {
      this.loaderService.hideLoader();
    });
  }

  onMaterialDetailsEmit(material: Observable<MaterialBean>): void {
    material.subscribe(material => {
      this.materialDetails = material
    })
  }

  onStatusDetailsEmit(statusDetails: String): void {
    this.popup.statusDetails = statusDetails;
  }

  onRefreshEmit($event: Event) {
    var that = this;
    this.loaderService.showLoader();
    setTimeout(() => {
      that.searchBox.filter();
    }, 50);
  }

  setMissingMaterialsFlag() {
    if (this.isMissingMaterials === 'true') {
      this.playlistDetailsService.isMissingMaterialsLink = true;
      this.initExpandAllList = true;
    } else {
      this.playlistDetailsService.isMissingMaterialsLink = false;
      this.initExpandAllList = false;
    }
  }
}
