import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from "@angular/core";
import {UniqueIdComponent} from "./unique-id-component";
import {FileItem, FileUploader} from "ng2-file-upload";
import {DictionaryService} from "../../common/dictionary-service";
import {environment} from "../../../environments/environment";
import {CommonMessageService} from "../../common/common-message-service";

@Component({
  selector: 'attach-box',
  templateUrl: 'attach-box.component.html',
})
export class AttachBoxComponent extends UniqueIdComponent implements AfterViewInit, OnDestroy {
  @Input() multiple: boolean = false;
  @Input() title: string;
  @Output() onFileUpload = new EventEmitter<FileList>();
  uploader: FileUploader = new FileUploader({});
  hasBaseDropZoneOver: boolean = false;
  @ViewChild('selectedFile') selectedFile: any;


  constructor(public dictionaryService: DictionaryService,
              private messageService: CommonMessageService) {
    super();
  }

  ngAfterViewInit(): void {
    this.uploader.onCompleteAll = () => {
    };

    this.uploader.onAfterAddingAll = (fileItems: FileList) => {
      this.onFileUpload.emit(fileItems);
    }

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (fileItem.file.size > environment.UPLOAD_MAX_FILE_SIZE) {
        this.messageService.error(this.dictionaryService.dictionary.errors.uploadedFileTooLargeError);
        this.uploader.removeFromQueue(fileItem);
      }
    }
  }

  ngOnDestroy(): void {
    this.uploader.destroy();
  }

  clearFiles() {
    //usun obrazki upuszczone wczesniej
    this.uploader.clearQueue();
    this.clearFileInput();
  }

  clearFileInput() {
    this.selectedFile.nativeElement.value = '';
  }


  public getUploader() {
    return this.uploader;
  }
}
