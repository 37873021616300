/**
 * Created by sebastian on 24.03.17.
 */
import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DictionaryService} from "../../common/dictionary-service";
import {DatePipe} from "@angular/common";
import {PlaylistScheduleDetails} from "../../model/generated/dtos";
import {PlaylistStatusConstants} from '../../common/constant/playlist-status-constants';

@Component({
  selector: 'playlist-cell',
  templateUrl: './playlist-cell.component.html'
})
export class PlaylistCell implements OnInit {

  @Input() details: PlaylistScheduleDetails;
  detailsActive: boolean = false;
  PlaylistStatusConstants = PlaylistStatusConstants;

  constructor(private router: Router, protected dictionaryService: DictionaryService, private datepipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  goToDetails(details: PlaylistScheduleDetails): void {
    this.router.navigate(['/detail', this.details.channel.id, this.datepipe.transform(this.details.playlistDate, 'MMddyyyy'), false]);
  }

  goToDetailsWithMissingParam(event: any, details: PlaylistScheduleDetails): void {
    event.stopPropagation();
    this.router.navigate(['/detail', this.details.channel.id, this.datepipe.transform(this.details.playlistDate, 'MMddyyyy'), true]);
  }
}
