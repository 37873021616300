<div (click)="expandRow()" class="table-row table-body">
  <div (click)="isExpanded=!isExpanded" class="table-row flexbox comparing-flexbox">
    <div class="table-row-toggleable-head toggle-row-head flexbox">
      <div style="width: 50%;">
        <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-right: 2px solid #bcbcbc; ">
          <div class="table-item item-pozycja-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}"></div>
          <div class="table-item item-poczatek-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">
            <ng-container *ngIf="rowCompare.version1 && rowCompare.version1.blockStart">
              {{rowCompare.version1.blockStart}}
            </ng-container>
          </div>
          <div class="table-item item-nazwa-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">
            <ng-container *ngIf="rowCompare.version1 && rowCompare.version1.blockName">{{rowCompare.version1.blockName}}
            </ng-container>
          </div>
          <div class="table-item item-idtvn-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">

          </div>
          <div class="table-item item-dl-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">
            <ng-container *ngIf="rowCompare.version1 && rowCompare.version1.blockLength">
              {{rowCompare.version1.blockLength}}
            </ng-container>
          </div>
          <div class="table-item item-format-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">

          </div>
          <div class="table-item item-typ-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">
            <ng-container *ngIf="rowCompare.version1 && rowCompare.version1.blockType">
              {{dictionaryService.dictionary.playlistDetails.search.blockTypes[rowCompare.version1.blockType]}}
            </ng-container>
          </div>
          <div class="table-item item-piwo-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">

          </div>
          <div class="table-item item-hfss-compare"
               [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'deleted': rowCompare.diffStatus == 'deleted'}">

          </div>
        </div>
      </div>

      <div style="width: 50%">
        <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-left: 2px solid #bcbcbc;">

          <div class="table-item compare-pad item-pozycja-compare" style="" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}"></div>
          <div class="table-item compare-pad item-poczatek-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">
            <ng-container *ngIf="rowCompare.version2 && rowCompare.version2.blockStart">
              {{rowCompare.version2.blockStart}}
            </ng-container>
          </div>
          <div class="table-item compare-pad item-nazwa-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">
            <ng-container *ngIf="rowCompare.version2 && rowCompare.version2.blockName">{{rowCompare.version2.blockName}}
            </ng-container>
          </div>
          <div class="table-item compare-pad item-idtvn-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">

          </div>
          <div class="table-item compare-pad item-dl-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">
            <ng-container *ngIf="rowCompare.version2 && rowCompare.version2.blockLength">
              {{rowCompare.version2.blockLength}}
            </ng-container>
          </div>
          <div class="table-item  compare-pad item-format-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">

          </div>
          <div class="table-item compare-pad item-typ-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">
            <ng-container *ngIf="rowCompare.version2 && rowCompare.version2.blockType">
              {{dictionaryService.dictionary.playlistDetails.search.blockTypes[rowCompare.version2.blockType]}}
            </ng-container>
          </div>
          <div class="table-item compare-pad item-piwo-compare" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">

          </div>
          <div class="table-item compare-pad item-hfss-compare" style="border-right: 1px transparent" [ngClass]="{'modify': rowCompare.diffStatus == 'modified',
                                                                'added': rowCompare.diffStatus == 'added'}">

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="rowCompare.diffStatus=='modified'" class="item-akcjewaskie-compare">
      <div class="comparing-alert">
        <div class="comparing-alert-arrow"></div>
        <div class="comparing-alert-label">{{dictionaryService.dictionary.playlistComparison.row.differenceLabel}}</div>
      </div>
    </div>
    <div *ngIf="rowCompare.diffStatus=='added'" class="item-akcjewaskie-compare table-item-added">
      <div class="comparing-alert">
        <div class="comparing-added-arrow"></div>
        <div class="comparing-added-label">{{dictionaryService.dictionary.playlistComparison.row.addedLabel}}</div>
      </div>
    </div>
    <div *ngIf="rowCompare.diffStatus=='deleted'" class="item-akcjewaskie-compare compare-deleted-alert">
      <div class="comparing-alert">
        <div class="comparing-deleted-arrow"></div>
        <div class="comparing-deleted-label">{{dictionaryService.dictionary.playlistComparison.row.deletedLabel}}</div>
      </div>
    </div>
  </div>
</div><!-- .table-row -->

<div *ngIf="rowCompare.diffStatus == 'unchanged'" class="table-row table-body">
  <div class="table-row flexbox toggle-row-body changed-msg" [ngClass]="{'active': isExpanded}">
    <ng-container *ngFor="let spot of rowCompare.version1.childList">
      <div class="flexbox comparing-flexbox">
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-right: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad item-hfss-compare" style="">
              {{spot.hfss}}
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-left: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad item-hfss-compare">
              {{spot.hfss}}
            </div>
            <!--<div class="table-item item-akcjewaskie-compare table-item-modify">-->
            <!--<div class="comparing-alert">-->
            <!--<div class="comparing-modify-arrow"></div>-->
            <!--<div class="comparing-modify-label">Zmodyfikowany</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div><!-- .table-row -->

<div *ngIf="rowCompare.diffStatus == 'added'" class="table-row table-body">
  <div class="table-row flexbox toggle-row-body changed-msg" [ngClass]="{'active': isExpanded}">
    <ng-container *ngFor="let spot of rowCompare.version2.childList">
      <div class="flexbox comparing-flexbox">
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-right: 2px solid #bcbcbc;">
            <div class="table-item compare-pad item-pozycja-compare">
            </div>
            <div class="table-item compare-pad item-poczatek-compare">
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
            </div>
            <div class="table-item compare-pad item-dl-compare">
            </div>
            <div class="table-item compare-pad item-format-compare">
            </div>
            <div class="table-item compare-pad item-typ-compare">
            </div>
            <div class="table-item compare-pad item-piwo-compare">
            </div>
            <div class="table-item compare-pad item-hfss-compare" style="">
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-left: 2px solid #bcbcbc;">
            <div class="table-item compare-pad item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item  compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad  item-hfss-compare">
              {{spot.hfss}}
            </div>
          </div>
      </div>
      </div>
    </ng-container>
  </div>
</div><!-- .table-row -->

<div *ngIf="rowCompare.diffStatus == 'deleted'" class="table-row table-body">
  <div class="table-row flexbox toggle-row-body changed-msg" [ngClass]="{'active': isExpanded}">
    <ng-container *ngFor="let spot of rowCompare.version1.childList">
      <div class="flexbox comparing-flexbox">
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-right: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item  compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item  compare-pad item-hfss-compare" style="">
              {{spot.hfss}}
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-left: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
            </div>
            <div class="table-item compare-pad item-poczatek-compare">
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
            </div>
            <div class="table-item  compare-pad item-idtvn-compare">
            </div>
            <div class="table-item compare-pad item-dl-compare">
            </div>
            <div class="table-item compare-pad item-format-compare">
            </div>
            <div class="table-item compare-pad item-typ-compare">
            </div>
            <div class="table-item compare-pad item-piwo-compare">
            </div>
            <div class="table-item compare-pad item-hfss-compare">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div><!-- .table-row -->


<div *ngIf="rowCompare.diffStatus == 'modified'" class="table-row table-body">
  <div class="table-row flexbox toggle-row-body changed-msg" [ngClass]="{'active': isExpanded}">
    <ng-container *ngFor="let spot of rowCompare.version1.childList">
      <div *ngIf="spot.status == 'modified'" class="flexbox comparing-flexbox">
        <div style="width: 50%;">
          <div class="table-comparing-row-modify flexbox indent-items"
               style="width: 100%; height: 100%; border-right: 2px solid #bcbcbc; ">
            <div class="table-item compare-pad table-item-modify item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad table-item-modify item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad table-item-modify item-nazwa-compare text-center">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad table-item-modify item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad table-item-modify item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad table-item-modify item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad table-item-modify item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad table-item-modify item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad table-item-modify item-hfss-compare" style="">
              {{spot.hfss}}
            </div>
          </div>
        </div>

        <div style="width: 50%;">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%;height: 100%; border-left: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item-modify item-pozycja-compare">
              {{spot.spotVersion2.position}}
            </div>
            <div class="table-item compare-pad table-item-modify item-poczatek-compare table-item-shift">
              {{spot.spotVersion2.blockStart}}
            </div>
            <div class="table-item compare-pad table-item-modify item-nazwa-compare">
              {{spot.spotVersion2.materialName}}
            </div>
            <div class="table-item compare-pad table-item-modify item-idtvn-compare">
              {{spot.spotVersion2.partnerId}}
            </div>
            <div class="table-item compare-pad table-item-modify item-dl-compare">
              {{spot.spotVersion2.blockLength}}
            </div>
            <div class="table-item compare-pad table-item-modify item-format-compare">
              {{spot.spotVersion2.format}}
            </div>
            <div class="table-item compare-pad table-item-modify item-typ-compare">
              {{spot.spotVersion2.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad table-item-modify item-piwo-compare">
              {{spot.spotVersion2.piwo}}
            </div>
            <div class="table-item compare-pad table-item-modify item-hfss-compare">
              {{spot.spotVersion2.hfss}}
            </div>
          </div>
        </div>
        <div class="item-akcjewaskie-compare table-item-modify">
          <div class="comparing-alert">
            <div class="comparing-modify-arrow"></div>
            <div class="comparing-modify-label">{{dictionaryService.dictionary.playlistComparison.row.modifiedLabel}}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="spot.status == 'added'" class="flexbox comparing-flexbox">
        <div style="width: 50%;">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; height: 100%;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
            </div>
            <div class="table-item compare-pad item-poczatek-compare">
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
            </div>
            <div class="table-item compare-pad item-dl-compare">
            </div>
            <div class="table-item compare-pad item-format-compare">
            </div>
            <div class="table-item compare-pad item-typ-compare">
            </div>
            <div class="table-item  compare-pad item-piwo-compare">
            </div>
            <div class="table-item  compare-pad item-hfss-compare" style="margin-right: 10px">
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="table-comparing-row-added flexbox indent-items" style="width: 100%;">
            <div class="table-item compare-pad table-item-added table-item item-pozycja-compare" style="margin-left: 5px">
              {{spot.spotVersion2.position}}
            </div>
            <div class="table-item compare-pad table-item-added item-poczatek-compare table-item-shift">
              {{spot.spotVersion2.blockStart}}
            </div>
            <div class="table-item compare-pad table-item-added item-nazwa-compare">
              {{spot.spotVersion2.materialName}}
            </div>
            <div class="table-item compare-pad table-item-added item-idtvn-compare">
              {{spot.spotVersion2.partnerId}}
            </div>
            <div class="table-item compare-pad table-item-added item-dl-compare">
              {{spot.spotVersion2.blockLength}}
            </div>
            <div class="table-item compare-pad table-item-added item-format-compare">
              {{spot.spotVersion2.format}}
            </div>
            <div class="table-item compare-pad table-item-added item-typ-compare">
              {{spot.spotVersion2.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad table-item-added item-piwo-compare">
              {{spot.spotVersion2.piwo}}
            </div>
            <div class="table-item compare-pad table-item-added item-hfss-compare">
              {{spot.spotVersion2.hfss}}
            </div>
          </div>
        </div>
        <div class="item-akcjewaskie-compare table-item-added" style="padding: 0px;">
          <div class="comparing-alert">
            <div class="comparing-added-arrow"></div>
            <div class="comparing-added-label">{{dictionaryService.dictionary.playlistComparison.row.addedLabel}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="spot.status == 'deleted'" class="flexbox comparing-flexbox">
        <div style="width: 50%;">
          <div class="table-comparing-row-deleted flexbox indent-items" style="width: 100%;">
            <div class="table-item compare-pad table-item-deleted item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad table-item-deleted item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item  compare-pad table-item-deleted item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad  table-item-deleted item-hfss-compare" style="">
              {{spot.hfss}}
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; height: 100%;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
            </div>
            <div class="table-item compare-pad item-poczatek-compare">
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
            </div>
            <div class="table-item  compare-pad item-idtvn-compare">
            </div>
            <div class="table-item  compare-pad item-dl-compare">
            </div>
            <div class="table-item  compare-pad item-format-compare">
            </div>
            <div class="table-item  compare-pad item-typ-compare">
            </div>
            <div class="table-item compare-pad  item-piwo-compare">
            </div>
            <div class="table-item  compare-pad item-hfss-compare">
            </div>
          </div>
        </div>
        <div class="item-akcjewaskie-compare table-item-deleted" style="padding: 0px">
          <div class="comparing-alert">
            <div class="comparing-deleted-arrow"></div>
            <div class="comparing-deleted-label">{{dictionaryService.dictionary.playlistComparison.row.deletedLabel}}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="spot.status == 'unchanged'" class="flexbox comparing-flexbox">
        <div style="width: 50%;">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-right: 2px solid #bcbcbc;">
            <div class="table-item compare-pad table-item item-pozycja-compare">
              {{spot.position}}
            </div>
            <div class="table-item compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item compare-pad item-hfss-compare" style="">
              {{spot.hfss}}
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="table-comparing-row flexbox indent-items" style="width: 100%; border-left: 2px solid #bcbcbc;">
            <div class="table-item  compare-pad table-item item-pozycja-compare" style="">
              {{spot.position}}
            </div>
            <div class="table-item  compare-pad item-poczatek-compare table-item-shift">
              {{spot.blockStart}}
            </div>
            <div class="table-item compare-pad item-nazwa-compare">
              {{spot.materialName}}
            </div>
            <div class="table-item  compare-pad item-idtvn-compare">
              {{spot.partnerId}}
            </div>
            <div class="table-item compare-pad  item-dl-compare">
              {{spot.blockLength}}
            </div>
            <div class="table-item compare-pad item-format-compare">
              {{spot.format}}
            </div>
            <div class="table-item compare-pad item-typ-compare">
              {{spot.blockType | capitalizeFirst}}
            </div>
            <div class="table-item  compare-pad item-piwo-compare">
              {{spot.piwo}}
            </div>
            <div class="table-item  compare-pad item-hfss-compare">
              {{spot.hfss}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div><!-- .table-row -->
