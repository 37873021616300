import {Injectable} from '@angular/core';
import {AppContext} from "../model/app-context";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class GlobalUtilService {

  appContext: AppContext = new AppContext(environment.PROD);

  blockUi(block: boolean) {
    this.appContext.blockedUi = block;
  }

  getAppContext(): AppContext {
    return this.appContext;
  }

  isIE(): boolean {
    let ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('msie') > -1 || ua.indexOf('trident/') > -1 || ua.indexOf('edge/') > 1;
  }

}
