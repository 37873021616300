import {Component, ViewChild} from "@angular/core";
import {DictionaryService} from "../common/dictionary-service";
import {StatisticsDashboardComponent} from "./statistics-dashboard/statistics-dashboard.component";
import {BaseComponent} from "../base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../common/loader.service";

@Component({
  selector: 'statistics',
  templateUrl: './statistics.component.html'
})
export class StatisticsComponent extends BaseComponent {

  constructor(public dictionaryService: DictionaryService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected loaderService: LoaderService) {
    super(router, route, loaderService);
  }

  @ViewChild(StatisticsDashboardComponent) statisticsDashboardComponent: StatisticsDashboardComponent;

  forceDashboardReload() {
    this.statisticsDashboardComponent.getStatistics();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
