/**
 * Created by sebastian on 24.03.17.
 */
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PlaylistGridService} from "../playlist-grid/playlist-grid.service";
import {of} from 'rxjs';
import {DatePipe} from "@angular/common";
import {PlaylistSearchService} from "./search-playlist.service";
import {LoaderService} from "../../common/loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DictionaryService} from "../../common/dictionary-service";
import {ChannelBean, ChannelsGroupBean, MaterialBean, PlaylistBean, PlaylistImportBean, PlaylistImportDatesBean, PlaylistImportPrepareRequestBean, PlaylistImportPrepareResponseBean, UserBean} from "../../model/generated/dtos";
import {environment} from "../../../environments/environment";
import {ChangeMaterialRequestBean} from "../../model/technical/change-material/change-material-request-bean.model";
import {InputDebounceComponent} from "../../shared/input-debounce/input-debounce.component";
import {CommonMessageService} from "../../common/common-message-service";

@Component({
  selector: 'search-playlist',
  templateUrl: './search-playlist.component.html'
})

export class SearchPlaylist implements OnInit {

  date: Date = new Date();
  baseApiUrl: string = environment.BASE_API_URL;

  isCleared: boolean = false;
  diffGenerationAvl: boolean = false;
  isForceRefresh: boolean = false;

  private _slotsCount: number = 7;

  private _slotsCountTimer: number | undefined;

  @Output() rowsData: EventEmitter<PlaylistBean[]> = new EventEmitter();
  @Output() dateEmit: EventEmitter<Date> = new EventEmitter();
  @Input() groups: ChannelsGroupBean[];
  @Input() user: UserBean;

  @Input() set slotsCount(value: number) {
    if (!!this._slotsCountTimer) {
      clearTimeout(this._slotsCountTimer);
    }

    this._slotsCountTimer = setTimeout(() => {
      this._slotsCount = value;
      this.forceRefreshData();
    }, 500);
  }

  usersChannels: ChannelBean[];
  selectedUsersChannels: ChannelBean[];
  selectedUsersChannelsCopy: ChannelBean[] = [];
  selectedGroups: ChannelsGroupBean[] = [];
  selectedGroupsCopy: ChannelsGroupBean[] = [];
  selectedGroupsIds: Array<number> = [];
  selectedGroupsIdsCopy: Array<number> = [];
  selectedUserChannelsIds: Array<number> = [];
  selectedUserChannelsIdsCopy: Array<number> = [];
  selectedChannelGroup: ChannelsGroupBean;
  foundChannelGroups: ChannelsGroupBean[];

  //IMPORT PLAYLIST ROBOCZYCH
  IMPORT_STATUS_BEFORE = 'before';
  IMPORT_STATUS_ERROR = 'error';
  IMPORT_STATUS_PROCESSING = 'processing';
  IMPORT_STATUS_END = 'end';
  importRequestBean: PlaylistImportPrepareRequestBean = {} as PlaylistImportPrepareRequestBean;
  importResponseBean: PlaylistImportPrepareResponseBean = {} as PlaylistImportPrepareResponseBean;
  importChannelGroupDisabled: boolean;
  importChannelDisabled: boolean;
  importFromUserDisabled: boolean;
  importMinDate: Date = new Date();
  importMaxDate: Date = new Date();
  importEndCount: number = 0;   //zakonczona operacja importu
  importTotalCount: number = 0; //liczba wszystkich par kanal-data
  importDoneCount: number = 0;  //poprawnie zaimportowane
  importCurrentPlaylist: PlaylistImportBean = null;
  importStatus: string = this.IMPORT_STATUS_BEFORE; //before - przed importem //error - import przerwany z powodu błędu //processing - w trakcie importowania //end - zakończono import
  importButtonActive: boolean = true;

  // ZMIENNE WSPÓLNE DLA POPUPÓW
  popupFoundChannelNames: string[];
  popupFoundChannelGroupNames: string[];

  //ZMIANA MATERIAŁU
  changeMaterialRequest: ChangeMaterialRequestBean = new ChangeMaterialRequestBean();
  sendChangesButtonActive: boolean = true;
  changeMaterialGroupDisabled: boolean;
  changeMaterialChannelDisabled: boolean;
  oldMaterialDetails: MaterialBean;
  newMaterialDetails: MaterialBean;
  @ViewChild('newMaterialInput') newMaterialInput: InputDebounceComponent;
  @ViewChild('oldMaterialInput') oldMaterialInput: InputDebounceComponent;

  constructor(private playlistService: PlaylistGridService,
              private searchPlaylist: PlaylistSearchService,
              public datepipe: DatePipe,
              private loaderService: LoaderService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected dictionaryService: DictionaryService,
              protected messageService: CommonMessageService,
              protected playlistSearchService: PlaylistSearchService) {
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryString => {
      this.date = this.parseQueryDate(queryString['date']);
      if (this.date && this.user.role && this.user.active && this.user.agreementSigned) {
        if (this.user && (this.user.role == 'ADMIN' || this.user.role == 'OPERATOR')) {
          this.handleGroupsQuery(queryString);
        } else {
          this.handleUserChannelsQuery(queryString);
        }
      }
    });
    this.dateEmit.emit(this.date);
    if (this.user.role == 'MANAGER' || this.user.role == 'USER') {
      this.getDiffGenerationAvl();
    }
    this.searchPlaylist.getAvailableImportDates().subscribe(resp => {
      this.setImportDateLimits(resp);
    });
  }

  private handleGroupsQuery(queryString) {
    this.loadGroups().subscribe(groups => {
      this.groups = groups;
      let channelIds = this.parseChannelGroupIds(queryString['groupsIds']);
      if (queryString['groupsIds'] == null || (queryString['groupsIds'] == '[]' && this.isCleared == false)) {
        this.addAllGroups();
        this.onParamChange();
      } else
        this.searchPlayLists(channelIds);
    });
  }

  private handleUserChannelsQuery(queryString) {
    this.loadUserChannels().subscribe(userChannels => {
      this.usersChannels = userChannels;
      if (queryString['channelIds'] && queryString['channelIds'] != '[]') {
        let channelIds = JSON.parse(queryString['channelIds']);

        this.selectedUsersChannels = this.usersChannels.filter(channel => (channelIds.indexOf(channel.id) > -1));
        this.selectedUserChannelsIds = channelIds;
        this.sortSelectedChannels();
      } else {
        this.selectedUsersChannels = this.usersChannels;
        this.selectedUserChannelsIds = this.selectedUsersChannels.map(t => t.id);
        this.sortSelectedChannels();
      }

      let selectedChannelsString = this.selectedUsersChannels.map(t => t.id).join(', ');
      this.searchPlayLists(selectedChannelsString);
      this.onParamChange();
    });
  }

  private parseChannelGroupIds(groupsIds) {
    if (groupsIds) {
      this.selectedGroupsIds = JSON.parse(groupsIds);
      this.selectedGroups = this.getSelectedPlaylistGroups(this.groups, this.selectedGroupsIds);
      this.sortSelectedGroups();
      return this.getJoinedChanneGroupslIds(this.selectedGroups);
    } else
      return '';
  }

  loadGroups() {
    return (this.groups ? of(this.groups) : this.searchPlaylist.getAllGroupsWithChannels());
  }

  loadUserChannels() {
    return (this.usersChannels ? of(this.usersChannels) : this.searchPlaylist.getUserChannels());
  }

  reloadUserChannels() {
    return this.searchPlaylist.getUserChannels();
  }

  private parseQueryDate(dateParam: any) {
    let queryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24));
    if (dateParam)
      queryDate.setTime(dateParam);
    return queryDate;
  }

  private getSelectedPlaylistGroups(groups: ChannelsGroupBean[], selectedGroupsIds: Array<number>) {
    if (groups && groups.length > 0)
      return groups.filter(group => selectedGroupsIds.indexOf(group.id) > -1);
    else
      return [];
  }

  private searchPlayLists(channelIds: string) {
    let transformedDate = this.datepipe.transform(this.date, 'MMddyyyy');
    this.loadPlaylists(transformedDate, channelIds);
  }

  private loadPlaylists(transformedDate: string, ids: string) {
    this.loaderService.showLoader();

    this.playlistService.getPlaylists(transformedDate, ids, this._slotsCount).subscribe({
      next: (data) => {
        this.rowsData.emit(data);
        this.loaderService.hideLoader();
      },
      error: () => {
        this.loaderService.hideLoader();
      }
    });

    if (this.isForceRefresh) {
      this.restoreSearchParams();
      this.isForceRefresh = false;
    }

  }

  dateChange() {
    this.saveSearchParamsToUrl();
    this.onParamChange();
  }

  saveSearchParamsToUrl(): void {
    this.router.navigate([], {
      queryParams: {
        'groupsIds': JSON.stringify(this.selectedGroupsIds),
        'channelIds': JSON.stringify(this.selectedUserChannelsIds),
        'date': this.date.getTime()
      },
      relativeTo: this.route
    });
  }

  addGroup(group: ChannelsGroupBean): void {
    this.selectedGroups.push(group);
    this.selectedGroupsIds.push(group.id);
    this.sortSelectedGroups();
  }

  addAllGroups(): void {
    this.selectedGroups = [];
    this.selectedGroupsIds = [];
    for (let group of this.groups) {
      this.selectedGroups.push(group);
      this.selectedGroupsIds.push(group.id);
    }
    this.sortSelectedGroups();
  }

  onParamChange() {
    this.saveSearchParamsToUrl();
    this.dateEmit.emit(this.date);
  }

  onIncreaseDate(date: Date) {
    this.date.setDate(date.getDate() + 1);
    this.onParamChange();
    this.saveSearchParamsToUrl();
  }

  onDecreaseDate(date: Date) {
    this.date.setDate(date.getDate() - 1);
    this.onParamChange();
    this.saveSearchParamsToUrl();
  }

  deleteGroup(toDelete: ChannelsGroupBean) {
    this.isCleared = true;
    this.selectedGroups.splice(this.selectedGroups.indexOf(toDelete), 1);
    this.selectedGroupsIds.splice(this.selectedGroupsIds.indexOf(toDelete.id), 1);
    this.saveSearchParamsToUrl();
  }

  deleteChannel(toDelete: ChannelBean) {
    this.isCleared = true;
    this.selectedUsersChannels.splice(this.selectedUsersChannels.indexOf(toDelete), 1);
    this.selectedUserChannelsIds.splice(this.selectedUserChannelsIds.indexOf(toDelete.id), 1);
    this.saveSearchParamsToUrl();
  }

  getJoinedChanneGroupslIds(selected: ChannelsGroupBean[]): string {
    //flatten map and join
    return [].concat.apply(selected.map(o => o.channels.map(t => t.id))).join(', ');
  }

  searchChannelsGroup(event) {
    let ids = this.selectedGroups.map(group => group.id).join(',');
    if (ids == null) {
      ids = '';
    }
    this.searchPlaylist.getChannelsGroupsByName(event.query, ids).subscribe(data => {
      this.foundChannelGroups = data;
      let lastBean = {} as ChannelsGroupBean;
      lastBean.id = -1;
      lastBean.name = this.dictionaryService.dictionary.playlists.search.selectAll;
      this.foundChannelGroups.push(lastBean);
    });
  }

  getSelectedGroup(event) {
    let group = <ChannelsGroupBean>event;
    if (group.id != -1) {
      this.addGroup(group);
    } else {
      this.addAllGroups();
    }
    this.selectedChannelGroup = null;
  }

  onClearClick() {
    this.isCleared = true;
    if (this.user.role && this.user.active) {
      if (this.user && (this.user.role == 'ADMIN' || this.user.role == 'OPERATOR')) {
        this.selectedGroups = [];
        this.selectedGroupsIds = [];

      } else {
        this.selectedUserChannelsIds = this.usersChannels.map(t => t.id);
        this.selectedUsersChannels = this.usersChannels;
        this.sortSelectedChannels();

        this.reloadUserChannels().subscribe(userChannels => {
          this.usersChannels = userChannels;
        });
      }
    }

    this.onParamChange();
    this.saveSearchParamsToUrl();
  }

  openImportPopup(): void {
    this.resetImportPopup();
    var outerHtmlElement: any = document.getElementById('import-playlist-form-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  openChangeMaterial(): void {
    this.resetChangePopup();
    let outerHtmlElement: any = document.getElementById('change-material-form-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  closeImportPopup(): void {
    var outerHtmlElement: any = document.getElementById('import-playlist-form-dialog');
    if (this.importStatus != this.IMPORT_STATUS_BEFORE) {
      this.forceRefreshData();
    }
    this.fixedClosePopup(outerHtmlElement);
  }

  closeChangeMaterialPopup(): void {
    let outerHtmlElement: any = document.getElementById('change-material-form-dialog');
    this.fixedClosePopup(outerHtmlElement);
  }

  fixedOpenPopup(dialogHtmlElement: any) {
    dialogHtmlElement.style.top = window.scrollY + 100 + 'px';
    dialogHtmlElement.setAttribute("open", "open");
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
  }

  getDiffGenerationAvl() {
    this.playlistSearchService.getProfileDiffGeneration().subscribe(res => {
      this.diffGenerationAvl = res;
    });
  }


  generateDivergencesReport() {
    this.loaderService.showLoader();
    this.playlistSearchService.checkDivergencesReport().subscribe(() => {
      let browser = window.navigator.userAgent.toLowerCase();
      if (browser.indexOf('firefox') > -1) {
        window.open(
          this.baseApiUrl + 'reports/generateDivergencesReport',
          '_blank'
        );
        window.close();
        return false;
      } else {
        window.location.href = this.baseApiUrl + 'reports/generateDivergencesReport';
      }
      this.loaderService.hideLoader();
    }, () => {
      this.loaderService.hideLoader();
    });

  }

  //kopiowanie grup kanałowych/kanałów przed odświeżeniem danych jako aktualnych parametrów wyszukiwania
  //bez tego kopiowania przy wymuszeniu odswiezenia danych (poprzez timer lub odświeżenie strony) zaznaczone na gui pozycje znikają i odświeżone zostają dane ostatniego wyszukiwania )
  copySearchParams() {
    if (this.selectedGroups != null) {
      this.selectedGroupsCopy = [];
      this.selectedGroups.forEach(el => {
        this.selectedGroupsCopy.push(el);
      });
    } else {
      this.selectedGroupsCopy = null;
    }

    if (this.selectedGroupsIds != null) {
      this.selectedGroupsIdsCopy = [];
      this.selectedGroupsIds.forEach(el => {
        this.selectedGroupsIdsCopy.push(el);
      });
    } else {
      this.selectedGroupsIdsCopy = null;
    }

    if (this.selectedUsersChannels != null) {
      this.selectedUsersChannelsCopy = [];
      this.selectedUsersChannels.forEach(el => {
        this.selectedUsersChannelsCopy.push(el);
      });
    } else {
      this.selectedUsersChannelsCopy = null;
    }

    if (this.selectedUserChannelsIds != null) {
      this.selectedUserChannelsIdsCopy = [];
      this.selectedUserChannelsIds.forEach(el => {
        this.selectedUserChannelsIdsCopy.push(el);
      });
    } else {
      this.selectedUserChannelsIdsCopy = null;
    }
  }

  restoreSearchParams() {
    if (this.selectedGroupsCopy != null) {
      this.selectedGroups = [];
      this.selectedGroupsCopy.forEach(el => {
        this.selectedGroups.push(el);
      });
      this.sortSelectedGroups();
    } else {
      this.selectedGroups = null;
    }

    if (this.selectedGroupsIdsCopy != null) {
      this.selectedGroupsIds = [];
      this.selectedGroupsIdsCopy.forEach(el => {
        this.selectedGroupsIds.push(el);
      });
    } else {
      this.selectedGroupsIds = null;
    }

    if (this.selectedUsersChannelsCopy != null) {
      this.selectedUsersChannels = [];
      this.selectedUsersChannelsCopy.forEach(el => {
        this.selectedUsersChannels.push(el);
      });
      this.sortSelectedChannels();
    } else {
      this.selectedUsersChannels = null;
    }

    if (this.selectedUserChannelsIdsCopy != null) {
      this.selectedUserChannelsIds = [];
      this.selectedUserChannelsIdsCopy.forEach(el => {
        this.selectedUserChannelsIds.push(el);
      });
    } else {
      this.selectedUserChannelsIds = null;
    }

  }


  forceRefreshData() {
    this.isForceRefresh = true;
    this.copySearchParams();
    this.route.queryParams.subscribe(queryString => {
      this.date = this.parseQueryDate(queryString['date']);
      if (this.date && this.user.role && this.user.active && this.user.agreementSigned) {
        if (this.user && (this.user.role == 'ADMIN' || this.user.role == 'OPERATOR')) {
          this.handleGroupsQuery(queryString);
        } else {
          this.handleUserChannelsQuery(queryString);
        }
      }
    });
  }

  //IMPORT PLAYLIST ROBOCZYCH
  private setImportDateLimits(bean: PlaylistImportDatesBean) {
    this.importMinDate = new Date(bean.dateFrom);
    this.setNeutralTime(this.importMinDate);
    this.importMaxDate = new Date(bean.dateTo);
    this.setNeutralTime(this.importMaxDate);
  }

  validateImportForm() {
    //ŹRÓDŁO IMPORTU
    if (this.user.role == "ADMIN" || this.user.role == "OPERATOR") {
      //KANAŁ/GRUPA KANAŁOWA OBOWIĄZKOWA
      if (this.isBlank(this.importRequestBean.channelName) && this.isBlank(this.importRequestBean.channelGroupName)) {
        this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importChannelOrGroupMissing);
        return;
      }
    } else {
      if (this.user.role == "USER" || this.user.role == "MANAGER") {
        if (this.isBlank(this.importRequestBean.channelName) && !this.importRequestBean.fromUser) {
          this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importChannelOrProfileChannel);
          return;
        }
      }
    }

    //TERMIN IMPORTU
    if (this.importRequestBean.dateFrom == null || this.importRequestBean.dateTo == null) {
      this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importDateMissing);
      return;
    }
    this.setNeutralTime(this.importRequestBean.dateFrom);
    this.setNeutralTime(this.importRequestBean.dateTo);

    if (this.importRequestBean.dateFrom > this.importRequestBean.dateTo) {
      this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importDateOrderError);
      return;
    }
    if (this.importRequestBean.dateFrom < this.importMinDate || this.importRequestBean.dateTo > this.importMaxDate) {
      this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importDateLimitError + this.datepipe.transform(this.importMinDate, 'yyyy/MM/dd') + " - " + this.datepipe.transform(this.importMaxDate, 'yyyy/MM/dd'));
      return;
    }

    this.getPreparedDataForPlaylistImport();
  }

  validateChangeMaterialForm() {
    if (this.isBlank(this.changeMaterialRequest.channelName) && this.isBlank(this.changeMaterialRequest.channelGroupName)) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.channelOrGroupMissing);
      return;
    }

    //TERMIN ZMIANY
    if (this.changeMaterialRequest.dateFrom == null || this.changeMaterialRequest.dateTo == null) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.dateMissing);
      return;
    }
    this.setNeutralTime(this.changeMaterialRequest.dateFrom);
    this.setNeutralTime(this.changeMaterialRequest.dateTo);

    if (this.changeMaterialRequest.dateFrom > this.changeMaterialRequest.dateTo) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.dateOrderError);
      return;
    }
    if (this.changeMaterialRequest.dateFrom < this.importMinDate || this.changeMaterialRequest.dateTo > this.importMaxDate) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.dateLimitError + this.datepipe.transform(this.importMinDate, 'yyyy/MM/dd') + " - " + this.datepipe.transform(this.importMaxDate, 'yyyy/MM/dd'));
      return;
    }

    if (this.isBlank(this.changeMaterialRequest.oldMaterialCode)) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.oldMaterialMissing);
      return;
    }

    if (this.isBlank(this.changeMaterialRequest.newMaterialCode)) {
      this.messageService.warning(this.dictionaryService.dictionary.changeMaterialPopup.newMaterialMissing);
      return;
    }

    if (!this.oldMaterialDetails || !this.newMaterialDetails) {
      this.messageService.error(this.dictionaryService.dictionary.errors.materialNotFound);
      return;
    }

    this.loaderService.showLoader();
    this.playlistSearchService.changeMaterial(this.changeMaterialRequest).subscribe(() => {
      this.messageService.success(this.dictionaryService.dictionary.changeMaterialPopup.operationSuccess);
      this.loaderService.hideLoader();
    }, () => {
      this.loaderService.hideLoader();
    });
  }

  getPreparedDataForPlaylistImport() {
    this.loaderService.showLoader(true);
    this.importRequestBean.dateFrom.setHours(12);
    this.importRequestBean.dateTo.setHours(12);
    this.searchPlaylist.getPreparedDataForPlaylistImport(this.importRequestBean).subscribe(resp => {
      if (resp.errorCode == null) {
        this.messageService.success(this.dictionaryService.dictionary.importPlaylist.importStart);
        this.setImportButtonState(false);
        this.resetImportCount();
        this.importStatus = this.IMPORT_STATUS_PROCESSING;
        this.importResponseBean = resp;
        this.importTotalCount = this.importResponseBean.errors.length + this.importResponseBean.preparedPlaylists.length;
        this.importEndCount = this.importResponseBean.errors.length;
        this.importPlaylistRequest(0);
      } else {
        this.loaderService.hideLoader(true);
        this.messageService.error(this.dictionaryService.dictionary.importPlaylist[resp.errorCode]);
      }
    }, () => {
      this.importStatus = this.IMPORT_STATUS_ERROR;
      this.loaderService.hideLoader(true);
      this.messageService.error(this.dictionaryService.dictionary.importPlaylist.errorOccured);
    });
  }

  importPlaylistRequest(playlistIndex: number) {
    if (playlistIndex < this.importResponseBean.preparedPlaylists.length) {
      this.importCurrentPlaylist = this.importResponseBean.preparedPlaylists[playlistIndex];
      this.searchPlaylist.importPlayList(this.importResponseBean.preparedPlaylists[playlistIndex]).subscribe(resp => {
        this.importEndCount++;
        if (resp.statusCode != "DONE") {
          this.importResponseBean.errors.push(resp);
        } else {
          this.importDoneCount++;
        }

        this.importPlaylistRequest(playlistIndex + 1);
      }, () => {
        this.importStatus = this.IMPORT_STATUS_ERROR;
        this.loaderService.hideLoader(true);
      });
    } else {
      this.importStatus = this.IMPORT_STATUS_END;
      this.sortImportPlaylistErrors();
      this.loaderService.hideLoader(true);
      if (this.importResponseBean.errors.length <= 0 && this.importDoneCount > 0) {
        this.messageService.success(this.dictionaryService.dictionary.importPlaylist.importFullSuccess);
      } else {
        this.messageService.warning(this.dictionaryService.dictionary.importPlaylist.importPartialSuccess);
      }
    }
  }

  sortImportPlaylistErrors() {
    this.importResponseBean.errors = this.importResponseBean.errors.sort((r1, r2) => {
      if (r1.channelName > r2.channelName) {
        return 1;
      } else if (r1.channelName < r2.channelName) {
        return -1;
      } else {
        if (r1.playlistDate > r2.playlistDate) {
          return 1;
        } else if (r1.playlistDate < r2.playlistDate) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }


  //AUTOCOMPLETE - kanał importowany
  searchPopupChannels(event) {
    if (this.user.role == 'MANAGER' || this.user.role == 'USER') {
      this.playlistSearchService.getUserChannelsByName(event.query, '').subscribe(data => {
        this.popupFoundChannelNames = data.map(d => d.name);
      });
    } else {
      this.playlistSearchService.getChannelsByName(event.query, '').subscribe(data => {
        this.popupFoundChannelNames = data.map(d => d.name);
      });
    }
  }

  //AUTOCOMPLETE - importowana grupa kanałowa
  searchImportChannelGroups(event) {
    this.searchPlaylist.getChannelsGroupsByName(event.query, '').subscribe(data => {
      this.popupFoundChannelGroupNames = data.map(d => d.name);
    });
  }

  setImportButtonStateTrue() {
    this.setImportButtonState(true);
  }

  resetImportPopup(): void {
    this.importStatus = this.IMPORT_STATUS_BEFORE;
    this.setImportButtonState(true);
    this.resetImportCount();
    this.importRequestBean = {} as PlaylistImportPrepareRequestBean;
    this.importResponseBean = {} as PlaylistImportPrepareResponseBean;
    this.checkImportsSelectsDisability();
  }

  resetChangePopup(): void {
    this.changeMaterialRequest = new ChangeMaterialRequestBean();
    this.oldMaterialDetails = null;
    this.newMaterialDetails = null;
    this.oldMaterialInput.resetInput();
    this.newMaterialInput.resetInput();
    this.checkChangeMaterialSelectsDisability();
  }

  checkImportsSelectsDisability() {
    setTimeout(() => {
      this.importChannelDisabled = !this.isBlank(this.importRequestBean.channelGroupName) || this.importRequestBean.fromUser;
      this.importChannelGroupDisabled = !this.isBlank(this.importRequestBean.channelName);
      this.importFromUserDisabled = !this.isBlank(this.importRequestBean.channelName);
    });
  }

  checkChangeMaterialSelectsDisability() {
    setTimeout(() => {
      this.changeMaterialChannelDisabled = !this.isBlank(this.changeMaterialRequest.channelGroupName);
      this.changeMaterialGroupDisabled = !this.isBlank(this.changeMaterialRequest.channelName);
    });
  }

  resetImportCount(): void {
    this.importEndCount = 0;
    this.importTotalCount = 0;
    this.importDoneCount = 0;
    this.importCurrentPlaylist = null;
  }

  private setNeutralTime(date: Date) {
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  isBlank(arg: string): boolean {
    return arg == null || arg.length <= 0;
  }

  onImportStartDateChange() {
    this.setImportButtonState(true);
    if (this.importRequestBean.dateTo == null) {
      this.importRequestBean.dateTo = new Date(this.importRequestBean.dateFrom);
    }
  }

  onChangeMaterialStartDateChange() {
    if (this.changeMaterialRequest.dateTo == null) {
      this.changeMaterialRequest.dateTo = new Date(this.changeMaterialRequest.dateFrom);
    }
  }

  setImportButtonState(active: boolean) {
    this.importButtonActive = active;
  }

  getOldMaterialDetails(code: string) {
    this.changeMaterialRequest.oldMaterialCode = code;
    if (this.isBlank(this.changeMaterialRequest.oldMaterialCode)) {
      return;
    }
    this.playlistSearchService.getMaterialByCode(this.changeMaterialRequest.oldMaterialCode, false).subscribe(data => {
      this.oldMaterialDetails = data;
    }, () => {
      this.oldMaterialDetails = null;
    });
  }

  getNewMaterialDetails(code: string) {
    this.changeMaterialRequest.newMaterialCode = code;
    if (this.isBlank(this.changeMaterialRequest.newMaterialCode)) {
      return;
    }
    this.playlistSearchService.getMaterialByCode(this.changeMaterialRequest.newMaterialCode, true).subscribe(data => {
      this.newMaterialDetails = data;
    }, () => {
      this.newMaterialDetails = null;
    });
  }

  sortSelectedGroups() {
    this.selectedGroups = this.selectedGroups.sort((g1, g2) => g1.name.localeCompare(g2.name));
  }

  sortSelectedChannels() {
    this.selectedUsersChannels = this.selectedUsersChannels.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }
}
