<form action="" method="post" class="filters">
  <div class="custom-panel flex-end mb-20">
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistComparison.search.channelName}}:</label>
      </div>
      <div><input *ngIf="selectedChannel" value="{{selectedChannel.name}}" class="field" type="text" name="channel" disabled/></div>
    </div>
    <div class="field-box playlist-datepicker-margin">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistComparison.search.emissionDate}}:</label>
      </div>
      <input *ngIf="date" value="{{date}}" class="field" type="text" name="emissionDate" disabled/>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistComparison.search.ver1}}:</label></div>
      <div>
        <div tabindex="0" (click)="version1Open=!version1Open" (blur)="version1Open = false"
             class="nice-select playlist-input-height" [ngClass]="{'open':version1Open}">
          <span *ngIf="selVersion1" class="current">V{{selVersion1.version}} {{selVersion1.actualization}}</span>
          <span *ngIf="!selVersion1" class="current">{{dictionaryService.dictionary.playlistComparison.search.selectVersion}}</span>
          <ul class="list">
            <ng-container *ngFor="let version of playlistsVersion">
              <li *ngIf="!(selVersion2 && selVersion2.version == version.version)" (click)="version1Select(version)"
                  class="option">V{{version.version}} {{version.actualization}}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistComparison.search.ver2}}:</label></div>
      <div>
        <div tabindex="0" (click)="version2Open=!version2Open" (blur)="version2Open = false"
             class="nice-select playlist-input-height" [ngClass]="{'open':version2Open}">
          <span *ngIf="selVersion2" class="current">V{{selVersion2.version}} {{selVersion2.actualization}}</span>
          <span *ngIf="!selVersion2" class="current">{{dictionaryService.dictionary.playlistComparison.search.selectVersion}}</span>
          <ul class="list">
            <ng-container *ngFor="let version of playlistsVersion">
              <li *ngIf="!(selVersion1 && selVersion1.version == version.version)" (click)="version2Select(version)"
                  class="option">V{{version.version}} {{version.actualization}}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <button [disabled]="!(selVersion1 && selVersion2)" (click)="compare()"
            class=" playlist-input-height  mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mb-5"
            type="submit">
      {{dictionaryService.dictionary.playlistComparison.search.compareButton}}
    </button>
    <div class="field-box playlist-input-height element-to-right-margin">
      <button *ngIf="(selVersion1 && selVersion2)" (click)="downloadComparedPlaylists()" title="Pobierz Excel"
              class="playlist-input-height mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5">
          <span>{{dictionaryService.dictionary.playlistComparison.search.downloadCompared}}</span>
      </button>
    </div>
  </div>
</form>
