export class ControlPanelMaterialStatusConstants {
  public static readonly SENT = 'sent';
  public static readonly NOT_EXIST = 'not_exist';
  public static readonly PREPARE = 'prepare';
  public static readonly SENDING = 'sending';
  public static readonly COPYING_ERROR = 'copying_error';
  public static readonly PROCESS_ERROR = 'process_error';
  public static readonly VIRTUAL = 'virtual';
  public static readonly ALL_STATUSES = [
    ControlPanelMaterialStatusConstants.SENT,
    ControlPanelMaterialStatusConstants.NOT_EXIST,
    ControlPanelMaterialStatusConstants.PREPARE,
    ControlPanelMaterialStatusConstants.SENDING,
    ControlPanelMaterialStatusConstants.COPYING_ERROR,
    ControlPanelMaterialStatusConstants.PROCESS_ERROR,
    ControlPanelMaterialStatusConstants.VIRTUAL
  ];
}
