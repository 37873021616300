<div>

  <!--WYSZUKIWANIE-->
  <form action="" method="post" class="filters" (ngSubmit)="refreshMaterials()">
    <div class="custom-panel mb-20">

      <div class="field-box">
        <div><label class="field-label">{{dictionaryService.dictionary.statistics.missingMaterials.materialName}}:</label></div>
        <div><input class="field" type="text" name="materialName" [(ngModel)]="searchFilter.materialTitleFilter" ></div>
      </div>

      <div class="field-box">
        <div><label class="field-label">{{dictionaryService.dictionary.statistics.missingMaterials.createDate}}</label></div>
        <div class="weekpicker-box">
          <p-calendar class="field"
                      [readonlyInput]="true"
                      dateFormat="yy/mm/dd"
                      selectOtherMonths="true"
                      placeholder="{{dictionaryService.dictionary.statistics.missingMaterials.fromDate}}"
                      [(ngModel)]="searchFilter.createDateFromFilter"
                      appendTo="body">
          </p-calendar>
          <p-calendar class="field"
                      [readonlyInput]="true"
                      dateFormat="yy/mm/dd"
                      selectOtherMonths="true"
                      placeholder="{{dictionaryService.dictionary.statistics.missingMaterials.toDate}}"
                      [(ngModel)]="searchFilter.createDateToFilter"
                      appendTo="body">
          </p-calendar>
        </div>
      </div>

      <div class="field-box">
        <div><label class="field-label"> &nbsp;</label></div>
        <button type="submit" class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  missing-material-search-pannel-button">
          {{dictionaryService.dictionary.statistics.missingMaterials.filter}}
          <i class="material-icons">tune</i>
        </button>
      </div>

      <div class="field-box">
        <div><label class="field-label"> &nbsp;</label></div>
        <button type="button" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  missing-material-search-pannel-button" (click)="clearFiltersAndGetMaterials()" >
          {{dictionaryService.dictionary.statistics.missingMaterials.cleanFilters}}
        </button>
      </div>

    </div>
  </form>

  <!--TABELA-->
  <div>
    <div class="table-responsive-vertical shadow-z-1">
      <table id="table" class="table table-hover table-mc-light-blue missing-table">
        <thead>
        <tr>
          <th *ngFor="let header of headers; let i = index" [ngClass]="{'column-header-hover': header.clickable}" class="">
            <div (click)="toggleArrow(i, header)" class="column-header-content">
              {{header.name}}
              <sort-arrow [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="missingMaterials.length <= 0">
          <td colspan="3" class="no-results-found">{{dictionaryService.dictionary.statistics.noResults}} <i class="material-icons">do_not_disturb</i></td>
        </tr>
        <ng-container *ngIf="missingMaterials.length > 0">
          <tr  *ngFor="let element of missingMaterials">
            <td class="missing-table-cell column-width-30">{{element.title}} [{{element.id}}]</td>
            <td class="missing-table-cell column-width-10">{{element.createDate  | date: 'yyyy/MM/dd'}}</td>
            <td class="missing-table-cell column-width-10">{{element.retryCount}}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div class="bottom-button-wrapper">
        <button  class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" [disabled]="!showMore" (click)="nextPage()" type="button">
          {{dictionaryService.dictionary.statistics.showMore}}
        </button>
      </div>
    </div>
  </div>
</div>
