<form action="" method="post" class="filters">
  <div class="custom-panel flex-end mb-20">
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistDetails.search.channelNameLabel}}:</label>
      </div>
      <div><input *ngIf="selectedChannel" value="{{selectedChannel.name}}" class="field" type="text" name="channel" disabled/></div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistDetails.search.dateLabel}}:</label></div>
      <input *ngIf="playlistBean" value="{{playlistBean.playlistDate | date: 'yyyy/MM/dd'}}" class="field" type="text" name="emissionDate" disabled/>
    </div>
    <div class="field-box">
      <div><label
        class="field-label">{{dictionaryService.dictionary.playlistDetails.search.blockBeginningLabel}}:</label></div>
      <div class="block-field">
        <p-inputMask [(ngModel)]="startTime" mask="99:99" name="poczatek_od"
                     placeholder="{{dictionaryService.dictionary.playlistDetails.search.blockBeginningPlaceholder}}:"></p-inputMask>
        <p-inputMask [(ngModel)]="endTime" mask="99:99" name="poczatek_do"
                     placeholder="{{dictionaryService.dictionary.playlistDetails.search.blockEndingPlaceholder}}:"></p-inputMask>
      </div>
    </div>

    <div class="field-box">
      <div><label
        class="field-label">{{dictionaryService.dictionary.playlistDetails.search.materialStatusLabel}}:</label></div>
      <div>
        <div *ngIf="!isMissingMaterial" tabindex="0" (click)="openListIfCheckboxNotChecked()"
             (blur)="materialStsOpen = false" class="nice-select" [ngClass]="{'open':materialStsOpen}">
          <span class="current">{{statusTranslation[chosenMaterialStatus]}}</span>
          <ul class="list">
            <li *ngFor="let status of materialStatuses" class="option selected focus"
                (click)="choseStatusFilter(status)">
              {{statusTranslation[status]}}
            </li>
          </ul>
        </div>
        <div *ngIf="isMissingMaterial" tabindex="0" class="nice-select disabledInput ">
          <span class="current ">{{statusTranslation["missing"]}}</span>
        </div>
      </div>
    </div>

    <div class="field-box-checkbox">
      <div><label
        class="field-label">{{dictionaryService.dictionary.playlistDetails.search.checkboxMissingLabel}}:</label></div>
      <div style="text-align: center">
        <input class="playlist-detail-checkbox" type="checkbox" [checked]="isMissingMaterial"
               (click)="changeIsMissingMaterial()">
      </div>
    </div>

    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistDetails.search.blockTypeLabel}}:</label>
      </div>
      <div>
        <div tabindex="0" (click)="blockTypeOpen=!blockTypeOpen" (blur)="blockTypeOpen = false" class="nice-select"
             [ngClass]="{'open':blockTypeOpen}">
          <span
            class="current">{{dictionaryService.dictionary.playlistDetails.search.blockTypes[chosenBlockType]}}</span>
          <ul class="list">
            <li *ngFor="let type of blockTypes" class="option selected focus" (click)="choseBlockTypeFilter(type)">
              {{dictionaryService.dictionary.playlistDetails.search.blockTypes[type]}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{dictionaryService.dictionary.playlistDetails.search.partnerIdLabel}}:</label>
      </div>
      <div><input [(ngModel)]="partnerId" class="field" type="text" name="id_tvn"/></div>
    </div>
    <button (click)="filter()"
            class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="submit">
      {{dictionaryService.dictionary.playlistDetails.search.filterButtonLabel}}
      <i class="material-icons">tune</i>
    </button>
    <button (click)="clearFiltr()" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mb-5">
      {{dictionaryService.dictionary.playlistDetails.search.clearButtonLabel}}
    </button>
  </div>
</form>
