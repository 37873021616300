export class MaterialStatusConstants {
  static readonly SENT_TO_BROADCASTER = 'sent_to_broadcaster';
  static readonly FILE_NOT_EXIST = 'file_not_exist';
  static readonly PREPARE_EMISSION = 'prepare_emission';
  static readonly NEW = 'new';
  static readonly UPDATE = 'update';
  static readonly RETRY = 'retry';
  static readonly RETRY_COPYING = 'retry_copying';
  static readonly LOCK = 'lock';
  static readonly SENDING_TO_BROADCASTER = 'sending_to_broadcaster';
  static readonly COPYING_ERROR = 'copying_error';
  static readonly PROCESSING_ERROR = 'processing_error';
  static readonly PROCESSING_FAILED = 'processing_failed';
  static readonly TECHNICAL_ERROR = 'technical_error';
  static readonly VIRTUAL = 'virtual';
  static readonly DONE = 'done';
  static readonly MATERIAL_NULL = 'material_null';
  static readonly ALL = 'all';
  static readonly PROCESSING = 'processing';
  static readonly ALL_MISSING = 'all_missing';

  static readonly ALL_STATUSES = [MaterialStatusConstants.NEW, MaterialStatusConstants.UPDATE,
    MaterialStatusConstants.RETRY, MaterialStatusConstants.RETRY_COPYING, MaterialStatusConstants.LOCK,
    MaterialStatusConstants.PREPARE_EMISSION, MaterialStatusConstants.SENDING_TO_BROADCASTER,
    MaterialStatusConstants.COPYING_ERROR, MaterialStatusConstants.PROCESSING_ERROR,
    MaterialStatusConstants.TECHNICAL_ERROR, MaterialStatusConstants.PROCESSING_FAILED];
  static readonly ADMIN_STATUSES = [MaterialStatusConstants.ALL, MaterialStatusConstants.SENT_TO_BROADCASTER,
    MaterialStatusConstants.FILE_NOT_EXIST, MaterialStatusConstants.PREPARE_EMISSION,
    MaterialStatusConstants.SENDING_TO_BROADCASTER, MaterialStatusConstants.COPYING_ERROR,
    MaterialStatusConstants.PROCESSING_ERROR, MaterialStatusConstants.VIRTUAL, MaterialStatusConstants.MATERIAL_NULL];
  static readonly USER_STATUSES = [MaterialStatusConstants.ALL, MaterialStatusConstants.SENT_TO_BROADCASTER,
    MaterialStatusConstants.PROCESSING, MaterialStatusConstants.VIRTUAL, MaterialStatusConstants.MATERIAL_NULL];
  static readonly STATISTICS_MATERIAL_STATUSES = [MaterialStatusConstants.SENT_TO_BROADCASTER, MaterialStatusConstants.FILE_NOT_EXIST,
    MaterialStatusConstants.PREPARE_EMISSION, MaterialStatusConstants.SENDING_TO_BROADCASTER,
    MaterialStatusConstants.COPYING_ERROR, MaterialStatusConstants.PROCESSING_ERROR, MaterialStatusConstants.VIRTUAL,
    MaterialStatusConstants.MATERIAL_NULL];
  static readonly ERROR_STATUSES = [MaterialStatusConstants.COPYING_ERROR,
    MaterialStatusConstants.PROCESSING_ERROR, MaterialStatusConstants.PREPARE_EMISSION];

}
