<div class="center-box">
  <h2>{{dictionaryService.dictionary.userAgreement.header}}</h2>


  <form action="" method="post" class="filters" (ngSubmit)="saveUserAgreements()">
    <div class="agreement-panel mb-20">
      <div class="field-box">
        <div><input  type="checkbox" name="agreement1" [(ngModel)]="agreement1" >
          <label>
            {{dictionaryService.dictionary.userAgreement.agreement1.firstPart}}
            <a *ngIf="dictionaryService.isPolish" target="_blank" href="/regulamin">{{dictionaryService.dictionary.userAgreement.agreement1.link}}</a>
            <a *ngIf="!dictionaryService.isPolish" target="_blank" href="/regulations">{{dictionaryService.dictionary.userAgreement.agreement1.link}}</a>
            {{dictionaryService.dictionary.userAgreement.agreement1.secondPart}}
          </label>
        </div>
      </div>
      <div class="field-box">
        <div><input  type="checkbox" name="agreement2" [(ngModel)]="agreement2">
          <label>{{dictionaryService.dictionary.userAgreement.agreement2}}</label>
        </div>
      </div>
      <div class="field-box">
        <div><input  type="checkbox" name="agreement3" [(ngModel)]="agreement3">
          <label>{{dictionaryService.dictionary.userAgreement.agreement3}}</label>
        </div>
      </div>
      <div class="field-box">
        <div><input  type="checkbox" name="agreement4" [(ngModel)]="agreement4">
          <label>{{dictionaryService.dictionary.userAgreement.agreement4}}</label>
        </div>
      </div>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5" type="submit" style="float: right">
        {{dictionaryService.dictionary.userAgreement.buttonLabel}}
        <i class="material-icons">done</i>
      </button>
    </div>
  </form>

</div>
