/**
 * Created by sebastian on 09.04.17.
 */
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DictionaryService} from "../../common/dictionary-service";


@Component({
  selector: 'playlist-status-popup',
  templateUrl: './playlist-status-popup.component.html',
  styleUrls: ['./playlist-status-popup.component.css']
})
export class PlaylistStatusPopupComponent implements OnInit {

  @Input() statusDetails: String;

  constructor(protected dictionaryService: DictionaryService) {
  }

  ngOnInit(): void {
  }

  closePopup(): void {
    var dialogHtmlElement = document.getElementById('popup');
    this.fixedClosePopup(dialogHtmlElement);
    this.statusDetails = null;
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
  }

}
