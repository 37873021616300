import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CommonService} from './common-service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService extends CommonService {

  private getIsAutoRetryOnUrl = environment.BASE_API_URL + 'globalConfig/getIsAutoRetryOn';
  private setIsAutoRetryOnUrl = environment.BASE_API_URL + 'globalConfig/setIsAutoRetryOn';
  private params = new HttpParams();

  constructor(private http: HttpClient) {
    super();
  }

  getIsAutoRetryOn(): Observable<boolean> {
    return this.http.get<boolean>(this.getIsAutoRetryOnUrl, {
      params: this.params
    });
  }

  setIsAutoRetryOn(value: boolean): Observable<void> {
    return this.http.post<void>(this.setIsAutoRetryOnUrl, value, {
      params: this.params
    });
  }
}
