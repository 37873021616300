import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CommonService} from "../common/common-service";
import {Observable} from "rxjs";
import {
  GetMissingMaterialsResponseBean,
  GetPlaylistsElementsResponseBean,
  PlaylistElementFilterBean
} from "../model/generated/dtos";
import {MaterialMissingFilterBean} from "../model/generated/dtos";
import {PlaylistElementBean} from "../model/generated/dtos";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends CommonService {

  private statisticsGetUrl = environment.BASE_API_URL + 'statistics/getStatistics';
  private playlistsElementsGetUrl = environment.BASE_API_URL + 'statistics/getPlaylistsElements';
  private missingMaterialsGetUrl = environment.BASE_API_URL + 'statistics/getMissingMaterials';
  private retryMaterialsProcessingUrl = environment.BASE_API_URL + 'statistics/retryMaterialsProcessing';
  private params = new HttpParams();

  constructor(private http: HttpClient) {
    super();
  }

  getStatistics(): Observable<any> {
    return this.http.get(this.statisticsGetUrl, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  getPlaylistsElementsForUser(filter: PlaylistElementFilterBean, userId: number): Observable<GetPlaylistsElementsResponseBean> {
    if (filter == null)
      filter = {} as PlaylistElementFilterBean;
    filter.userId = userId
    return this.http.post(this.playlistsElementsGetUrl, filter, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  getMissingMaterials(filter: MaterialMissingFilterBean): Observable<GetMissingMaterialsResponseBean> {
    if (filter == null)
      filter = {} as MaterialMissingFilterBean;
    return this.http.post(this.missingMaterialsGetUrl, filter, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  retryMaterialsProcessing(retryCopying: boolean, elements: PlaylistElementBean[]): Observable<number> {
    if (elements == null)
      elements = [];
    return this.http.post(this.retryMaterialsProcessingUrl + '/' + retryCopying, elements, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }


}
