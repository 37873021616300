/**
 * Created by sebastian on 11.04.17.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";

import {Observable} from "rxjs";
import {CommonService} from "../../common/common-service";
import {GlobalUtilService} from "../../common/global-util.service";
import {environment} from "../../../environments/environment";
import {SliceRequestByExample, SliceResponse} from "../../user-list/common/paging";
import {AdminProfileDiffGenerationBean, ChannelBean, ChannelsGroupBean, FtpOptionsBean, MaterialBean, PlaylistImportBean, PlaylistImportDatesBean, PlaylistImportPrepareRequestBean, PlaylistImportPrepareResponseBean, PlaylistImportRequestBean, ProfileBean, SaveChannelBean, SaveChannelsGroupBean} from "../../model/generated/dtos";
import {ChangeMaterialRequestBean} from "../../model/technical/change-material/change-material-request-bean.model";
import {map} from "rxjs/operators";
import {newSimpleChannelBean} from "../../common/constructors/simple-channel-bean";
import {newSimpleChannelsGroupBean} from "../../common/constructors/simple-channels-group-bean";


@Injectable({
  providedIn: 'root',
})
export class PlaylistSearchService extends CommonService {

  constructor(private http: HttpClient, private globalUtilService: GlobalUtilService) {
    super();
  }

  private params = new HttpParams();
  private getAllGroupsWithChannelsUrl = environment.BASE_API_URL + 'channels/getAllGroupsWithChannels';
  private getAllGroupsUrl = environment.BASE_API_URL + 'channels/getAllGroups';
  private getUserChannelsUrl = environment.BASE_API_URL + 'channels/getUserChannels';
  private getChannelGroupsByNameUrl = environment.BASE_API_URL + 'channels/getChannelGroupsByName';
  private getChannelsByNameUrl = environment.BASE_API_URL + 'channels/getChannelsByName';
  private getChannelsByNameWithDisabledUrl = environment.BASE_API_URL + 'channels/getChannelsByNameWithDisabled';
  private getChannelsByNameAndOmitDiffGenFalseUrl = environment.BASE_API_URL + 'channels/getChannelsByNameAndOmitDiffGenFalse';
  private getUserChannelsByNameUrl = environment.BASE_API_URL + 'channels/getUserChannelsByName';
  private getChannelsByNameAndChannelGroupNameUrl = environment.BASE_API_URL + 'channels/getChannelsByNameAndChannelGroupNameUrl';
  private channelsSliceUrl = environment.BASE_API_URL + 'channels/slice';
  private channelSaveNewUrl = environment.BASE_API_URL + 'channels/saveNewChannel';
  private channelSaveEditedUrl = environment.BASE_API_URL + 'channels/saveEditedChannel';
  private channelGroupsSliceUrl = environment.BASE_API_URL + 'channels/groups/slice';
  private channelGroupSaveUrl = environment.BASE_API_URL + 'channels/groups';
  private deleteChannelUrl = environment.BASE_API_URL + 'channels/delete/';
  private deleteChannelGroupUrl = environment.BASE_API_URL + 'channels/delete/group/';
  private importPlayListUrl = environment.BASE_API_URL + 'playlists/importPlayList';
  private getProfileDiffGenerationUrl = environment.BASE_API_URL + 'profiles/getProfileDiffGeneration';
  private getAdminProfileDiffGenerationUrl = environment.BASE_API_URL + 'profiles/getAdminProfileDiffGeneration';
  private changeAdminProfileDiffGenerationUrl = environment.BASE_API_URL + 'profiles/changeAdminProfileDiffGeneration';
  private getProfilesByNameUrl = environment.BASE_API_URL + 'profiles/getProfilesByName';
  private checkDivergencesReportUrl = environment.BASE_API_URL + 'reports/checkDivergencesReport';
  private prepareImportPlayListDataUrl = environment.BASE_API_URL + 'playlists/prepareImportPlayListData';
  private getAvailableImportDatesUrl = environment.BASE_API_URL + 'users/getAvailableImportDates';
  private getMaterialByCodeUrl = environment.BASE_API_URL + 'playlists/getMaterialByCode';
  private changeMaterialUrl = environment.BASE_API_URL + 'playlists/changeMaterial';

  getAllGroupsWithChannels(): Observable<ChannelsGroupBean[]> {
    return this.http.get(this.getAllGroupsWithChannelsUrl)
      .pipe(map(this.extractData));
  }

  getAllGroups(): Observable<ChannelsGroupBean[]> {
    return this.http.get(this.getAllGroupsUrl)
      .pipe(map(this.extractData));
  }

  getUserChannels(): Observable<ChannelBean[]> {
    this.globalUtilService.blockUi(true);
    return this.http.get(this.getUserChannelsUrl)
      .pipe(map(this.extractData));
  }

  getChannelsGroupsByName(query: string, ids: string): Observable<ChannelsGroupBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('ids[]', ids);

    return this.http.get(this.getChannelGroupsByNameUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }

  getChannelsByName(query: string, ids: string): Observable<ChannelBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('ids[]', ids);
    return this.http.get(this.getChannelsByNameUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }

  getChannelsByNameWithDisabled(query: string): Observable<ChannelBean[]> {
    const params = new HttpParams()
      .set('name', query);
    return this.http.get(this.getChannelsByNameWithDisabledUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }

  getProfilesByName(query: string, ids: string): Observable<ProfileBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('ids[]', ids);
    return this.http.get(this.getProfilesByNameUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }


  getChannelsByNameAndOmitDiffGenFalse(query: string): Observable<ChannelBean[]> {
    let params = new HttpParams()
      .set('name', query);
    return this.http.get(this.getChannelsByNameAndOmitDiffGenFalseUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }

  getChannelsByNameAndChannelGroupName(query: string, channelGroupName: string): Observable<ChannelBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('chgName', channelGroupName);
    return this.http.get(this.getChannelsByNameAndChannelGroupNameUrl, {
      params: params
    })
      .pipe(map(this.extractData));
  }

  getUserChannelsByName(query: string, ids: string): Observable<ChannelBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('ids[]', ids);
    return this.http.get(this.getUserChannelsByNameUrl, {
      params: params
    }).pipe(map(this.extractData));
  }

  getSliceChannels(exampleMatchObject: ChannelBean, slice: number, size: number, sortField?: string, sortOrder?: number): Observable<SliceResponse<ChannelBean>> {
    let sliceRequest = new SliceRequestByExample(newSimpleChannelBean(exampleMatchObject), slice, size, sortField, sortOrder);
    return this.http.post(this.channelsSliceUrl, sliceRequest, {
      params: this.params
    }).pipe(map(response => {
      let sr = <SliceResponse<ChannelBean>>response;
      return new SliceResponse<ChannelBean>(sr.number, sr.size, sr.numberOfElements, sr.hasContent, sr.hasNext, sr.hasPrevious, sr.isFirst, sr.isLast, sr.content);
    }));
  }

  mapChannelBeanToSaveChannelBean(channelBean: ChannelBean): SaveChannelBean {
    const saveChannelBean = {
      id: channelBean.id,
      name: channelBean.name,
      host: channelBean.host,
      port: channelBean.port,
      url: channelBean.url,
      urlIn: channelBean.urlIn,
      username: channelBean.username,
      password: channelBean.password,
      type: channelBean.type,
      logoRelativePath: channelBean.logoRelativePath,
      omitDiffGen: channelBean.omitDiffGen,
      disabled: channelBean.disabled,
      limit: channelBean.limit,
      autoRetry: channelBean.autoRetry,
      ftpOptions: channelBean.ftpOptions,
    } as SaveChannelBean;
    if (channelBean.channelGroup) {
      saveChannelBean.channelGroupId = channelBean.channelGroup.id;
    }
    return saveChannelBean;
  }

  saveNewChannel(channelBean: ChannelBean): Observable<number> {
    return this.http.post<number>(this.channelSaveNewUrl, this.mapChannelBeanToSaveChannelBean(channelBean), {
      params: this.params
    });
  }

  saveEditedChannel(channelBean: ChannelBean): Observable<number> {
    return this.http.post<number>(this.channelSaveEditedUrl, this.mapChannelBeanToSaveChannelBean(channelBean), {
      params: this.params
    });
  }

  deleteChannel(channelBean: ChannelBean): Observable<any> {
    return this.http.post(this.deleteChannelUrl + channelBean.id, {
      params: this.params
    });
  }


  getSliceChannelGroups(exampleMatchObject: ChannelsGroupBean, slice: number, size: number, sortField?: string, sortOrder?: number): Observable<SliceResponse<ChannelsGroupBean>> {
    let sliceRequest = new SliceRequestByExample(newSimpleChannelsGroupBean(exampleMatchObject), slice, size, sortField, sortOrder);
    return this.http.post(this.channelGroupsSliceUrl, sliceRequest, {
      params: this.params
    })
      .pipe(map(response => {
        let sr = <SliceResponse<ChannelsGroupBean>>response;
        return new SliceResponse<ChannelsGroupBean>(sr.number, sr.size, sr.numberOfElements, sr.hasContent, sr.hasNext, sr.hasPrevious, sr.isFirst, sr.isLast, sr.content);
      }));
  }

  mapChannelGroupBeanToSaveChannelGroupBean(groupBean: ChannelsGroupBean): SaveChannelsGroupBean {
    let saveChannelgroupBean = {
      id: groupBean.id,
      name: groupBean.name,
      channelName: groupBean.channelName,
      workflow: groupBean.workflow,
      workflowContext: groupBean.workflowContext,
      fileExtension: groupBean.fileExtension,
      deleted: groupBean.deleted,
    } as SaveChannelsGroupBean;
    if (groupBean.channels) {
      saveChannelgroupBean.channelIds = groupBean.channels.map((channel) => {
        return channel.id;
      });
    }
    return saveChannelgroupBean;
  }

  saveChannelGroup(groupBean: ChannelsGroupBean): Observable<void> {
    return this.http.post<void>(this.channelGroupSaveUrl, this.mapChannelGroupBeanToSaveChannelGroupBean(groupBean), {
      params: this.params
    });
  }

  deleteChannelGroup(groupBean: ChannelsGroupBean): Observable<any> {
    return this.http.post(this.deleteChannelGroupUrl + groupBean.id, {
      params: this.params
    });
  }

  attachLogos(): Observable<any> {
    return this.http.post(environment.BASE_API_URL + "channels/attach/logos", {
      params: this.params
    });
  }

  mapPlaylistImportBeanToPlaylistImportRequestBean(playlistImportBean: PlaylistImportBean): PlaylistImportRequestBean {
    let playlistImportRequestBean = {
      channelName: playlistImportBean.channelName,
      channelId: playlistImportBean.channelId,
      playlistDate: playlistImportBean.playlistDate
    } as PlaylistImportRequestBean;
    return playlistImportRequestBean
  }

  importPlayList(importBean: PlaylistImportBean): Observable<PlaylistImportBean> {
    let request = this.mapPlaylistImportBeanToPlaylistImportRequestBean(importBean);
    return this.http.post<PlaylistImportBean>(this.importPlayListUrl, request);
  }


  getProfileDiffGeneration(): Observable<boolean> {
    return this.http.get<boolean>(this.getProfileDiffGenerationUrl);
  }

  getAdminProfileDiffGeneration(): Observable<AdminProfileDiffGenerationBean[]> {
    return this.http.get<AdminProfileDiffGenerationBean[]>(this.getAdminProfileDiffGenerationUrl).pipe(map(value => {
      return value;
    }));
  }

  changeAdminProfileDiffGeneration(diffGeneration: AdminProfileDiffGenerationBean[]): Observable<boolean> {
    return this.http.post<boolean>(this.changeAdminProfileDiffGenerationUrl, diffGeneration, {
      params: this.params
    }).pipe(map(value => {
      return value;
    }));
  }

  checkDivergencesReport(): Observable<any> {
    return this.http.get(this.checkDivergencesReportUrl);
  }

  getAvailableImportDates(): Observable<PlaylistImportDatesBean> {
    return this.http.get<PlaylistImportDatesBean>(this.getAvailableImportDatesUrl).pipe(map(value => {
      return value;
    }));
  }

  getPreparedDataForPlaylistImport(bean: PlaylistImportPrepareRequestBean): Observable<PlaylistImportPrepareResponseBean> {
    return this.http.post<PlaylistImportPrepareResponseBean>(this.prepareImportPlayListDataUrl, bean, {
      params: this.params
    }).pipe(map(value => {
      return value;
    }));
  }

  getMaterialByCode(code: string, externalSearch: boolean): Observable<MaterialBean> {
    let params = new HttpParams()
      .set('code', code)
      .set('externalSearch', externalSearch.toString());
    return this.http.get(this.getMaterialByCodeUrl, {
      params: params
    }).pipe(map(this.extractData));
  }

  changeMaterial(bean: ChangeMaterialRequestBean): Observable<void> {
    return this.http.post<void>(this.changeMaterialUrl, bean, {
      params: this.params
    });
  }

  getFtpOptionsDict(): Observable<FtpOptionsBean[]> {
    return this.http.get<FtpOptionsBean[]>(environment.BASE_API_URL + 'channels/getFtpOptionsDict').pipe(map(value => {
      return value;
    }));
  }

}
