import {HttpHeaders, HttpResponse} from "@angular/common/http";
import {throwError} from "rxjs";

/**
 * Created by msokolnicki on 19.01.17.
 */
export class CommonService {

  protected extractData(res: any) {
    if (res == null) {
      return res;
    }
    const body = JSON.parse(JSON.stringify(res), (key, value) => {
      if (key.toUpperCase && key.toUpperCase().indexOf('DATE') !== -1) {

        const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}[+-]\d{2}:\d{2}$/;

        const isDateNumber = dateFormat.exec(value);
        if (isDateNumber) {
          return new Date(value);
        }
      }
      return value;
    });
    return body || {};
  }

  protected getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    return headers
  }

  protected handleError(error: HttpResponse<any> | any) {
    console.error(error);
    let errMsg: string;
    if (error instanceof HttpResponse) {
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(() => errMsg);
  }


  // public static isValueEmpty(string: String): boolean {
  //   return (isNullOrUndefined(string) || string == "")
  // }

}
