<div>
  <div class="playlist-header">
    <div class="playlist-header-title">
      <h2>{{dictionaryService.dictionary.playlists.header}}</h2>
    </div>
    <div class="timer-wheel-box">
      <div #timer id="sellPerCirc" class="perCirc hover" (click)="manualDataRefresh()" [ngStyle]="timerStyle">
        <div class="perCircInner">
          <div class="perCircStat">{{timerDisplay}} {{dictionaryService.dictionary.playlists.to}} </div><div>{{dictionaryService.dictionary.playlists.refresh}}</div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
  <search-playlist #searchPlaylist (rowsData)='onNotify($event); restartTimer()' (dateEmit)="onDateNotify($event)" [user]="user" [slotsCount]="slotsCount"></search-playlist>
  <playlist-grid #playlistGrid [playlists]="playlists" [date]="date" [slotsCount]="slotsCountForGrid"></playlist-grid>
</div>
