import {CommonService} from "../common/common-service";
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {SliceRequestByExample, SliceResponse} from "../user-list/common/paging";
import {Observable} from "rxjs";
import {FtpOptionsBean, ProfileBean, SaveProfileBean} from "../model/generated/dtos";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {newSimpleProfileBean} from "../common/constructors/simple-profile-bean";

@Injectable({
  providedIn: 'root',
})
export class ProfileListService extends CommonService {
  constructor(private http: HttpClient) {
    super();
  }

  private params = new HttpParams();
  private profilesSliceUrl = environment.BASE_API_URL + 'profiles/slice';
  private profilesSaveUrl = environment.BASE_API_URL + 'profiles/';
  private profilesDeleteUrl = environment.BASE_API_URL + 'profiles/delete/';
  private profilesGetUrl = environment.BASE_API_URL + 'profiles/';
  private profilesGetByNameUrl = environment.BASE_API_URL + 'profiles/getProfilesByName';


  getSlice(exampleMatchObject: ProfileBean, slice: number, size: number, sortField?: string, sortOrder?: number): Observable<SliceResponse<ProfileBean>> {
    let sliceRequest = new SliceRequestByExample(newSimpleProfileBean(exampleMatchObject), slice, size, sortField, sortOrder);
    return this.http.post(this.profilesSliceUrl, sliceRequest, {
      params: this.params
    }).pipe(map(response => {
      let sr = <SliceResponse<ProfileBean>>response;
      return new SliceResponse<ProfileBean>(sr.number, sr.size, sr.numberOfElements, sr.hasContent, sr.hasNext, sr.hasPrevious, sr.isFirst, sr.isLast, sr.content);
    }));
  }

  mapProfileBeanToSaveProfileBean(profileBean: ProfileBean): SaveProfileBean {
    let channelIds = profileBean.channels.map((channel)=>{return channel.id;})
    let saveProfileBean = {
      id: profileBean.id,
      name: profileBean.name,
      channelIds: channelIds,
      byMail: profileBean.byMail,
      byFTP: profileBean.byFTP,
      byMaterialFTP: profileBean.byMaterialFTP,
      diffGeneration: profileBean.diffGeneration,
      tempPlaylistDays: profileBean.tempPlaylistDays,
      ftpHost: profileBean.ftpHost,
      ftpPort: profileBean.ftpPort,
      ftpUrl: profileBean.ftpUrl,
      ftpUrlIn: profileBean.ftpUrlIn,
      ftpUsername: profileBean.ftpUsername,
      ftpPassword: profileBean.ftpPassword,
      ftpType: profileBean.ftpType,
      ftpOptions: profileBean.ftpOptions,
    } as SaveProfileBean;

    if (profileBean.user){
      saveProfileBean.userId = profileBean.user.id;
    }
    if (profileBean.channel){
      saveProfileBean.channelId = profileBean.channel.id;
    }
    return saveProfileBean
  }

  saveProfile(profileBean: ProfileBean): Observable<any> {
    return this.http.post(this.profilesSaveUrl, this.mapProfileBeanToSaveProfileBean(profileBean), {
      params: this.params
    });
  }

  deleteProfile(profileBean: ProfileBean): Observable<any> {
    return this.http.get(this.profilesDeleteUrl + profileBean.id);
  }

  getProfile(id: number): Observable<ProfileBean> {
    return this.http.get(this.profilesGetUrl + id, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  getProfilesByName(query: string, ids: string): Observable<ProfileBean[]> {
    let params = new HttpParams()
      .set('name', query)
      .set('ids[]', ids);
    this.params = params;
    return this.http.get(this.profilesGetByNameUrl, {
      params: this.params
    }).pipe(map(this.extractData));
  }

  getFtpOptionsDict(): Observable<FtpOptionsBean[]> {
    return this.http.get<FtpOptionsBean[]>(environment.BASE_API_URL + 'profiles/getFtpOptionsDict').pipe(map(value => {
      return value;
    }));
  }

}
