import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of, Subject} from "rxjs";
import {CommonService} from "../common/common-service";
import {
  MailUserChannelBean,
  SaveUserBean,
  SetUserMailChannelRequestBean,
  UserBean,
  UserFilterBean
} from "../model/generated/dtos";
import {LazyLoadEvent, PageRequestByExample, PageResponse} from "./common/paging";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class UserListService extends CommonService {
  private currentUser: UserBean = environment.PROD ? null : {} as UserBean;

  constructor(private http: HttpClient) {
    super();
  }

  private params = new HttpParams();

  private userDataChange = new Subject<any>();
  userDataChange$ = this.userDataChange.asObservable();

  private usersCurrentUrl = environment.BASE_API_URL + 'users/currentUser';
  private usersSliceUrl = environment.BASE_API_URL + 'users/slice';
  private usersCreateUrl = environment.BASE_API_URL + 'users/';
  private usersDeleteUrl = environment.BASE_API_URL + 'users/';
  private usersDisableUrl = environment.BASE_API_URL + 'users/disable';
  private usersEnableUrl = environment.BASE_API_URL + 'users/enable';
  private usersAgreementsUrl = environment.BASE_API_URL + 'users/agreements';
  private getMailUserChannelUrl = environment.BASE_API_URL + 'users/getUserMailChannel';
  private getUserHourConfUrl = environment.BASE_API_URL + 'users/getUserHourConfiguration';
  private setUseMailChannelUrl = environment.BASE_API_URL + 'users/setUserMailChannel';
  private saveUserNotifyHoursUrl = environment.BASE_API_URL + 'users/saveUserNotifyHours';
  private getManagerProfileBeanUrl = environment.BASE_API_URL + 'profiles/getManagerProfileBean';

  getCurrentUser(): Observable<UserBean> {
      return this.http.get(this.usersCurrentUrl).pipe(
        map(this.extractData),
        map(response => {
          if (response)
            this.currentUser = response;
          return response;
        }));
  }

  notifyUserChange(): any {
    this.userDataChange.next('REFESH_USER');
  }

  setCurrentUser(userBean: UserBean): any {
    this.currentUser = userBean;
  }

  saveUser(userBean: SaveUserBean): Observable<any> {
    return this.http.post<UserBean>(this.usersCreateUrl, userBean, {
      params: this.params
    });
  }

  mapUserBeanToFilterBean(userBean: UserBean): UserFilterBean {
    const profilesName = userBean.profiles.map((element) => {return element.name});
    let filter =  {
      id: userBean.id,
      firstName: userBean.firstName,
      lastName: userBean.lastName,
      login: userBean.login,
      phoneNumber: userBean.phoneNumber,
      email: userBean.email,
      createDate: userBean.createDate,
      searchStartDate: userBean.searchStartDate,
      searchEndDate: userBean.searchEndDate,
      role: userBean.role,
      agreementSigned: userBean.agreementSigned,
      active: userBean.active,
      profiles: profilesName,
      profileNames: userBean.profileNames,
      name: userBean.name,
    } as UserFilterBean;

    if (userBean.channelGroup){
      filter.channelGroupId = userBean.channelGroup.id;
    }

    return filter;
  }

  getPage(exampleMatchObject: UserBean, event: LazyLoadEvent): Observable<PageResponse<UserBean>> {
    let req = new PageRequestByExample(this.mapUserBeanToFilterBean(exampleMatchObject), event);
    return this.http.post(this.usersSliceUrl, req, {
      params: this.params
    }).pipe(map(response => {
      let pr = <PageResponse<UserBean>>response;
      return new PageResponse<UserBean>(pr.totalPages, pr.totalElements, pr.content);
    }));
  }

  deleteUser(userBean: UserBean): Observable<any> {
    return this.http.delete(this.usersDeleteUrl + userBean.id);
  }

  saveUserAgreements() {
    return this.http.post(this.usersAgreementsUrl, {}, {
      params: this.params
    }).pipe(map(this.extractData));
  }

  disableUser(userBeanId: number) {
    return this.http.post(this.usersDisableUrl, userBeanId, {
      params: this.params
    });
  }

  enableUser(userBeanId: number) {
    return this.http.post(this.usersEnableUrl, userBeanId, {
      params: this.params
    });
  }

  getUserMailChannels(): Observable<MailUserChannelBean[]> {
    return this.http.get(this.getMailUserChannelUrl)
      .pipe(map(this.extractData));
  }

  getUserHourConfiguration(): Observable<any> {
    return this.http.get(this.getUserHourConfUrl)
      .pipe(map(this.extractData));
  }

  saveUserMailChannel(setUserMailChannelRequestBean: SetUserMailChannelRequestBean) {
    return this.http.post(this.setUseMailChannelUrl, setUserMailChannelRequestBean, {
      params: this.params
    });
  }

  saveUserNotifyHours(userNotifyHours: Array<string>) {
    return this.http.post(this.saveUserNotifyHoursUrl, userNotifyHours, {
      params: this.params
    });
  }

  getManagerProfileBean(): Observable<any> {
    return this.http.get(this.getManagerProfileBeanUrl)
      .pipe(map(this.extractData))
      .pipe(map(response => {
      return response;
    }));
  }
}
