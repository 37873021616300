<div *ngIf="details.status===PlaylistStatusConstants.CLOSED" (click)="goToDetails(details)" class="schedule-item status-success schedule-item-cursor-pointer">
  <div class="schedule-item-expanded"></div>
  <div class="schedule-item-date">
    Poniedziałek <span class="color-white">2017-02-18</span>
  </div>
  <div class="schedule-item-head schedule-item-label schedule-item-opacity">{{dictionaryService.dictionary.playlists.grid.materialsTotal}}: <span class="fw-700 schedule-item-number">{{details.all_materials}}</span></div>
  <div class="schedule-item-body flexbox">

    <div *ngIf="details.missing>0" class="schedule-item-missing-outern" (click)="goToDetailsWithMissingParam($event,details)">
        <span class="schedule-item-missing-inner">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</span><br>
        <span class="fw-700 schedule-item-number error-msg schedule-item-missing-inner">{{details.missing}}</span>
    </div>
    <div *ngIf="details.missing<=0" class="schedule-item-body-element schedule-item-opacity">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</div>
      <div class="fw-700 schedule-item-number">{{details.missing}}</div>
    </div>

    <div class="schedule-item-body-element schedule-item-opacity">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsVirtual}}:</div>
      <div class="fw-700 schedule-item-number">{{details.virtual}}</div>
    </div>
  </div>
  <div class="flexbox schedule-item-footer">
    <div class="item-status success-msg">
								<span class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button--icononly mdl-button-static">
									<i class="material-icons success-msg">done</i>
								</span>
      <span class="item-icon-label">{{dictionaryService.dictionary.playlists.grid.materialsReady}}</span>
    </div>
    <div class="item-version">
      <div (mouseover)="detailsActive=!detailsActive" class="mdl-button mdl-js-button mdl-button--fab mdl-button--gray mdl-button-static font-roboto">
        <div class="item-version-n" title="Aktualizowane: &#13; {{details.actualization}}">V{{details.version}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="details.status===PlaylistStatusConstants.ERROR" (click)="goToDetails(details)" class="schedule-item status-error schedule-item-cursor-pointer">
  <div class="schedule-item-expanded"></div>
  <div class="schedule-item-date">
    Wtorek <span class="color-white">2017-02-19</span>
  </div>
  <div class="schedule-item-head schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsTotal}}: <span class="fw-700 schedule-item-number">{{details.all_materials}}</span></div>
  <div class="schedule-item-body flexbox">
    <div class="schedule-item-body-element">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</div>
      <div class="fw-700 schedule-item-number error-msg">{{details.missing}}</div>
    </div>
    <div class="schedule-item-body-element">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsVirtual}}:</div>
      <div class="fw-700 schedule-item-number">{{details.virtual}}</div>
    </div>
  </div>
  <div class="flexbox schedule-item-footer">
    <div class="item-version">
      <div (mouseover)="detailsActive=!detailsActive" class="mdl-button mdl-js-button mdl-button--fab mdl-button--gray mdl-button-static font-roboto">
        <div class="item-version-n" title="Aktualizowane: &#13; {{details.actualization}}"> V{{details.version}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="details.status===PlaylistStatusConstants.TEMPORARY" (click)="goToDetails(details)" class="schedule-item status-warning schedule-item-cursor-pointer">
  <div class="schedule-item-expanded"></div>
  <div class="schedule-item-date">
    Niedziela <span class="color-white">2017-02-24</span>
  </div>
  <div class="schedule-item-head schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsTotal}}: <span class="fw-700 schedule-item-number">{{details.all_materials}}</span></div>
  <div class="schedule-item-body flexbox">

    <div *ngIf="details.missing>0" class="schedule-item-missing-outern" (click)="goToDetailsWithMissingParam($event,details)">
      <span class="schedule-item-missing-inner">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</span><br>
      <span class="fw-700 schedule-item-number error-msg schedule-item-missing-inner">{{details.missing}}</span>
    </div>
    <div *ngIf="details.missing<=0" class="schedule-item-body-element schedule-item-opacity">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</div>
      <div class="fw-700 schedule-item-number">{{details.missing}}</div>
    </div>

    <div class="schedule-item-body-element">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsVirtual}}:</div>
      <div class="fw-700 schedule-item-number">{{details.virtual}}</div>
    </div>
  </div>
  <div class="flexbox schedule-item-footer">
    <div class="item-status warning-msg">
								<span class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button-static">
									<i class="material-icons warning-msg">edit</i>
								</span>
      <span class="item-icon-label">{{dictionaryService.dictionary.playlists.grid.materialsDraft}}</span>
    </div>
    <div class="item-version">
      <div (mouseover)="detailsActive=!detailsActive" class="mdl-button mdl-js-button mdl-button--fab mdl-button--gray mdl-button-static font-roboto">
        <div class="item-version-n" title="Aktualizowane: &#13; {{details.actualization}}">V{{details.version}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="details.status===PlaylistStatusConstants.EMPTY" class="schedule-item status-empty schedule-item-cursor-pointer">
  <div class="schedule-item-date">
    Poniedziałek <span class="color-white">2017-02-18</span>
  </div>
  <div class="schedule-item-placeholder">
    {{dictionaryService.dictionary.playlists.grid.materialsEmpty}}
  </div>
</div>
<div *ngIf="details.status===PlaylistStatusConstants.IN_PROGRESS" (click)="goToDetails(details)" class="schedule-item status-inprogress schedule-item-cursor-pointer">
  <div class="schedule-item-expanded"></div>
  <div class="schedule-item-date">
    Środa <span class="color-white">2017-02-20</span>
  </div>
  <div class="schedule-item-head schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsTotal}}: <span class="fw-700 schedule-item-number ">{{details.all_materials}}</span></div>
  <div class="schedule-item-body flexbox">
    <div class="schedule-item-body-element">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsMissing}}:</div>
      <div class="fw-700 error-msg schedule-item-number">{{details.missing}}</div>
    </div>
    <div class="schedule-item-body-element">
      <div class="schedule-item-label">{{dictionaryService.dictionary.playlists.grid.materialsVirtual}}:</div>
      <div class="fw-700 schedule-item-number">{{details.virtual}}</div>
    </div>
  </div>
  <div class="flexbox schedule-item-footer">
    <div class="item-status inprogress-msg">
								<span class="mdl-button mdl-js-button mdl-button--fab mdl-button--white mdl-button-static">
									<i class="material-icons inprogress-msg">settings</i>
								</span>
      <span class="item-icon-label">{{dictionaryService.dictionary.playlists.grid.materialsPending}}</span>
    </div>
    <div class="item-version">
      <div (mouseover)="detailsActive=!detailsActive" class="mdl-button mdl-js-button mdl-button--fab mdl-button--gray mdl-button-static font-roboto">
        <div id="tvn_style_20170220" class="item-version-n">V1</div>
      </div>
    </div>
  </div>
</div>
