<dialog class="mdl-dialog" id="dialog-1">
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
  <h4 class="mdl-dialog__title">{{dictionaryService.dictionary.detailsPopup.header}}</h4>
  <a (click)="closePopup()" class="dialog-close dialog-close-icon"><span class="material-icons">close</span></a>
  <div *ngIf="materialDetails" class="mdl-dialog__content">
    <div class="mdl-tabs mdl-js-tabs">
      <div class="mdl-tabs__tab-bar">
        <a (click)="tabChange(1)" class="mdl-tabs__tab" [ngClass]="{'is-active':activeTab==1}">{{dictionaryService.dictionary.detailsPopup.previewTab}}</a>
        <a (click)="tabChange(2)" class="mdl-tabs__tab" [ngClass]="{'is-active':activeTab==2}">{{dictionaryService.dictionary.detailsPopup.infoTab}}</a>
        <div class="tab-line"></div>
      </div>
      <div *ngIf="activeTab==1" class="mdl-tabs__panel dialog-content" [ngClass]="{'is-active':activeTab==1}" id="dialog-tab1">
        <div class="file-video-box" *ngIf="materialDetails && materialDetails.materialBean.id">
          <video width="100%" controls preload='auto' [id]="materialDetails.materialBean.id" controlsList="nodownload">
            <source [src]="baseApiUrl+ 'playlists/getVideo/' + materialDetails.materialBean.md5">
          </video>
        </div>
        <div class="mdl-dialog__actions">
          <a *ngIf="materialDetails && materialDetails.materialBean.id" [href]="baseApiUrl+'playlists/downloadVideo/' + materialDetails.materialBean.md5" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5">
            {{dictionaryService.dictionary.detailsPopup.downloadButton}}
          </a>
        </div>
      </div>
      <div *ngIf="activeTab==2" class="mdl-tabs__panel dialog-content" [ngClass]="{'is-active':activeTab==2}" id="dialog-tab2">
        <div class="flexbox flex-plan">
          <div class="file-details-box flexbox">
            <div class="file-details-body">
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.spotTitle}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.title}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.spotLength}}:</small>
                <div class="file-details-value">{{materialDetails.spotLength}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.musicTitle}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.musicTitle}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.spotType}}:</small>
                <div class="file-details-value">{{dictionaryService.dictionary.playlistDetails.search.blockTypes[materialDetails.spotType]}}</div>

              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.copyrightDateBegin}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.copyrightStartDate}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.copyrightValidTo}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.copyrightEndDate}}</div>
              </div>
            </div>
          </div>
          <div class="file-details-box flexbox">
            <div class="file-details-body">
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.composer}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.composer}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.lyricsAuthor}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.authorWords}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.screenplay}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.scenario}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.polishVersionAuthor}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.authorPolishVersion}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.producer}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.producer}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.director}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.director}}</div>
              </div>
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.cinematographer}}:</small>
                <div class="file-details-value">{{materialDetails.materialBean.cinematographer}}</div>
              </div>
            </div>
          </div>
          <div class="file-details-box flexbox">
            <div class="file-details-header bg-white">
              <div class="file-details-detail">
                <small class="file-details-label">{{dictionaryService.dictionary.detailsPopup.format}}:</small>
                <div class="file-details-value">
                  <div class="file-format" [ngClass]="{'active':materialDetails.materialBean.format==='HD'}">HD</div>
                  <div class="file-format" [ngClass]="{'active':materialDetails.materialBean.format==='SD'}">SD</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</dialog>
