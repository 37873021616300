import {Injectable} from '@angular/core'
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderCounterSource = new Subject<boolean>();
  loaderCounterSource$ = this.loaderCounterSource.asObservable();

  majorLoader: boolean = false; //przy aktywnym majorLoader wygaszenie loadera jest możliwe tylko poprzez hideLoader(true)

  showLoader(activateMajor?: boolean) {
    if (activateMajor != null) {
      this.majorLoader = activateMajor;
    }
    this.loaderCounterSource.next(true);
  }

  hideLoader(deactivateMajor?: boolean) {
    if (!this.majorLoader) {
      this.loaderCounterSource.next(false);
    } else if (this.majorLoader && deactivateMajor) {
      this.loaderCounterSource.next(false);
      this.majorLoader = false;
    } else {
    }
  }

}
