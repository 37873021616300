import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../common/loader.service";
import {BaseComponent} from "../base.component";
import {DictionaryService} from "../common/dictionary-service";
import {PlaylistDetailsService} from "../playlist-details/playlist-details-table/playlist-details-table.service";
import {ChannelBean} from "../model/generated/dtos";
import {PlaylistBean} from "../model/generated/dtos";
import {PlaylistCompareBean} from "../model/generated/dtos";

/**
 * Created by sebastian on 08.05.17.
 */
@Component({
  selector: 'playlist-compare',
  templateUrl: './playlist-compare.component.html'
})
export class PlaylistCompare extends BaseComponent {

  constructor(protected route: ActivatedRoute,
              protected loaderService: LoaderService,
              protected router: Router,
              protected playlistDetailsService: PlaylistDetailsService,
              protected dictionaryService: DictionaryService,) {
    super(router, route, loaderService);
  };

  comparedPlaylist: PlaylistCompareBean[] = [];
  channelId: string;
  version1: string;
  date: string;
  channel: ChannelBean;

  selectedV1: PlaylistBean;
  selectedV2: PlaylistBean;

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      this.channelId = params['channelId'];
      this.version1 = params["version1"];
      this.date = params["date"];
    });

    this.playlistDetailsService.getChannelByPlaylistId(Number(this.version1)).subscribe(channel => {
      this.channel = channel;
    })
  }

  onNotify(comparedPlaylist: Observable<PlaylistCompareBean[]>): void {
    comparedPlaylist.subscribe(comparedPlaylist => {
      this.comparedPlaylist = comparedPlaylist;
      this.loaderService.hideLoader();
    }, error => {
      this.loaderService.hideLoader();
    });
  }

  onSelectedV1(selectedV1: PlaylistBean): void {
    this.selectedV1 = selectedV1;
  }

  onSelectedV2(selectedV2: PlaylistBean): void {
    this.selectedV2 = selectedV2;
  }
}
