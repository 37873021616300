<div>
  <h2>{{dictionaryService.dictionary.playlistDetails.header}}</h2>
  <playlist-details-search #searchBox [playlistId]="id"
                           [selectedChannel]="channel"
                           (detailsData)="onNotify($event)"
                           [playlistBean]="playlistScheDet"
                           [user]="user"
                           [statusTranslation]="statusTranslation"></playlist-details-search>
  <playlist-details-table [playlistDetails]="playlistDetails"
                          [playlistBean]="playlistScheDet"
                          (materialDetails)="onMaterialDetailsEmit($event)"
                          (statusDetails)="onStatusDetailsEmit($event)"
                          (refreshList)="onRefreshEmit($event)"
                          [channel]="channel"
                          [playlistDate]="playlistDate"
                          [user]="user"
                          [statusTranslation]="statusTranslation"
                          [initExpandAllList]="initExpandAllList"></playlist-details-table>
  <details-popup [materialDetails]="materialDetails" class="mdl-dialog"></details-popup>
  <playlist-status-popup #popup class="mdl-dialog"></playlist-status-popup>
</div>
