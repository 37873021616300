import {Component, Input, OnInit} from "@angular/core";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistCompareBean} from "../../model/generated/dtos";

/**
 * Created by sebastian on 10.05.17.
 */

@Component({
  selector: 'playlist-compare-row',
  templateUrl: './playlist-compare-row.component.html'
})
export class PlaylistCompareRow implements OnInit {

  @Input() rowCompare: PlaylistCompareBean;

  constructor(public dictionaryService: DictionaryService) {
  }

  ngOnInit(): void {
  }

  isExpanded: boolean = false;

  expandRow(): void {
  }
}
