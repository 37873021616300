<div>

  <!--WYSZUKIWANIE-->
  <form action="" method="post" class="filters" (ngSubmit)="refreshPlaylistsElements()">
    <div class="custom-panel mb-20">


      <div style="width: 12%">
        <div class="mr-5-imp field-box" *ngIf="user && user.role && (user.role == 'ADMIN' || user.role =='OPERATOR')">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.channelGroup}}
            :</label></div>
          <div class="search-groups--autocomplete disable-all filter-channel ">
            <p-autoComplete name="name" class="playlist-elements-search-pannel-input"
                            [placeholder]="dictionaryService.dictionary.statistics.playlistElements.chooseChannelGroup"
                            [(ngModel)]="searchCurrentChannelGroup"
                            [suggestions]="foundFilterChannelGroupNames"
                            appendTo="body"
                            (completeMethod)="searchFilterChannelGroups($event)">
              <ng-template let-searchCurrentChannelGroup pTemplate="item">
                <span>{{searchCurrentChannelGroup}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div class="mr-5-imp field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.channel}}:</label>
          </div>
          <div class="search-groups--autocomplete disable-all filter-channel">
            <p-autoComplete name="channel" class="playlist-elements-search-pannel-input"
                            [placeholder]="dictionaryService.dictionary.statistics.playlistElements.chooseChannel"
                            [(ngModel)]="searchCurrentChannel"
                            [suggestions]="foundFilterChannels"
                            appendTo="body"
                            (completeMethod)="searchFilterChannels($event)">
              <ng-template let-searchCurrentChannel pTemplate="item">
                <span>{{searchCurrentChannel}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </div>

      <div>
        <div class="mr-5-imp field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.playlistDate}}
            :</label></div>
          <div class="weekpicker-box playlist-element-date-input">
            <p-calendar class="field"
                        [readonlyInput]="true"
                        dateFormat="yy/mm/dd"
                        selectOtherMonths="true"
                        placeholder="{{dictionaryService.dictionary.statistics.playlistElements.fromDate}}"
                        [(ngModel)]="searchFilter.playListDateFromFilter"
                        name="fromDate"
                        appendTo="body">
            </p-calendar>
            <p-calendar class="field"
                        [readonlyInput]="true"
                        dateFormat="yy/mm/dd"
                        selectOtherMonths="true"
                        placeholder="{{dictionaryService.dictionary.statistics.playlistElements.toDate}}"
                        [(ngModel)]="searchFilter.playListDateToFilter"
                        name="toDate"
                        appendTo="body">
            </p-calendar>
          </div>
        </div>

        <div class="mr-5-imp field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.materialName}}
            :</label></div>
          <div><input class="field playlist-element-input p-inputtext" type="text" name="materialName"
                      [(ngModel)]="searchFilter.materialNameFilter"></div>
        </div>
      </div>

      <div style="width: 12%">
        <div class="mr-5-imp field-box">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.code}}:</label>
          </div>
          <div><input class="field playlist-element-input p-inputtext" type="text" name="code"
                      [(ngModel)]="searchFilter.codeFilter"></div>
        </div>

        <div class="mr-5-imp field-box">
          <div><label class="field-label"> &nbsp; </label></div>
          <button
            class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-element-input"
            type="submit">
            {{dictionaryService.dictionary.statistics.playlistElements.filter}}
            <i class="material-icons">tune</i>
          </button>
        </div>
      </div>

      <div style="width: 15%">
        <div class="flex-end ">
          <div class="mr-5-imp field-box clearfix">
            <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.materialStatus}}
              :</label></div>
            <div>
              <p-dropdown
                [options]="materialStatusesForInput"
                [(ngModel)]="searchFilter.statusFilter"
                optionLabel="label"
                optionValue="value"
                [style]="{'height':'29px', 'width':'100%'}"
                [styleClass]="'custom-dropdown'"
              >
              </p-dropdown>
            </div>
          </div>
        </div>

      </div>

      <div class="playlist-elements-filter-settings-box" style="width: 15%;">

        <div class="mr-5-imp field-box clearfix">
          <div><label class="field-label">{{dictionaryService.dictionary.statistics.playlistElements.pageSize}}:</label>
          </div>
          <div>
            <div tabindex="0" (click)="pageSizeOpen=!pageSizeOpen" (blur)="pageSizeOpen = false"
                 class="nice-select playlist-element-input playlist-elements-page-size-list"
                 [ngClass]="{'open':pageSizeOpen}">
              <span class="current active-input-color">{{searchFilter.pageSize}}</span>
              <ul class="list margin-0">
                <ng-container>
                  <li *ngFor="let i of pageSizes" class="option" (click)="changePageSize(i)">{{i}}</li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

        <div class="mr-5-imp field-box">
          <div><label class="field-label"> &nbsp;</label></div>
          <button type="button"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-button-border  playlist-element-search-pannel-button playlist-element-input"
                  (click)="clearFiltersAndGetElements()">
            {{dictionaryService.dictionary.statistics.playlistElements.cleanFilters}}
          </button>
        </div>

      </div>

      <div style="width: 7%; border-right: 1px solid black; text-align: center; padding-top: 25px;">
        {{dictionaryService.dictionary.statistics.playlistElements.filterMatchingCount["1"]}} <br>
        {{dictionaryService.dictionary.statistics.playlistElements.filterMatchingCount["2"]}} <br>
        <b style="font-size: 15px">{{searchFilter.resultCount}}</b>
      </div>

      <div class="playlist-elements-action-btn-container">
        <div class="playlist-elements-action-btn-container-inner">
          <div *ngIf="checkboxEnabled" style="padding-bottom: 5px">
            <button type="button"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-elements-action-btn"
                    (click)="switchCheckAll()">
              <span *ngIf="!isAllChecked">
                {{dictionaryService.dictionary.statistics.playlistElements.checkAll}}</span>
              <span *ngIf="isAllChecked">{{dictionaryService.dictionary.statistics.playlistElements.unCheckAll}}</span>
            </button>
          </div>

          <div *ngIf="checkboxEnabled" style="padding-bottom: 5px">
            <button type="button"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-elements-action-btn"
                    (click)="retrySelected(false)">
              {{dictionaryService.dictionary.statistics.playlistElements.retry}}
              <i class="material-icons">autorenew</i>
            </button>
          </div>

          <div *ngIf="checkboxEnabled && retryCopyEnabled">
            <button type="button"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button  playlist-element-search-pannel-button playlist-elements-action-btn"
                    (click)="retrySelected(true)">
              {{dictionaryService.dictionary.statistics.playlistElements.retryCopy}}
              <i class="material-icons changed-msg">replay</i>
            </button>
          </div>
        </div>
      </div>


    </div>
  </form>

  <!--TABELA-->
  <div>
    <div class="table-responsive-vertical shadow-z-1">
      <table id="table" class="table table-hover table-mc-light-blue stats-table">
        <thead>
        <tr>
          <th *ngFor="let header of headersView; let i = index"
              [ngClass]="{'column-header-hover' : header.clickable,'hidden' : header.hidden,'width-auto' : header.propertyName == 'materialName'}"
              [style.width.%]="header.width"
              style="min-height: 69px; vertical-align: middle">
            <div (click)="toggleArrow(i, header)" class="column-header-content">
              <span [ngClass]="{'margin-center': header.center}">{{header.name}}</span>
              <sort-arrow *ngIf="header.selected" [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="playlistElements.length <= 0">
          <td colspan="9" class="no-results-found">{{dictionaryService.dictionary.statistics.noResults}} <i
            class="material-icons">do_not_disturb</i></td>
        </tr>
        <ng-container *ngIf="playlistElements.length > 0">
          <tr *ngFor="let element of playlistElements; let i = index" [attr.data-index]="i">
            <td class="stats-table-cell"
                [style.width.%]="headersBase[0].width"
                style="padding-left: 1.2rem; padding-right: 1.2rem;">{{element.code}}</td>
            <td class="stats-table-cell"
                style="width:auto">{{element.materialName}}</td>
            <td class="stats-table-cell"
                [style.width.%]="headersBase[2].width">{{element.channelGroupName}} </td>
            <td class="stats-table-cell"
                [style.width.%]="headersBase[3].width">{{element.channelName}}</td>
            <td class="stats-table-cell"
                [style.width.%]="headersBase[4].width">{{element.playListDate  | date: 'yyyy/MM/dd'}}</td>
            <td class="stats-table-cell"
                [style.width.%]="headersBase[5].width">{{dictionaryService.dictionary.statistics.statuses[element.status]}}</td>
            <td class="stats-table-cell"
                [ngClass]="{'hidden' : headersView[materialStatusDetailsColumnIndex].hidden}"
                [style.width.%]="headersBase[6].width">
              <span *ngIf="element.statusDetails == null"></span>
              <span
                *ngIf="element.statusDetails && !expanded[i]">{{element.statusDetails.slice(0, materialStatusDetailsTextLength)}}</span>
              <span
                *ngIf="element.statusDetails && !expanded[i] && element.statusDetails.length > materialStatusDetailsTextLength"
                class="expand-button"
                (click)="expandShrink(i)">{{dictionaryService.dictionary.statistics.expand}}</span>
              <span
                *ngIf="element.statusDetails && expanded[i] && element.statusDetails.length > materialStatusDetailsTextLength">{{element.statusDetails}}</span>
              <span
                *ngIf="element.statusDetails && expanded[i] && element.statusDetails.length > materialStatusDetailsTextLength"
                class="expand-button"
                (click)="expandShrink(i)">{{dictionaryService.dictionary.statistics.shrink}}</span>
            </td>
            <td class="stats-table-cell"
                [style.width.%]="headersBase[7].width">{{element.statusDate.toLocaleDateString("pl-PL", dateOptions)}}</td>
            <td *ngIf="checkboxEnabled" class="stats-table-cell"
                [style.width.%]="headersBase[8].width">
              <div class="field-box-checkbox margin-center">
                <div><label
                  class="field-label"></label></div>
                <div style="text-align: center">
                  <input class="playlist-element-checkbox" type="checkbox" [(ngModel)]="element.isChecked">
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div class="bottom-button-wrapper">
        <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" [disabled]="!showMore"
                (click)="nextPage()" type="button">
          {{dictionaryService.dictionary.statistics.showMore}}
        </button>
      </div>
    </div>
  </div>


</div>
