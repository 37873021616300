import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictionaryService} from '../common/dictionary-service';
import {BaseComponent} from '../base.component';
import {LoaderService} from '../common/loader.service';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'lang-panel',
  templateUrl: './lang-panel.component.html',
  styleUrls: ['./lang-panel.component.css']
})
export class LangPanelComponent extends BaseComponent {

  public items: MenuItem[];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected loaderService: LoaderService,
              public dictionaryService: DictionaryService) {
    super(router, route, loaderService);
  }

  ngOnInit(): void {
    this.items = [
      {
        styleClass: 'custom-icon custom-icon-en',
        command: () => {
          this.changeLang(false);
        },
      },
      {
        styleClass: 'custom-icon custom-icon-pl',
        command: () => {
          this.changeLang(true);
        },
      }
    ];
  }

  changeLang(isPolish: boolean) {
    this.dictionaryService.isPolish = isPolish;
    sessionStorage.setItem('isPolish', String(this.dictionaryService.isPolish));
    location.reload();
  }
}
