import {Component, OnInit} from '@angular/core';
import {UserBean} from "./model/generated/dtos";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "./common/loader.service";

@Component({
  template: ''
})
export class BaseComponent implements OnInit {
  protected user: UserBean;

  constructor(protected router: Router, protected route: ActivatedRoute, protected loaderService: LoaderService) {}

  ngOnInit(): void {
    this.user = this.route.snapshot.data['user'];
    // if(environment.PROD && this.user){
    //   if(!this.user.agreementSigned)
    //     this.router.navigate(['./user/agreement']);
    //   if(!this.user.active)
    //     this.router.navigate(['./blocked']);
    //   this.loaderService.hideLoader();
    // }
  }
}
