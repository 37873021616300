import {Component, OnInit} from "@angular/core";
import {PlaylistSearchService} from "../schedule/search-playlist/search-playlist.service";
import {UserListService} from "../user-list/user-list.service";
import {DictionaryService} from "../common/dictionary-service";
import {SelectItem} from "primeng/api";
import {AdminProfileDiffGenerationBean, ChannelBean, MailUserChannelBean, SetUserMailChannelRequestBean, UserBean} from "../model/generated/dtos";
import {Observable} from "rxjs";
import {CommonMessageService} from "../common/common-message-service";

@Component({
  selector: 'notifications-configuration',
  styleUrls: ['./notifications-configuration.component.css'],
  templateUrl: './notifications-configuration.component.html'
})
export class NotificationsConfigurationComponent implements OnInit {

  //ZALOGOWANY
  user: UserBean;
  //KANAŁY - PAGING
  searchRequestBean: ChannelBean = {} as ChannelBean;
  inited = false;
  hasNextPage = false;
  page = 0;
  pageSize = 10;
  sortField = 'name';
  sortOrder = 1;
  mailUserChannelsList: MailUserChannelBean[] = Array<MailUserChannelBean>();
  userNotifHoursList: Array<string> = Array<string>();
  deleteNotifyUserHour: string = "";
  dailyHours: Array<string> = Array<string>();
  dailyHoursItems: SelectItem[] = [];
  selectedHour: any;
  formBean: string = "";
  userMailChannelHourOpen: boolean = false;
  isAdmin: boolean = false;
  adminProfileDiffGeneration: AdminProfileDiffGenerationBean[] = [];

  //TABLE
  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.tableHeaders.channelNameLabel,
      propertyName: 'name',
      selected: true,
      asc: true,
      clickable: true,
    },
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.tableHeaders.sendLabel,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  //FREQUENCY CONFIGURATION TABLE
  aggregatedHeaders: any[] = [
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.tableHeaders.hourLabel,
      propertyName: 'name',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.tableHeaders.actionLabel,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  //DIFFERENCES REPORTS CONFIGURATION TABLE
  diffReportsConfigHeaders: any[] = [
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.diffReportsConfigtableHeaders.profileLabel,
      propertyName: 'profile',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.notificationsConfiguration.diffReportsConfigtableHeaders.sendReportsLabel,
      propertyName: 'sendReports',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  constructor(private playlistSearchService: PlaylistSearchService,
              private userListService: UserListService,
              private messageService: CommonMessageService,
              public dictionaryService: DictionaryService) {

  }

  ngOnInit(): void {
    this.loadUserHourConfiguration();
    this.loadHourConfigurationOptions();
    this.userListService.getCurrentUser().subscribe(res => {
      this.user = res;
      if (this.user != null && this.user.role != null && (this.user.role == 'MANAGER' || this.user.role == 'USER'))
        this.loadMailUserChannelConfiguration();
      if (this.user != null && this.user.role != null && (this.user.role == 'ADMIN')) {
        this.isAdmin = true;
        this.loadAdminProfileDiffReportGeneration();
      }

    });

  }

  loadMailUserChannelConfiguration() {
    this.userListService.getUserMailChannels().subscribe(
      res => {
        this.mailUserChannelsList = res;
      }, error => {
        console.error(error);
      }
    );
  }

  loadUserHourConfiguration() {
    this.userListService.getUserHourConfiguration().subscribe(
      res => {
        this.userNotifHoursList = res;
        this.sortUserNotifyHoursList(1);
      }
    );
  }

  sortUserNotifyHoursList(sort: number) {
    let asc: boolean = true;
    (sort == 0) ? asc = false : null;
    let hoursNumber: Array<number> = new Array();
    for (let i of this.userNotifHoursList) {
      hoursNumber.push(Number(i))
    }

    if (asc) {
      hoursNumber = hoursNumber.sort((n1, n2) => {
        if (n1 > n2) return 1;
        else if (n1 < n2) return -1;
        else return 0;
      });
    } else {
      hoursNumber = hoursNumber.sort((n1, n2) => {
        if (n1 > n2) return -1;
        else if (n1 < n2) return 1;
        else return 0;
      });
    }

    let hoursString: Array<string> = new Array();
    for (let i of hoursNumber) {
      hoursString.push(String(i));
    }

    this.userNotifHoursList = hoursString;
  }

  loadHourConfigurationOptions() {
    for (let i = 0; i < 24; i++) {
      this.dailyHours.push(i.toString());
      let itemLabel = i + ":00";
      this.dailyHoursItems.push({label: itemLabel, value: i});
    }
  }

  addUserHourConfiguration(newBean: any) {
    if (this.validateHour(newBean)) {
      this.userNotifHoursList.push(newBean.toString());
      this.sortUserNotifyHoursList(this.sortOrder);
      this.userListService.saveUserNotifyHours(this.userNotifHoursList).subscribe(
        res => {
          this.formBean = "";
          this.selectedHour = null;
          this.messageService.success(this.dictionaryService.dictionary.notificationsConfiguration.messages.changesSaved);
          this.closePopup();
        }, error => {

        }
      );
    }
  }

  deleteUserHourConfiguration(deleteBean: string) {
    let deleteIndex = this.userNotifHoursList.indexOf(deleteBean);
    if (deleteIndex > -1) {
      this.userNotifHoursList.splice(deleteIndex, 1);

    }
    this.userListService.saveUserNotifyHours(this.userNotifHoursList).subscribe(
      res => {
        this.messageService.success(this.dictionaryService.dictionary.notificationsConfiguration.messages.changesSaved);
        this.closeConfirmPopup('delete-user-notify-hour-dialog');
      }, error => {
        console.error(error);
      }
    );

  }

  setMailUserChannelConfiguration(conf: MailUserChannelBean) {
    let request = {
      id: conf.id,
      channelId: conf.channel.id,
      userId: conf.user?.id,
      sent: conf.sent
    } as SetUserMailChannelRequestBean;

    this.userListService.saveUserMailChannel(request).subscribe(
      res => {
        this.messageService.success(this.dictionaryService.dictionary.notificationsConfiguration.messages.channelConfigurationSavedFirstPart
          + conf.channel.name + this.dictionaryService.dictionary.notificationsConfiguration.messages.channelConfigurationSavedSecondPart);
      }, () => {
        conf.sent = !conf.sent;
      }
    );
  }

//POPUPS
  fixedOpenPopup(dialogHtmlElement: any) {
    let topOffset = window.pageYOffset + 100 + 'px';
    dialogHtmlElement.style.top = topOffset;
    dialogHtmlElement.setAttribute("open", "open");
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
  }

  openAddHourPopup() {
    var outerHtmlElement: any = document.getElementById('user-mail-channel-form-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  chooseHour(value: string) {
    this.formBean = value;
  }

  resetHour() {
    this.formBean = "";
  }

  validateHour(value: any): boolean {
    let result = true;
    if (value == null) {
      this.messageService.error(this.dictionaryService.dictionary.notificationsConfiguration.messages.timeNotChosenError);
      result = false;
    } else {
      result = this.validateUniqueHour(value);
      if (!result) {
        this.messageService.error(this.dictionaryService.dictionary.notificationsConfiguration.messages.timeDuplicateError);
      }
    }
    return result;
  }

  validateUniqueHour(value: any): boolean {
    let result = true;
    let existing = this.userNotifHoursList.map(h => h.toLowerCase());
    let repeated = existing.filter(ch => ch == value.toString());
    return repeated.length == 0;
  }


  openConfirmHourDeletePopup(hour: string): void {
    this.deleteNotifyUserHour = hour;
    var outerHtmlElement: any = document.getElementById('delete-user-notify-hour-dialog');
    this.fixedOpenPopup(outerHtmlElement);
  }

  closeConfirmPopup(elementId: string): void {
    elementId = elementId || 'delete-user-notify-hour-dialog';
    var outerHtmlElement: any = document.getElementById(elementId);
    this.fixedClosePopup(outerHtmlElement);
  }

  closePopup(): void {
    this.selectedHour = null;
    var outerHtmlElement: any = document.getElementById('user-mail-channel-form-dialog');
    this.fixedClosePopup(outerHtmlElement);
  }

  requestSorting(header: any, aggregated: boolean): void {
    if (header.clickable) {
      this.sortField = header.propertyName;
      this.sortOrder = 0;
      if (header.asc) {
        this.sortOrder = 1;
      }

      if (aggregated) {
        this.sortUserNotifyHoursList(this.sortOrder);
      } else {
        this.sortUserMailChannel(this.sortOrder);
      }

    }
  }

  sortUserMailChannel(order: number) {
    if (order == 1) {
      this.mailUserChannelsList.sort((obj1, obj2) => {
        return obj1.channel.name.localeCompare(obj2.channel.name);
      });
    } else {
      this.mailUserChannelsList.sort((obj1, obj2) => {
        return obj1.channel.name.localeCompare(obj2.channel.name);
      }).reverse();
    }
  }

  toggleArrow(index: number, header: any, aggregated: boolean): void {

    if (!aggregated) {
      if (this.headers[index].clickable) {
        if (this.headers[index].selected) {
          this.headers[index].asc = !this.headers[index].asc;
        } else {
          this.headers.forEach(header => header.selected = false);
          this.headers[index].selected = true;
        }
        this.requestSorting(header, aggregated);
      }
    } else {
      if (this.aggregatedHeaders[index].clickable) {
        if (this.aggregatedHeaders[index].selected) {
          this.aggregatedHeaders[index].asc = !this.aggregatedHeaders[index].asc;
        } else {
          this.aggregatedHeaders.forEach(header => header.selected = false);
          this.aggregatedHeaders[index].selected = true;
        }
        this.requestSorting(header, aggregated);
      }
    }

  }

  loadAdminProfileDiffReportGeneration() {
    this.playlistSearchService.getAdminProfileDiffGeneration().subscribe(
      res => {
        this.adminProfileDiffGeneration = res;
      }, error => {
        console.error(error);
      }
    );

  }

  diffReportGenerationCheckAll() {
    this.adminProfileDiffGeneration.forEach(a => a.generateDiffReport = true);
    this.multipleDiffReportGenerationChange(this.adminProfileDiffGeneration).subscribe(
      res => {
      }, error => {
        console.error(error);
      }
    )
  }

  diffReportGenerationUncheckAll() {
    this.adminProfileDiffGeneration.forEach(a => a.generateDiffReport = false);
    this.multipleDiffReportGenerationChange(this.adminProfileDiffGeneration).subscribe(
      res => {
      }, error => {
        console.error(error);
      }
    )
  }

  diffReportGenerationChange(adminProfileDiffGeneration: AdminProfileDiffGenerationBean) {
    return this.multipleDiffReportGenerationChange([adminProfileDiffGeneration]).subscribe(
      res => {
      }, error => {
        console.error(error);
      }
    )
  }

  private multipleDiffReportGenerationChange(adminProfileDiffGenerations: AdminProfileDiffGenerationBean[]): Observable<boolean> {
    return this.playlistSearchService.changeAdminProfileDiffGeneration(adminProfileDiffGenerations);
  }


}
