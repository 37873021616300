import {Component, OnInit} from "@angular/core";
import {LoaderService} from "../common/loader.service";
import {DictionaryService} from "../common/dictionary-service";

@Component({
  selector: 'authorisationWarning',
  templateUrl: './authorisationWarning.component.html'
})
export class UnauthorisedWarningComponent implements OnInit {

  constructor(protected loaderService: LoaderService,
              protected dictionaryService: DictionaryService) {
  };

  ngOnInit(): void {
    this.loaderService.hideLoader();
  }
}
