<!--LOADER-->
<style>
  .statistics-loader-icon {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid rgb(39, 94, 171); /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
<!------------------>
<div class="custom-panel flex-end mb-25 ">
  <div style="min-height: 45px" class="stats-panel">
    <div class="stats-panel-left-part">
      <div *ngIf="isLoading" style="margin-left: 59%; margin-top: 10px">
        <div class="statistics-loader-icon"></div>
      </div>

      <div *ngIf="!isLoading">
        <!--KOL 1-->
        <div class="stats-panel-col">
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.SENT_TO_BROADCASTER]}}: {{statistics.sent}}</div>
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.MATERIAL_NULL]}}: {{statistics.materialNull}}</div>
        </div>

        <!--KOL 2-->
        <div class="stats-panel-col">
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.PREPARE_EMISSION]}}: {{statistics.inPreparation}}</div>
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.FILE_NOT_EXIST]}}: {{statistics.noMaterial}}</div>
        </div>

        <!--KOL 3-->
        <div class="stats-panel-col">
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.SENDING_TO_BROADCASTER]}}: {{statistics.inSending}}</div>
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.PROCESSING_ERROR]}}: {{statistics.processingError}}</div>
        </div>

        <!--KOL 4-->
        <div class="stats-panel-col">
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.VIRTUAL]}}: {{statistics.virtual}}</div>
          <div class="stats-panel-cell">{{dictionaryService.dictionary.statistics.statuses[MaterialStatusConstants.COPYING_ERROR]}}: {{statistics.sendingError}}</div>
        </div>
      </div>

    </div>
    <div class="stats-panel-right-part">
      <button (click)="getStatistics()" style="float: right; height: 29px" class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10">
        {{dictionaryService.dictionary.statistics.refreshStatistics}}
        <i style="height: 29px" class="material-icons changed-msg">refresh</i>
      </button>
    </div>
  </div>
</div>
