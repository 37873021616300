import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router,
  ) {
  }

  toCasLoginPage(): void {
    window.location.href = '/api/unauthorized/casLogin';
  }

  toForbiddenPage(): void {
    this.router.navigate(['/unauthorised']).then();
  }
}
