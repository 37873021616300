<div>
  <h2>{{ dictionaryService.dictionary.channelList.header }}</h2>
</div>

<dialog class="mdl-dialog" id="channel-form-dialog" style="width: 512px;">
  <div class="popup-background-blocker"></div>
  <div class="mdl-dialog__content">
    <div class="channel-card-wide mdl-card mdl-shadow--2dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text" *ngIf="!currentChannelName">
          {{ dictionaryService.dictionary.channelList.dialog.header }}</h2>
        <h2 class="mdl-card__title-text" *ngIf="currentChannelName">{{ currentChannelName }}</h2>
      </div>
      <form #profileForm="ngForm" action="" method="post" class="filters" (ngSubmit)="saveChannel()">
        <div class="mdl-card__supporting-text "
             style="    margin-left: auto;    margin-right: auto;    margin-top: 20px;">
          <div class=" flex-end mb-20">

            <div class="field-box clearfix" style="margin-bottom: 12px">
              <div><label
                class="field-label">{{ dictionaryService.dictionary.channelList.dialog.channelGroupInputLabel }}</label>
              </div>
              <div class="edit-channel-group-select">
                <p-autoComplete name="name" [size]="35"
                                [placeholder]="dictionaryService.dictionary.channelList.form.channelGroupInputPlaceholder"
                                [(ngModel)]="searchModalCurrentChannelGroup"
                                [suggestions]="foundModalFilterChannelGroups"
                                field="name"
                                appendTo="body"
                                (completeMethod)="searchModalFilterChannelGroups($event)"
                                [ngModelOptions]="{standalone: true}"
                                (onSelect)="chooseGroup($event)">
                  <ng-template let-searchModalCurrentChannelGroup pTemplate="item">
                    <span>{{ searchModalCurrentChannelGroup.name }}</span>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
            <div class="field-box" style="margin-bottom: 12px;">
              <div><label class="field-label">{{ dictionaryService.dictionary.channelList.dialog.idInputLabel }}</label>
              </div>
              <div>
                <input class="field" [disabled]="isEditForm" type="number" min="0" max="999999999" name="id"
                       style="  width: 100%;" [(ngModel)]="formBean.id">
              </div>
            </div>

            <div class="field-box" style="margin-bottom: 12px;">
              <div class="checkbox-single-box">
                <p-checkbox name="disabled"
                            label="{{dictionaryService.dictionary.channelList.dialog.disabled}}"
                            [(ngModel)]="formBean.disabled"
                            binary="true">
                </p-checkbox>
              </div>
            </div>

            <div class="field-box" style="margin-bottom: 12px;">
              <div><label
                class="field-label">{{ dictionaryService.dictionary.channelList.dialog.nameInputLabel }}</label>
              </div>
              <div><input class="field " type="text" name="name" style="  width: 100%;" [(ngModel)]="formBean.name">
              </div>
            </div>
            <div class="field-box" style="margin-bottom: 12px;">
              <div><label
                class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpDestination }}</label>
              </div>
              <div><input class="field" type="text" name="urlIn" style="  width: 100%;"
                          [(ngModel)]="formBean.urlIn"></div>
            </div>
            <div class="field-box" *ngIf="true">
              <p-accordion (onOpen)="onLogoOpen($event)" (onClose)="onLogoClose($event)">
                <p-accordionTab [selected]="isLogoActive" (click)="isLogoActive=true">
                  <p-header (click)="isLogoActive=false">
                    {{ dictionaryService.dictionary.channelList.dialog.channelLogoHeader }}
                  </p-header>
                  <attach-box #logoBox
                              [multiple]="false"
                              [title]="dictionaryService.dictionary.channelList.dialog.addLogoLabel"></attach-box>
                  <div *ngIf="logoUploader">
                    <div *ngFor="let item of logoUploader.queue; let ix=index" class="attach-preview">
                      <div class="attach-preview--img" *ngIf="ix==logoUploader.queue.length-1">
                        <img src="" imgPreview [image]="item?._file"/>
                      </div>
                      <div class="attach-preview--title" *ngIf="ix==logoUploader.queue.length-1">
                        <p>
                          <span
                            style="color: #2f2f2f">{{ dictionaryService.dictionary.channelList.dialog.chosenFileLabel }}
                            :  {{ item?.file?.name }}</span>
                          <i class="material-icons" (click)="deleteLogo()">close</i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="formBean.logoRelativePath && logoUploader.queue.length==0">
                    <div class="attach-preview">
                      <div class="attach-preview--img">
                        <img [src]="baseApiUrl+'channels/logo/'+formBean.id+'?'+logoTimestamp">
                      </div>
                      <div class="attach-preview--title">
                        <p>
                          <span
                            style="color: #2f2f2f">{{ dictionaryService.dictionary.channelList.dialog.fileNameLabel }}
                            :  {{ formBean.logoRelativePath }}</span>
                          <i class="material-icons" (click)="deleteExistedLogo()">close</i>
                        </p>
                      </div>
                    </div>
                  </div>


                </p-accordionTab>
              </p-accordion>
            </div>
            <div class="field-box">
              <p-accordion>
                <p-accordionTab [selected]="isFtpSectionActive" (click)="isFtpSectionActive=true">
                  <p-header
                    (click)="isFtpSectionActive=false; isPasswordChangeTabActive=false; isFtpOptionActive=false">
                    {{ dictionaryService.dictionary.channelList.dialog.ftpConfigurationHeader }}
                  </p-header>
                  <div class="field-box clearfix">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.fileProtocolLabel }}</label>
                    </div>
                    <div>
                      <div (click)="ftpTypeOpen=!ftpTypeOpen" class="nice-select"
                           [ngClass]="{'open':ftpTypeOpen, 'disabled': disabledFTP}" style=" width: 100%">
                        <span *ngIf="formBean.type" class="current">{{ ftpTypeMap.get(formBean.type) }}</span>
                        <span *ngIf="!formBean.type"
                              class="current">{{ dictionaryService.dictionary.channelList.dialog.fileProtocolPlaceholder }}</span>
                        <ul class="list">
                          <ng-container>
                            <li class="option" (click)="resetFtpType()">
                              {{ dictionaryService.dictionary.channelList.dialog.fileProtocolNone }}
                            </li>
                            <li *ngFor="let ftpTypeKey of ftpTypeKeys" (click)="chooseFtpType(ftpTypeKey);"
                                class="option">
                              {{ ftpTypeMap.get(ftpTypeKey) }}
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="field-box">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpHost }}</label>
                    </div>
                    <div><input class="field" type="text" name="host" style="  width: 100%;"
                                [(ngModel)]="formBean.host"></div>
                  </div>
                  <div class="field-box">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpPort }}</label>
                    </div>
                    <div><input class="field" #port="ngModel" type="text" name="port" style="  width: 100%;"
                                [(ngModel)]="formBean.port" pattern="[0-9]*"></div>
                    <div class="error-msg mt-5" *ngIf="(!port.valid && !port.pristine) || formFtpPortError">
                      {{ dictionaryService.dictionary.channelList.dialog.ftpPortError }}
                    </div>
                  </div>

                  <div class="field-box">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpUser }}</label>
                    </div>
                    <div><input class="field" type="text" name="username" style="  width: 100%;"
                                [(ngModel)]="formBean.username"></div>
                  </div>
                  <div class="field-box">
                    <div class="checkbox-single-box">
                      <p-checkbox name="diffGeneration"
                                  label="{{dictionaryService.dictionary.channelList.dialog.diffGenOmit}}"
                                  [(ngModel)]="formBean.omitDiffGen"
                                  (ngModelChange)="formBean.autoRetry = false"
                                  binary="true">
                      </p-checkbox>
                    </div>
                  </div>

                  <div class="field-box">
                    <div class="checkbox-single-box">
                      <p-checkbox name="autoRetry"
                                  label="{{dictionaryService.dictionary.channelList.dialog.autoRetry}}"
                                  [disabled]="formBean.omitDiffGen"
                                  [(ngModel)]="formBean.autoRetry"
                                  binary="true">
                      </p-checkbox>
                    </div>
                  </div>
                  <!-- password -->
                  <div class="field-box" *ngIf="!isPasswordExisted && !isKeysAuthentication(formBean.type)">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpPassword }}</label>
                    </div>
                    <div><input class="field" type="password" name="password"
                                style="  width: 100%;" [(ngModel)]="formBean.password"></div>
                  </div>
                  <div style="width: 96%; margin-bottom: 12px;" *ngIf="!isKeysAuthentication(formBean.type)">
                    <p-accordion *ngIf="isPasswordExisted">
                      <p-accordionTab [selected]="isPasswordChangeTabActive" (click)="isPasswordChangeTabActive=true">
                        <p-header (click)="isPasswordChangeTabActive=false">

                          <span class="channel-change-pass-info">
                            [
                            {{ dictionaryService.dictionary.channelList.dialog.ftpPasswordState }}:
                            <span *ngIf="isPasswordChangeReq"
                                  style="font-weight: bold; color: red"> {{ dictionaryService.dictionary.channelList.dialog.ftpPasswordStateChange }}</span>
                            <span *ngIf="!isPasswordChangeReq"
                                  style="font-weight: bold"> {{ dictionaryService.dictionary.channelList.dialog.ftpPasswordStateNotChange }}</span>
                            ]
                          </span>
                          {{ dictionaryService.dictionary.channelList.dialog.ftpPasswordChange }}

                        </p-header>
                        <div class="field-box">
                          <div><label
                            class="field-label">{{ dictionaryService.dictionary.channelList.dialog.newFtpPassword }}</label>
                          </div>
                          <div><input class="field" type="password" name="newPassword" style="  width: 100%;"
                                      autocomplete="off" [(ngModel)]="newPassword" (keyup)="detectPasswordChange()"
                                      (change)="detectPasswordChange()"></div>
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                  </div>
                  <div style="width: 96%; margin-bottom: 12px;"
                       *ngIf="!isASCP(formBean.type) && !!ftpOptionsDict && ftpOptionsDict.length > 0">
                    <p-accordion>
                      <p-accordionTab [selected]="isFtpOptionActive" (click)="isFtpOptionActive=true">
                        <p-header (click)="isFtpOptionActive=false">
                          {{ dictionaryService.dictionary.channelList.dialog.ftpOptions }}
                        </p-header>
                        <div *ngFor="let ftpOption of ftpOptionsDict; let i = index">
                          <div class="checkbox-single-box">
                            <p-checkbox
                              name="{{ftpOption.code}}"
                              label="{{ftpOption.value}}"
                              [(ngModel)]="ftpOptionsDict[i].checked"
                              binary="true">
                            </p-checkbox>
                          </div>
                          <div class="checkbox-single-description-box">
                            <span>
                              <strong>
                                {{ dictionaryService.dictionary.channelList.dialog.ftpOptionsDescription[ftpOption.code] }}
                              </strong>
                            </span>
                          </div>
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                  </div>

                  <div style="white-space: normal" *ngIf="isOwnKeysAuthentication(formBean.type)">
                    <a [href]="baseApiUrl+'channels/downloadPublicKey'" target="_blank">
                      <button
                        class="channel-popup-footer-button con-btn mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                        type="button">
                        {{ dictionaryService.dictionary.channelList.dialog.downloadPublicKey }}
                      </button>
                    </a>
                  </div>


                  <div class="field-box">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.ftpUrl }}</label>
                    </div>
                    <div><input class="field" type="text" name="url" style="  width: 100%;"
                                [(ngModel)]="formBean.url"></div>
                  </div>

                  <div class="field-box">
                    <div><label
                      class="field-label">{{ dictionaryService.dictionary.channelList.dialog.limit }}</label>
                    </div>
                    <div><input class="field" type="number" name="limit" style="  width: 100%;"
                                [(ngModel)]="formBean.limit"></div>
                  </div>

                  <div style="white-space: normal">
                    <p *ngIf="connectionBean.message != null"
                       style="font-size: 13px; padding-left: 2px; padding-top: 10px"
                       [ngClass]="{'error-msg':connectionBean.code!=0}">
                      {{ connectionBean.message }}
                    </p>
                    <div class="channel-popup-footer-warning"></div>
                    <button (click)="ftpConnection()"
                            class="channel-popup-footer-button con-btn mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                            type="button" [disabled]="!isConnectedResponse">
                      {{ dictionaryService.dictionary.channelList.dialog.connectButton }}
                    </button>
                  </div>

                  <div *ngIf="!isConnectedResponse">
                    <loaders-css [loader]="'ball-spin-fade-loader'" [loaderClass]="'ttm-loader'"></loaders-css>
                  </div>

                </p-accordionTab>
              </p-accordion>


              <div class="mt-5">
                <!--<span *ngIf="!isFormFTPSectionValid && isFormSaved" class="error-msg field-label">{{dictionaryService.dictionary.channelList.dialog.ftpConfigurationError}}</span>-->
              </div>
            </div>
          </div>
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <div class="channel-popup-footer-warning">
          </div>

          <div class="channel-popup-footer-button-container">
            <button (click)="isFormSaved=true"
                    class="channel-popup-footer-button mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                    type="submit">
              {{ dictionaryService.dictionary.channelList.dialog.saveButton }}
              <i class="material-icons">add</i>
            </button>
          </div>
          <div style="clear: both"></div>
        </div>
        <div class="mdl-card__menu">
          <button (click)="closePopup()" class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
                  type="button">
            <i class="material-icons" style="color: white;">close</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</dialog>
<dialog class="mdl-dialog" style="max-width: 500px;" id="delete-channel-dialog">
  <div class="popup-background-blocker"></div>
  <div class="popup-content-foregrounder">
    <h4 class="mdl-dialog__title">{{ dictionaryService.dictionary.channelList.confirmDialog.header }}</h4>
    <div class="mdl-dialog__content" style="    padding: 20px 24px 24px;">
      <p>
        {{ dictionaryService.dictionary.channelList.confirmDialog.question }} {{ deleteChannelBean.name }}?
      </p>
    </div>
    <div class="mdl-dialog__actions" style="padding: 8px 8px 8px 24px;">
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button"
              (click)="closeConfirmPopup('delete-channel-dialog')">
        {{ dictionaryService.dictionary.channelList.confirmDialog.cancelButton }}
      </button>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              type="submit" (click)="deleteChannel()">
        {{ dictionaryService.dictionary.channelList.confirmDialog.confirmButton }}
      </button>
    </div>
  </div>
</dialog>

<form action="" method="post" class="filters" (ngSubmit)="searchChannels()">
  <div class="custom-panel flex-end mb-20">
    <div class="field-box">
      <div><label class="field-label">{{ dictionaryService.dictionary.channelList.form.nameInputLabel }}:</label></div>
      <div class="search-groups--autocomplete disable-all filter-channel">
        <p-autoComplete name="channel" [size]="35"
                        [placeholder]="dictionaryService.dictionary.channelList.form.nameInputPlaceholder"
                        [(ngModel)]="searchCurrentChannel"
                        (ngModelChange)="autocompleteModelChange()"
                        [suggestions]="foundFilterChannels"
                        [disabled]="channelDisabled" field="name"
                        appendTo="body"
                        (completeMethod)="searchFilterChannels($event)">
          <ng-template let-searchCurrentChannel pTemplate="item">
            <span>{{ searchCurrentChannel.name }}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="field-box">
      <div><label class="field-label">{{ dictionaryService.dictionary.channelList.form.channelGroupInputLabel }}
        :</label>
      </div>
      <div class="search-groups--autocomplete disable-all filter-channel">
        <p-autoComplete name="name" [size]="35"
                        [placeholder]="dictionaryService.dictionary.channelList.form.channelGroupInputPlaceholder"
                        [(ngModel)]="searchCurrentChannelGroup"
                        (ngModelChange)="autocompleteModelChange()"
                        [suggestions]="foundFilterChannelGroups"
                        [disabled]="channelGroupDisabled" field="name"
                        appendTo="body"
                        (completeMethod)="searchFilterChannelGroups($event)">
          <ng-template let-searchCurrentChannelGroup pTemplate="item">
            <span>{{ searchCurrentChannelGroup.name }}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
            type="submit">
      {{ dictionaryService.dictionary.channelList.form.filterButton }}
      <i class="material-icons">tune</i>
    </button>
    <button class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5" type="button"
            (click)="clearSearchFilter()">
      {{ dictionaryService.dictionary.channelList.form.clearFilterButton }}
    </button>
    <div class="element-to-right-margin">
      <span class="field-label auto-retry-label">{{ dictionaryService.dictionary.channelList.form.autoRetry }}:</span>
      <mat-slide-toggle class="auto-retry-toggle" [aria-label]="dictionaryService.dictionary.channelList.form.autoRetry"
                        [checked]="isAutoRetryOn" (toggleChange)="switchAutoRetry()">
      </mat-slide-toggle>
      <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
              type="button" (click)="openPopup(); cleanConnectionBean()">
        {{ dictionaryService.dictionary.channelList.form.newChannelButton }}
        <i class="material-icons">add</i>
      </button>
    </div>

  </div>
</form>

<div>
  <div class="table-responsive-vertical shadow-z-1">
    <table id="table" class="table table-hover table-mc-light-blue">
      <thead>
      <tr>
        <th *ngFor="let header of headers; let i = index" [ngClass]="{'column-header-hover': header.clickable}"
            class="">
          <div (click)="toggleArrow(i, header)" class="column-header-content">
            {{ header.name }}
            <sort-arrow [selected]="header.selected" [sortAsc]="header.asc"></sort-arrow>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let channel of channelsList">
        <td>{{ channel.name }}</td>
        <td>
          <span *ngIf="channel.channelGroup">{{ channel.channelGroup.name }}</span>
          <span *ngIf="!channel.channelGroup">-</span>
        </td>
        <td>
          {{ channel.type }}
        </td>
        <td>
          {{ channel.host }}
        </td>
        <td>
          {{ channel.port }}
        </td>
        <td>
          {{ channel.username }}
        </td>
        <td>
          {{ channel.url }}
        </td>
        <td>
          {{ channel.urlIn }}
        </td>
        <td>
          <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  type="submit" (click)="openPopup(channel); cleanConnectionBean()">
            {{ dictionaryService.dictionary.channelList.results.editButton }}
            <i class="material-icons">edit</i>
          </button>
          <button class="mdl-button mdl-js-button mdl-button--raised mdl-button--accent ml-10 filter-button mr-5 mb-5"
                  (click)="openConfirmDeletePopup(channel)">
            {{ dictionaryService.dictionary.channelList.results.deleteButton }}
            <i class="material-icons">delete</i>
          </button>
        </td>
      </tr>
      <tr *ngIf="channelsList.length==0">
        <td colspan="9" class="no-results-found">{{ dictionaryService.dictionary.channelList.results.noResults }}
          <i class="material-icons">do_not_disturb</i></td>
      </tr>
      </tbody>
    </table>
    <div class="bottom-button-wrapper">
      <button [disabled]="!hasNextPage" class="mdl-button mdl-js-button mdl-button--raised mdl-button-border mr-5 mb-5"
              (click)="nextSlice()" type="button">
        {{ dictionaryService.dictionary.channelList.results.showMore }}
      </button>
    </div>
  </div>
</div>
<p-blockUI [blocked]="!isConnectedResponse"></p-blockUI>
