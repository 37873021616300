import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {UserListService} from "../user-list/user-list.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private userService: UserListService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    if (url == '/logout/cas') {
      window.location.href = 'logout/cas';
    }

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    var that = this;
    this.userService.getCurrentUser().subscribe(user => {
      if (url != '/blocked') {
        if (user && !user.active) {
          setTimeout(function () { that.router.navigate(['/blocked']) }, 50);
        }
      }
      if (url != '/user/agreement') {
        if (user && !user.agreementSigned) {
          setTimeout(function () { that.router.navigate(['/user/agreement']);}, 50);
        }
      }

      if (url == '/profiles' || url == '/channelGroups' || url == '/channels') {
        if (user && user.role != 'ADMIN') {
          setTimeout(function () { that.router.navigate(['/home']);}, 50);
        }
      }

      if (url == '/user') {
        if (user && user.role != 'ADMIN' && user.role != 'MANAGER') {
          setTimeout(function () { that.router.navigate(['/home']);}, 50);
        }
      }

      if (url == '/transferControlPanel') {
        if (user && user.role != 'ADMIN' && user.role != 'OPERATOR') {
          setTimeout(function () { that.router.navigate(['/home']);}, 50);
        }
      }

    });
    return true;
  }
}
