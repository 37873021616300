import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ErrorInterceptor} from './common/error-interceptor';
// import {NgGridModule} from "angular2-grid";
import {AppComponent} from "./app.component";
import {PlaylistCell} from "./schedule/playlist-cell/playlist-cell.component";
import {SearchPlaylist} from "./schedule/search-playlist/search-playlist.component";
import {PlaylistGridComponent} from "./schedule/playlist-grid/playlist-grid.component";
import {PlaylistRow} from "./schedule/playlist-row/playlist-row.component";
import {PlaylistDetails} from "./playlist-details/playlist-details.component";
import {PlaylistDetailSearch} from "./playlist-details/playlist-details-search/playlist-details-search.component";
import {PlaylistDetailsTable} from "./playlist-details/playlist-details-table/playlist-details-table.component";
import {AppRoutingModule} from "./app-routing.module";
import {PlaylistsComponent} from "./schedule/playlists.component";
import {DatePipe} from "@angular/common";
import {PlaylistDetailsRow} from "./playlist-details/playlist-details-row/playlist-details-row.component";
import {PopupDetails} from "./popup-details/details-popup.component";
import {AccordionModule} from "primeng/accordion";
import {AutoCompleteModule} from "primeng/autocomplete";
import {BlockUIModule} from "primeng/blockui";
import {CalendarModule} from "primeng/calendar";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {ToastModule} from "primeng/toast";
import {InputMaskModule} from "primeng/inputmask";
import {RadioButtonModule} from "primeng/radiobutton";
import {PlaylistCompare} from "./playlist-compare/playlist-compare.component";
import {PlaylistCompareSearch} from "./playlist-compare/playlist-compare-search/playlist-compare-search.component";
import {PlaylistCompareTable} from "./playlist-compare/playlist-compare-table/playlist-compare-table.component";
import {PlaylistCompareRow} from "./playlist-compare/playlist-compare-row/playlist-compare-row.component";
// import {DatepickerModule} from "../lib/ttm-angular2-material-datepicker/src/datepicker.module";
import {UserListComponent} from "./user-list/user-list.component";
import {UserAgreementComponent} from "./user-list/user-agreement.component";
import {UnauthorisedComponent} from "./user-list/unauthorised.component";
import {SortArrowComponent} from "./user-list/sort-arrow.component";
import {ProfileListComponent} from "./profile-list/profile-list.component";
import {ChannelListComponent} from "./channel-list/channel-list.component";
import {ChannelGroupListComponent} from "./channel-group-list/channel-group-list.component";
import {AttachBoxComponent} from "./shared/attach-box/attach-box.component";
import {ImagePreviewDirective} from "./shared/directives/image-preview.directive";
import {FileUploadModule} from "ng2-file-upload";
import {NotificationsConfigurationComponent} from "./notifications-configuration/notifications-configuration.component";
import {UnauthorisedWarningComponent} from "./user-list/authorisationWarning.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {PlaylistsElementsComponent} from "./statistics/playlists-elements/playlists-elements.component";
import {MissingMaterialsComponent} from "./statistics/missing-materials/missing-materials.component";
import {StatisticsDashboardComponent} from "./statistics/statistics-dashboard/statistics-dashboard.component";
import {ControlPanelComponent} from './control-panel/control-panel.component';
import {CapitalizeFirstPipe} from "./shared/pipes/capitalize-first.pipe";
import {InputDebounceComponent} from "./shared/input-debounce/input-debounce.component";
import {PlaylistStatusPopupComponent} from "./playlist-details/playlist-status-popup/playlist-status-popup.component";
import {BaseComponent} from "./base.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MessageService} from "primeng/api";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {TooltipModule} from "primeng/tooltip";
import {FooterComponent} from "./footer/footer.component";
import {UserPanelComponent} from "./user-panel/user-panel.component";
import { MenuModule } from "primeng/menu";
import {StyleClassModule} from 'primeng/styleclass';
import {LangPanelComponent} from "./lang-panel/lang-panel.component";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        // NgGridModule,
        // DatepickerModule,
        AppRoutingModule,
        //  MultiselectDropdownModule,
        AutoCompleteModule,
        BlockUIModule,
        CalendarModule,
        ToastModule,
        RadioButtonModule,
        FileUploadModule,
        CheckboxModule,
        AccordionModule,
        DropdownModule,
        InputMaskModule,
        ProgressSpinnerModule,
        TooltipModule,
        MenuModule,
        StyleClassModule,
        MatSlideToggleModule
    ],

  declarations: [
    AppComponent,
    PlaylistCell,
    SearchPlaylist,
    PlaylistsComponent,
    PlaylistGridComponent,
    PlaylistRow,
    PlaylistDetails,
    PlaylistDetailSearch,
    PlaylistDetailsTable,
    PlaylistDetailsRow,
    PopupDetails,
    PlaylistStatusPopupComponent,
    PlaylistCompare,
    PlaylistCompareSearch,
    PlaylistCompareTable,
    PlaylistCompareRow,
    UserListComponent,
    UserAgreementComponent,
    UnauthorisedComponent,
    SortArrowComponent,
    ProfileListComponent,
    ChannelListComponent,
    ChannelGroupListComponent,
    ImagePreviewDirective,
    AttachBoxComponent,
    NotificationsConfigurationComponent,
    UnauthorisedWarningComponent,
    StatisticsComponent,
    PlaylistsElementsComponent,
    MissingMaterialsComponent,
    StatisticsDashboardComponent,
    ControlPanelComponent,
    CapitalizeFirstPipe,
    InputDebounceComponent,
    BaseComponent,
    FooterComponent,
    LangPanelComponent,
    UserPanelComponent
  ],
  providers: [
    MessageService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
