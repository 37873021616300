import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DictionaryService} from './dictionary-service';
import {CommonMessageService} from './common-message-service';
import {NavigationService} from "../service/navigation/navigation.service";

/**
 * HTTP interceptor for central handling of the errors that could be sent back
 * during request execution by displaying them in the GUI.
 *
 * Requires NotificationService to be provided in the used module as well.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private httpClient: HttpClient,
              private messageService: CommonMessageService,
              public dictionaryService: DictionaryService,
              private navigationService: NavigationService
              ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(errorResponse => {
        this.handleError(errorResponse);
        return throwError(errorResponse);
      })
    );
  }

  private handleError(error) {
    console.error('Request NOK code: ', error ?? 'unknown error');

    if (!error || !error.status) {
      return;
    }

    switch (error.status) {
      case 401: {
        this.navigationService.toCasLoginPage();
        break;
      }
      case 403: {
        this.navigationService.toForbiddenPage();
        break;
      }
      case 422: {
        this.handleUnprocessableEntity(error);
        break;
      }
      case 500: {
        this.handleInternalServerError();
        break;
      }
      default: {
      }
    }
  }

  private handleUnprocessableEntity(error): void {
    const errorKey = error.error.message;

    if (!errorKey) {
      return;
    }

    if (this.dictionaryService.dictionary.errors[errorKey]) {
      let message = this.dictionaryService.dictionary.errors[errorKey];
      if (error.error.extraParam) {
        message = message.replace('$', error.error.extraParam);
        this.messageService.error(message);
      } else {
        this.messageService.error(message);
      }
    } else {
      this.messageService.error(this.dictionaryService.dictionary.errors.requestError + errorKey);
    }
  }

  private handleInternalServerError(): void {
    this.messageService.error(this.dictionaryService.dictionary.errors.requestError);
  }
}
