import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictionaryService} from '../common/dictionary-service';
import {BaseComponent} from '../base.component';
import {LoaderService} from '../common/loader.service';
import {MenuItem} from 'primeng/api';
import {environment} from '../../environments/environment';
import {UserBean} from '../model/generated/dtos';

@Component({
  selector: 'user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent extends BaseComponent {

  @Input() user: UserBean;
  public items: MenuItem[] = [];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected loaderService: LoaderService,
              public dictionaryService: DictionaryService) {
    super(router, route, loaderService);
  }

  ngOnInit(): void {
    this.items = [
      this.user?.withMoreApplications ? {
        label: this.dictionaryService.dictionary.user_panel.account_menu.other_applications,
        icon: 'pi pi-external-link',
        url: '/api/unauthorized/casMainPage',
        target: '_self'
      } : null,
      {
        label: this.dictionaryService.dictionary.user_panel.account_menu.change_password,
        icon: 'pi pi-lock',
        url: environment.BASE_API_URL + 'users/changePassword',
        target: '_self'
      },
      {
        label: this.dictionaryService.dictionary.user_panel.account_menu.sign_out,
        icon: 'pi pi-power-off',
        routerLink: '/logout/cas',
        target: '_self'
      }
    ].filter(item => item != null);
  }
}
