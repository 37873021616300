import {Injectable} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {map} from "rxjs/operators";
import {CommonService} from "../../common/common-service";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PlaylistBean} from "../../model/generated/dtos";

@Injectable({
  providedIn: 'root',
})
export class PlaylistGridService extends CommonService {

  private cancelRequestGetPlaylists$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
    super();
  }

  private getPlaylistsUrl = environment.BASE_API_URL + 'playlists/getPlaylists';


  getPlaylists(date: string, ids: string, slotsCount: number = 7): Observable<PlaylistBean[]> {
    this.cancelRequestGetPlaylists$.next();
    let params = new HttpParams().set('from', date)
      .set('channels[]', ids)
      .set('slots', slotsCount);
    return this.http.get(this.getPlaylistsUrl, {
      params: params
    }).pipe(map(this.extractData)).pipe(takeUntil(this.cancelRequestGetPlaylists$));
  }
}
