<div class="schedule-box">
  <div #playlistHeaderBar class="schedule-row schedule-header flexbox">
    <div class="schedule-item item-main flexbox">
      {{dictionaryService.dictionary.playlists.grid.channelHeader}}
    </div>

    <ng-container *ngFor="let _ of [].constructor(slotsCount); let number = index">
      <div class="schedule-item">
        {{getDayOfWeek(number)}} <span class="color-white">{{getDateFormatted(number)}}</span>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let playlist of playlists">
    <playlist-row class="schedule-row flexbox" [rowData]="playlist"></playlist-row>
  </ng-container>
  <div #playlistTableEnd ></div>
  <ng-container *ngIf="!bottomBarStick && bottomBarVisible" >
    <div class="schedule-row schedule-header flexbox">
      <div class="schedule-item item-main flexbox">
        {{dictionaryService.dictionary.playlists.grid.channelHeader}}
      </div>
      <ng-container *ngFor="let _ of [].constructor(slotsCount); let number = index">
        <div class="schedule-item">
          {{getDayOfWeek(number)}} <span class="color-white">{{getDateFormatted(number)}}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="bottomBarStick && bottomBarVisible">
    <div #playlistFixedBar class="schedule-row schedule-header flexbox sticked-playlist-table-header header-bar-fix" [style.width.px]="bottomBarWidth">
      <div class="schedule-item item-main flexbox">
        {{dictionaryService.dictionary.playlists.grid.channelHeader}}
      </div>
      <ng-container *ngFor="let _ of [].constructor(slotsCount); let number = index">
        <div class="schedule-item">
          {{getDayOfWeek(number)}} <span class="color-white">{{getDateFormatted(number)}}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>


