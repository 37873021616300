import {SimpleProfileBean} from "../../model/generated/dtos";
import {ProfileBean} from "../../model/generated/dtos";

export function newSimpleProfileBean(dto: ProfileBean): SimpleProfileBean {
  let bean = {name: dto.name} as SimpleProfileBean;
  if (dto.channel != null) {
    bean.channelName = dto.channel.name;
  }
  return bean;
}
