<div  class="attachbox" ng2FileDrop (fileOver)="hasBaseDropZoneOver = $event" [uploader]="uploader">
    <div class = "attach-file" [ngClass]="{'attachbox-drop': hasBaseDropZoneOver}">
        <div class="attach-title">{{title}}</div>
        <div class="attach-inputbox">
            <label class="attach-label" type="file"  [for] ="_id">{{dictionaryService.dictionary.channelList.dialog.chooseFile}}</label>
            <input class="attach-input" type="file" name="image" accept="image/*" [id]="_id" ng2FileSelect [uploader]="uploader" [title]="title" #selectedFile/>
            <span class="attach-txt-or">{{dictionaryService.dictionary.channelList.dialog.orLabel}}</span>
            <span class="attach-txt-drop">{{dictionaryService.dictionary.channelList.dialog.dropFileLabel}}</span>
        </div>
    </div>
</div>



