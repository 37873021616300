import {Injectable} from '@angular/core'

import * as english from './lang/english';
import * as polish from './lang/polish';
import {PrimeNGConfig} from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {

  public isPolish = !sessionStorage.getItem('isPolish') || sessionStorage.getItem('isPolish') === 'true';
  public dictionary = this.isPolish ? polish.default : english.default;

  constructor(private config: PrimeNGConfig) {
    this.config.setTranslation(this.dictionary.calendar);
  }

}
