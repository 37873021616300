import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistBean} from "../../model/generated/dtos";


@Component({
  selector: 'playlist-grid',
  templateUrl: 'playlist-grid.component.html'
})

export class PlaylistGridComponent implements OnInit, AfterViewInit {

  constructor(private datepipe: DatePipe, protected dictionaryService: DictionaryService) {
  }

  days: string[] = this.dictionaryService.dictionary.playlists.grid.days;

  @Input() playlists: PlaylistBean[];
  @Input() slotsCount: number;
  @Input() date: Date;

  @ViewChild('playlistTableEnd') tableEnd: ElementRef;
  @ViewChild('playlistHeaderBar') headerBar: ElementRef;
  bottomBarVisible: boolean = false;
  bottomBarStick: boolean = false;
  bottomBarWidth: number = 0;

  getDayOfWeek(iter: number): string {
    let tempDate: Date = new Date;
    tempDate.setTime(this.date.getTime() + iter * 86400000 - 86400000);
    return this.days[tempDate.getDay()];
  }

  getDateFormatted(iter: number): string {
    let tempDate: Date = new Date;
    tempDate.setTime(this.date.getTime() + iter * 86400000);
    return this.datepipe.transform(tempDate, 'yyyy/MM/dd');
  }

  ngOnInit(): void {
    //this.date = new Date;
  }

  ngAfterViewInit(): void {
    this.checkBottomBarPosition();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.checkBottomBarPosition();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkBottomBarPosition();
  }

  public checkBottomBarPosition() {
    this.bottomBarVisible = (window.pageYOffset > (this.headerBar.nativeElement.offsetTop + 50));
    this.bottomBarStick = (this.tableEnd.nativeElement.offsetTop + 50 > window.innerHeight + window.pageYOffset);
    this.bottomBarWidth = this.headerBar.nativeElement.offsetWidth;
  }

}
