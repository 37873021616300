import {Injectable} from '@angular/core'
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class CommonMessageService {

  constructor(private messageService: MessageService) {}

  success(detail: string) {
    this.messageService.add({severity: 'success', detail: detail, styleClass: 'custom-toast-success',  contentStyleClass: 'p-toast-success'});
    console.debug(" DETAIL: " + detail);
  }

  info(detail: string) {
    this.messageService.add({severity: 'info', detail: detail, styleClass: 'custom-toast-info', contentStyleClass: 'p-toast-info'});
    console.debug(" DETAIL: " + detail);
  }

  warning(detail: string) {
    this.messageService.add({severity: 'warn', detail: detail, styleClass: 'custom-toast-warning', contentStyleClass: 'p-toast-warning'});
    console.debug(" DETAIL: " + detail);
  }

  error(detail: string) {
    this.messageService.add({severity: 'error', detail: detail, styleClass: 'custom-toast-error', contentStyleClass: 'p-toast-error', life: 1000000});
    console.debug(" DETAIL: " + detail);
  }

}
