import {Injectable} from '@angular/core';
import {CompareHistoryAndFtpFilesBean, ControlPanelFilterBean, ControlPanelParameters} from '../model/generated/dtos';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommonService} from '../common/common-service';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {MaterialStatusConstants} from '../common/constant/material-status-constants';

@Injectable({
  providedIn: 'root',
})
export class ControlPanelDataService extends CommonService {

  private fileHistoryUrl = environment.BASE_API_URL + 'controlPanel/getFileHistory';
  private fileActionUrl = environment.BASE_API_URL + 'controlPanel/fileAction';
  private retryMaterialsProcessingUrl = environment.BASE_API_URL + 'controlPanel/retryMaterialsProcessing';
  private parametersUlr = environment.BASE_API_URL + 'controlPanel/parameters';
  private params = new HttpParams();


  constructor(private http: HttpClient,
              public datePipe: DatePipe) {
    super();
  }

  likeFilter(quote: string, text: string): boolean {
    if (text == null || text.length <= 0) {
      return false;
    }
    return text.toUpperCase().indexOf(quote.toUpperCase()) !== -1;
  }

  isEmpty(value: string): boolean {
    return value == null || value.trim().length <= 0;
  }

  equalsDateDayPrecision(one: Date, two: Date): boolean {
    if (one == null || two == null) {
      return false;
    } else {
      return one.getFullYear() === two.getFullYear()
        && one.getMonth() === two.getMonth()
        && one.getDate() === two.getDate();
    }
  }

  public getFileHistory(channelsGroupName: string, channelName: string, playlistFrom: Date, playlistTo: Date,
                        materialName: string, fileName: string, status: string, fileSize: number):
    Observable<CompareHistoryAndFtpFilesBean[]> {

    if (!status) {
      status = MaterialStatusConstants.ALL;
    }

    const body = {} as ControlPanelFilterBean;
    body.channelsGroupName = channelsGroupName;
    body.channelName = channelName;
    body.playlistFrom = this.datePipe.transform(playlistFrom, 'MMddyyyy');
    body.playlistTo = this.datePipe.transform(playlistTo, 'MMddyyyy');
    body.materialName = materialName;
    body.fileName = fileName;
    body.status = status;
    body.compareFileStatus = fileSize;

    return this.http.post(this.fileHistoryUrl, body).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  doFileAction(bean: CompareHistoryAndFtpFilesBean): Observable<boolean> {
    return this.http.post(this.fileActionUrl, bean, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }


  retryMaterialsProcessing(retryCopying: boolean, elements: CompareHistoryAndFtpFilesBean[]): Observable<number> {
    if (elements == null) {
      elements = [];
    }
    return this.http.post(this.retryMaterialsProcessingUrl + '/' + retryCopying, elements, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }

  getParameters(): Observable<ControlPanelParameters> {
    return this.http.get(this.parametersUlr, {
      params: this.params
    }).pipe(map(this.extractData),
      map(response => {
        return response;
      }));
  }
}
