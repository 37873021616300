import {Component} from "@angular/core";
import {PlaylistSearchService} from "../schedule/search-playlist/search-playlist.service";
import {UserListService} from "app/user-list/user-list.service";
import {ChannelsGroupBean, ProfileBean, SaveUserBean, UserBean} from "../model/generated/dtos";
import {PageResponse} from "./common/paging";
import {ProfileListService} from "../profile-list/profile-list.service";
import {BaseComponent} from "../base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../common/loader.service";
import {DictionaryService} from "../common/dictionary-service";
import {CommonMessageService} from "../common/common-message-service";


@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends BaseComponent {
  showMore: boolean;

  userResponse: PageResponse<UserBean> = new PageResponse(0, 0, []);
  query: string;
  pageSize: number = 10;
  channelOpen: boolean = false;
  roleOpen: boolean = false;
  roleSearchOpen: boolean = false;
  searchRequestBean: UserBean = {} as UserBean;
  deleteUserBean: UserBean = {} as UserBean;
  changeStateUserBean: UserBean = {} as UserBean;
  currentName: string;
  inited: boolean;
  page: number = 0;
  sortField: string = 'email';
  sortOrder: number = 1;
  //autocomplete profile
  foundProfiles: ProfileBean[];
  currentProfile: ProfileBean;
  searchedProfile: ProfileBean;
  searchedProfileQuery: string;
  displayRole: string;
  createUserButtonBlocked: boolean = false;
  managerProfileBean: ProfileBean = {} as ProfileBean;
  popupConfirmButtonBlocked: boolean = false;

  profileSearched: ProfileBean = {} as ProfileBean;

  roleForInputInDialog: { label: string, value: string }[];
  roleForInput: { label: string, value: string }[];

  formBean: UserBean = {} as UserBean;
  headers: any[] = [
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.emailLabel,
      propertyName: 'email',
      selected: true,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.createDateLabel,
      propertyName: 'createDate',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.firstNameLabel,
      propertyName: 'firstName',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.lastNameLabel,
      propertyName: 'lastName',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.loginLabel,
      propertyName: 'login',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.phoneNumberLabel,
      propertyName: 'phoneNumber',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.roleLabel,
      propertyName: 'role',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.profileLabel,
      propertyName: 'profileNames',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.statusLabel,
      propertyName: 'active',
      selected: false,
      asc: true,
      clickable: true
    },
    {
      name: this.dictionaryService.dictionary.userList.tableLabels.actionsLabel,
      propertyName: 'actions',
      selected: false,
      asc: true,
      clickable: false
    },
  ];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              private playListSearch: PlaylistSearchService,
              private profileListService: ProfileListService,
              private userListService: UserListService,
              private messageService: CommonMessageService,
              public dictionaryService: DictionaryService,
              protected loaderService: LoaderService) {
    super(router, route, loaderService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchRequestBean.profiles = [];
    this.searchRequestBean.profiles.push(this.profileSearched);
    this.searchedProfileQuery = this.dictionaryService.dictionary.userList.form.profileAnyPlaceholder;
    this.loadUsers();
    if (this.user.role == 'MANAGER')
      this.getManagerProfileBean();
    this.roleForInputInDialog = [
      {label: this.dictionaryService.dictionary.userList.dialog.roles.roleAdmin, value: 'ADMIN'},
      {label: this.dictionaryService.dictionary.userList.dialog.roles.roleManager, value: 'MANAGER'},
      {label: this.dictionaryService.dictionary.userList.dialog.roles.roleOperator, value: 'OPERATOR'},
      {label: this.dictionaryService.dictionary.userList.dialog.roles.roleUser, value: 'USER'}
    ];

    this.roleForInput = ['ADMIN', 'OPERATOR'].includes(this.user.role) ? [
      {label: this.dictionaryService.dictionary.dictionaries.roles.ALL, value: null},
      {label: this.dictionaryService.dictionary.dictionaries.roles.ADMIN, value: 'ADMIN'},
      {label: this.dictionaryService.dictionary.dictionaries.roles.MANAGER, value: 'MANAGER'},
      {label: this.dictionaryService.dictionary.dictionaries.roles.OPERATOR, value: 'OPERATOR'},
      {label: this.dictionaryService.dictionary.dictionaries.roles.USER, value: 'USER'}
    ] : [
      {label: this.dictionaryService.dictionary.dictionaries.roles.ALL, value: null},
      {label: this.dictionaryService.dictionary.dictionaries.roles.MANAGER, value: 'MANAGER'},
      {label: this.dictionaryService.dictionary.dictionaries.roles.USER, value: 'USER'}
    ];
  }

  requestSorting(header: any): void {
    if (header.clickable) {
      this.inited = false;
      this.page = 0;
      this.sortField = header.propertyName;
      this.sortOrder = 0;
      if (header.asc) {
        this.sortOrder = 1;
      }
      this.loadUsers();
    }
  }

  toggleArrow(index: number, header: any): void {
    if (this.headers[index].clickable) {
      if (this.headers[index].selected) {
        this.headers[index].asc = !this.headers[index].asc;
      } else {
        this.headers.forEach(header => header.selected = false);
        this.headers[index].selected = true;
      }
      this.requestSorting(header);
    }
  }

  nextPage(): void {
    this.page++;
    this.loadUsers();
  }


  loadUsers() {
    if (!this.validateDates())
      return false;
    if (this.sortField === 'active') {
      this.sortOrder = 1 - this.sortOrder;
    }
    let requestEvent = {
      first: this.pageSize * this.page,
      rows: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: null,
      multiSortMeta: []
    };
    if (this.searchRequestBean.searchEndDate != null)
      this.searchRequestBean.searchEndDate.setHours(23, 59, 59, 999);

    this.userListService.getPage(this.searchRequestBean, requestEvent).subscribe(result => {
      if (this.inited) {
        result.content.forEach(user => this.userResponse.content.push(user));
      } else {
        this.userResponse = result;
        this.inited = true;
      }
      this.showMore = result.content.length == this.pageSize;
    })
  }

  validateDates(): boolean {
    if (this.searchRequestBean.searchStartDate != null && this.searchRequestBean.searchEndDate && this.searchRequestBean.searchStartDate > this.searchRequestBean.searchEndDate) {
      this.messageService.warning(this.dictionaryService.dictionary.userList.messages.filterDateRangeError);
      return false;
    }
    let today: Date = new Date;
    if (today < this.searchRequestBean.searchStartDate) {
      this.messageService.warning(this.dictionaryService.dictionary.userList.messages.filterDateTodayError);
      return false;
    }
    return true;
  }

  private mapUserBeanToSaveUserBean(userBean: UserBean): SaveUserBean {
    let saveUserBean = {} as SaveUserBean;
    saveUserBean.id = userBean.id;
    saveUserBean.agreementSigned = userBean.agreementSigned;
    saveUserBean.firstName = userBean.firstName;
    saveUserBean.lastName = userBean.lastName;
    saveUserBean.login = userBean.login;
    saveUserBean.phoneNumber = userBean.phoneNumber;
    saveUserBean.role = userBean.role;
    saveUserBean.email = userBean.email;
    saveUserBean.createDate = userBean.createDate;
    saveUserBean.searchStartDate = userBean.searchStartDate;
    saveUserBean.searchEndDate = userBean.searchEndDate;
    saveUserBean.channelGroupId = userBean.channelGroup?.id;
    saveUserBean.profileIds = userBean.profiles?.map((profile): number => {
      return profile.id;
    });
    saveUserBean.profileNames = userBean.profileNames;
    saveUserBean.active = userBean.active;
    saveUserBean.name = userBean.name;
    return saveUserBean;
  }

  saveUser() {
    if (!this.createUserButtonBlocked) {

      if (this.hasManyMistakes()) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.inputsRequired);
        return false;
      }

      if (this.user.role === "ADMIN" && this.formBean.role == null) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.roleRequired);
        return false;
      }

      if (this.formBean.email == null || this.formBean.email.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.emailRequired);
        return false;
      }

      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.formBean.email)) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.emailInvalid);
        return false;
      }

      if (this.formBean.firstName == null || this.formBean.firstName.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.nameRequired);
        return false;
      }

      if (this.formBean.lastName == null || this.formBean.lastName.trim().length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.lastNameRequired);
        return false;
      }

      if (this.formBean.phoneNumber != null && this.formBean.phoneNumber.trim().length > 0 && !/^\d+$/.test(this.formBean.phoneNumber)) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.phoneNumberInvalid);
        return false;
      }

      if (this.formBean.role === "MANAGER" && this.formBean.profiles.length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.managerProfileError);
        return false;
      }

      if (this.formBean.role === "USER" && this.formBean.profiles.length == 0) {
        this.messageService.error(this.dictionaryService.dictionary.userList.messages.userProfileError);
        return false;
      }

      if (this.formBean.phoneNumber != null && this.formBean.phoneNumber.trim().length == 0)
        this.formBean.phoneNumber = null;

      this.createUserButtonBlocked = true;
      this.loaderService.showLoader();
      this.userListService.saveUser(this.mapUserBeanToSaveUserBean(this.formBean)).subscribe(result => {
        this.messageService.success(this.dictionaryService.dictionary.userList.messages.userSaved);
        this.closePopup();
        this.inited = false;
        this.createUserButtonBlocked = false;
        this.loaderService.hideLoader();
        this.page = 0;
        this.loadUsers();
      }, err => {
        this.loaderService.hideLoader();
        this.createUserButtonBlocked = false;
      });
    }
  }

  hasManyMistakes(): boolean {
    let mistakes = 0;
    (this.user.role === "ADMIN" && this.formBean.role == null) ? mistakes++ : null;
    (this.formBean.email == null || this.formBean.email.trim().length == 0) ? mistakes++ : null;
    (this.formBean.firstName == null || this.formBean.firstName.trim().length == 0) ? mistakes++ : null;
    (this.formBean.lastName == null || this.formBean.lastName.trim().length == 0) ? mistakes++ : null;
    (this.formBean.role === "MANAGER" && this.formBean.profiles.length == 0) ? mistakes++ : null;
    if (mistakes >= 2)
      return true;
    else
      return false;
  }

  openPopup(user?: UserBean): void {
    this.roleOpen = false;
    if (user) {
      this.formBean = Object.assign({}, user);
      if (this.formBean.profiles == null) {
        this.formBean.profiles = [];
      }
      this.currentName = this.formBean.firstName + ' ' + this.formBean.lastName;
    } else {
      this.formBean = {} as UserBean;
      this.formBean.profiles = [];
      this.currentName = this.dictionaryService.dictionary.userList.dialog.header;

      if (this.user.role == 'Manager') {
        this.profileListService.getProfilesByName('', '').subscribe(data => {
          this.formBean.profiles = data;
        });
      }
    }
    var outerHtmlElement: any = document.getElementById('user-form-dialog');
    // outerHtmlElement.showModal();
    this.fixedOpenPopup(outerHtmlElement);
    this.searchProfiles({});
  }

  closePopup(): void {
    var outerHtmlElement: any = document.getElementById('user-form-dialog');
    //outerHtmlElement.close();
    this.fixedClosePopup(outerHtmlElement);
  }

  openConfirmDeletePopup(user: UserBean): void {
    this.popupConfirmButtonBlocked = false;
    if (user) {
      this.deleteUserBean = Object.assign({}, user);
    } else {
      this.deleteUserBean = {} as UserBean;
    }
    var outerHtmlElement: any = document.getElementById('delete-user-dialog');
    //outerHtmlElement.showModal();
    this.fixedOpenPopup(outerHtmlElement);
  }

  openConfirmDisablePopup(user: UserBean): void {
    this.popupConfirmButtonBlocked = false;
    if (user) {
      this.changeStateUserBean = Object.assign({}, user);
    } else {
      this.changeStateUserBean = {} as UserBean;
    }
    var outerHtmlElement: any = document.getElementById('disable-user-dialog');
    //outerHtmlElement.showModal();
    this.fixedOpenPopup(outerHtmlElement);
  }

  deleteUser(): void {
    this.userListService.deleteUser(this.deleteUserBean).subscribe(result => {
      this.messageService.success(this.dictionaryService.dictionary.userList.messages.userDeleted);
      this.closeConfirmPopup('delete-user-dialog');
      this.inited = false;
      this.loadUsers();
    });
  }

  changeActivationState(): void {
    this.popupConfirmButtonBlocked = true;
    if (this.changeStateUserBean.active)
      this.disableUser(this.changeStateUserBean);
    else
      this.enableUser(this.changeStateUserBean);
  }

  disableUser(userBean: UserBean): void {
    this.userListService.disableUser(userBean.id).subscribe(result => {
      this.popupConfirmButtonBlocked = false;
      this.messageService.success(this.dictionaryService.dictionary.userList.messages.userBlocked);
      this.closeConfirmPopup('disable-user-dialog');
      this.inited = false;
      this.loadUsers();
    }, error1 => {
      this.popupConfirmButtonBlocked = false;
    });
  }

  enableUser(userBean: UserBean): void {
    this.userListService.enableUser(userBean.id).subscribe(result => {
      this.popupConfirmButtonBlocked = false;
      this.messageService.success(this.dictionaryService.dictionary.userList.messages.userUnblocked);
      this.closeConfirmPopup('disable-user-dialog');
      this.inited = false;
      this.loadUsers();
    }, error1 => {
      this.popupConfirmButtonBlocked = false;
    });
  }

  closeConfirmPopup(elementId: string): void {
    elementId = elementId || 'delete-user-dialog';
    var outerHtmlElement: any = document.getElementById(elementId);
    // outerHtmlElement.close();
    this.fixedClosePopup(outerHtmlElement);
  }

  chooseRole(chosenRole: string): void {
    this.formBean.role = chosenRole;
  }

  chooseSearchRole(chosenRole: string): void {
    if (chosenRole != 'ALL') {
      this.searchRequestBean.role = chosenRole;
      this.displayRole = this.mapRole(chosenRole);
    } else
      this.searchRequestBean.role = null;
    this.page = 0;
    this.searchUsers();
  }

  chooseGroup(group: ChannelsGroupBean) {
    this.formBean.channelGroup = group;
  }

  clearSearch(): any {
    this.searchRequestBean.firstName = null;
    this.searchRequestBean.lastName = null;
    this.searchRequestBean.email = null;
    this.searchRequestBean.login = null;
    this.searchRequestBean.searchStartDate = null;
    this.searchRequestBean.searchEndDate = null;
    this.searchRequestBean.role = null;
    this.searchedProfileQuery = this.dictionaryService.dictionary.userList.form.profileAnyPlaceholder;
    this.searchedProfile = null;
    this.searchRequestBean.profiles[0] = {} as ProfileBean;
    this.page = 0;
    this.searchUsers();
  }

  fixedOpenPopup(dialogHtmlElement: any) {
    let topOffset = window.pageYOffset + 100 + 'px';
    dialogHtmlElement.style.top = topOffset;
    dialogHtmlElement.setAttribute("open", "open");
  }

  fixedClosePopup(dialogHtmlElement: any) {
    dialogHtmlElement.removeAttribute("open");
  }

  searchUsers() {
    this.inited = false;
    this.page = 0;
    this.loadUsers();
  }


  //AUTOCOMPLETE PROFILE FOR CREATE NEW USER
  searchProfiles(event) {
    let ids = this.formBean.profiles.map(profile => profile.id).join(',');
    if (ids == null) {
      ids = '';
    }
    let name = event.query ? event.query : '';
    if (name == '') {
      return null;
    }
    this.profileListService.getProfilesByName(name, ids).subscribe(data => {
      this.foundProfiles = data;
    });
  }

  getSelectedProfile(event) {
    let profile = <ProfileBean>event;
    if (profile.id != -1) {
      this.addProfile(profile);
    } else {

    }
    this.currentProfile = null;
  }

  //AUTOCOMPLETE PROFILE FOR PROFILE INPUT IN SEARCHING
  searchProfilesSearchBar(event) {
    this.searchedProfileQuery = event.query ? event.query : '';
    if (this.searchedProfileQuery == '') {
      this.searchRequestBean.profiles[0] = {} as ProfileBean;
      this.searchedProfileQuery = this.dictionaryService.dictionary.userList.form.profileAnyPlaceholder;
      return null;
    }
    this.searchRequestBean.profiles[0] = {} as ProfileBean;
    this.searchRequestBean.profiles[0].name = this.searchedProfileQuery;
    this.profileListService.getProfilesByName(this.searchedProfileQuery, "").subscribe(data => {
      this.foundProfiles = data;
    });
    if (this.searchedProfileQuery.length == 0) {
      this.searchedProfileQuery = this.dictionaryService.dictionary.userList.form.profileAnyPlaceholder;
    }
  }

  getSelectedProfileSearchBar(event) {
    let profile = <ProfileBean>event;
    this.searchedProfile = <ProfileBean>profile;
    this.searchRequestBean.profiles[0] = this.searchedProfile;
    this.page = 0;
    this.searchUsers();
  }


  addProfile(channel: ProfileBean): void {
    this.formBean.profiles.pop();
    this.formBean.profiles.push(channel);
  }

  deleteInput(toDelete: ProfileBean) {
    this.formBean.profiles.splice(this.formBean.profiles.indexOf(toDelete), 1);
  }


  mapRole(roleId: string) {
    return this.dictionaryService.dictionary.dictionaries.roles[roleId] ? this.dictionaryService.dictionary.dictionaries.roles[roleId] : '';
  }

  getManagerProfileBean() {
    this.userListService.getManagerProfileBean().subscribe(response => {
      this.managerProfileBean = Object.assign({}, response);
    });
  }

}
