<div id="statistics-page">
  <h2>{{dictionaryService.dictionary.statistics.header}}</h2>

  <!--PANEL STATYSTYK-->
  <statistics-dashboard
    *ngIf="user && user.role && (user.role == 'ADMIN' || user.role =='OPERATOR')"></statistics-dashboard>


  <!--LISTA ELEMENTOW PLAYLISTY-->
  <div class="statistics-playlists-elements mb-25">
    <p-accordion>
      <p-accordionTab [selected]="true">
        <p-header style="font-size: larger;">
          {{dictionaryService.dictionary.statistics.playlistElementsHeader}}
        </p-header>
        <playlists-elements (retryCompleted)="forceDashboardReload()" [user]="user"></playlists-elements>
      </p-accordionTab>
    </p-accordion>
  </div>

  <!--MATERIALY KTORYCH NIE UDALO SIE POBRAC-->
  <div class="statistics-playlists-elements mb-25"
       *ngIf="user && user.role && (user.role == 'ADMIN' || user.role =='OPERATOR')">
    <p-accordion>
      <p-accordionTab [selected]="true">
        <p-header style="font-size: larger;">
          {{dictionaryService.dictionary.statistics.missingMaterialsHeader}}
        </p-header>
        <missing-materials></missing-materials>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
