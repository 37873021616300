import {Component, ElementRef, EventEmitter, Input, Output} from "@angular/core";
import {fromEvent, debounceTime, distinctUntilChanged} from "rxjs";
import {map} from 'rxjs/operators';

@Component({
  selector: 'input-debounce',
  templateUrl: './input-debounce.component.html'
})
export class InputDebounceComponent {
  @Input() placeholderText: string = "";
  @Input() delay: number = 1000;
  @Output() value: EventEmitter<any> = new EventEmitter<any>();

  public inputValue: string;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(map(() => this.inputValue),
        debounceTime(this.delay),
        distinctUntilChanged());
    eventStream.subscribe(input => this.value.emit(input));
  }

  resetInput() {
    this.inputValue = null;
  }
}
