/**
 * Created by sebastian on 03.04.17.
 */

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {DatePipe} from "@angular/common";
import {NotifyService} from "../../common/notify.service";
import {DictionaryService} from "../../common/dictionary-service";
import {PlaylistDetailsService} from "./playlist-details-table.service";
import {LoaderService} from "../../common/loader.service";
import {ChannelBean, PlaylistDetailsBean, PlaylistScheduleDetails, UserBean} from "../../model/generated/dtos";
import {MaterialDetailsBean} from "../material-details-bean";
import {Observable, Subscription, timer} from 'rxjs';
import {first} from 'rxjs/operators';
import {MaterialStatusConstants} from '../../common/constant/material-status-constants';

@Component({
  selector: 'playlist-details-table',
  templateUrl: './playlist-details-table.component.html',
  styleUrls: ['./playlist-details-table.component.css']
})
export class PlaylistDetailsTable implements OnInit, OnDestroy {

  @Input() playlistDetails: PlaylistDetailsBean[];
  @Input() playlistBean: PlaylistScheduleDetails;
  @Output() materialDetails: EventEmitter<Observable<MaterialDetailsBean>> = new EventEmitter();
  @Output() statusDetails: EventEmitter<String> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  @Input() channel: ChannelBean;
  @Input() playlistDate: Date;
  baseApiUrl = environment.BASE_API_URL;
  @Input() statusTranslation: any;
  @Input() user: UserBean;
  @Input() initExpandAllList: boolean = false;
  private timerSubscription: Subscription;
  private statusSubscription: Subscription;
  private refreshCounter: number = 0;
  expandAll: boolean;
  sortAscending: boolean = true;
  sortBy: string = 'blockStartTimecode';
  MaterialStatusConstants = MaterialStatusConstants;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private datepipe: DatePipe,
              private notifyService: NotifyService,
              private loaderService: LoaderService,
              private playlistDetailsService: PlaylistDetailsService,
              protected dictionaryService: DictionaryService) {
  }

  ngOnInit() {
    if (this.initExpandAllList) {
      this.onExpandAllChange(true);
    }
  }

  onExpandAllChange(expandAll: boolean) {
    this.expandAll = expandAll;
  }

  onRefreshEmit(event: Observable<any>) {
    this.refreshList.emit(event);
  }

  onMaterialDetEmit(material: Observable<MaterialDetailsBean>) {
    this.materialDetails.emit(material);
  }

  onStatusDetEmit(statusDetails: String) {
    this.statusDetails.emit(statusDetails);
  }

  comparePlaylists() {
    this.router.navigate(['/compare', this.channel.id, this.playlistBean.id, this.datepipe.transform(this.playlistDate, 'MMddyyyy')]);
  }

  sortDetails(byParameter: string) {
    this.sortBy = byParameter;
    this.sortAscending = !this.sortAscending;
    let direction = this.sortAscending ? "asc" : "desc";
    let sortData = {sortBy: byParameter, sortDirection: direction};
    this.notifyService.notifyOther({option: 'playlistDetailsSearchSortParameters', value: sortData});
  }

  refreshTransformationStatus() {
    this.loaderService.showLoader();
    this.playlistDetailsService.refreshTransformationStatus(this.playlistBean.id).subscribe(response => {
      this.playlistBean.transformationStatus = MaterialStatusConstants.NEW;
      this.refreshPlaylistStatus();
      this.loaderService.hideLoader();
    }, error => {
      this.loaderService.hideLoader();
    });

  }

  downloadPlaylist() {
    window.location.href = this.baseApiUrl + 'playlists/downloadPlaylistFiles/' + this.playlistBean.id;
  }

  refreshPlaylistStatus() {
    this.refreshCounter++;
    this.statusSubscription = this.playlistDetailsService.getPlaylistStatus(this.playlistBean.id).subscribe(
      res => {
        let transformationStatus = res.transformationStatus;
        this.playlistBean.transformationStatus = transformationStatus;
        // 5 min
        if (this.refreshCounter < 60) {
          if (transformationStatus != MaterialStatusConstants.NEW && transformationStatus != MaterialStatusConstants.LOCK) {
            this.refreshCounter = 0;
          } else {
            this.subscribeToRefreshStatus();
          }
        } else {
          this.refreshCounter = 0;
        }
      }
    );
  }

  subscribeToRefreshStatus() {
    this.timerSubscription = timer(5000).pipe(first()).subscribe(() => this.refreshPlaylistStatus());
  }

  public ngOnDestroy(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }


}
